import { ControllerHelper, MediaDownloadDto, PorscheMomentPostDownloadDto, PostController, PostInteraction } from 'collaboration-service';
import DateTimeFns from 'components/DateTime/DateTimeFns';
import UrlComponent from 'components/General/UrlComponent';
import ImageSliderImgFlex from 'components/ImageSlider/ImageSliderImgFlex';
import SafeHTML from 'components/SafeHTML/SafeHTML';
import { Divider, Flag, Icon, Image, LottieIcon, Theme, Tooltip, fadeColor, getUriFromLinkByName, keyframes, styled, useBelowBreakpoint, useThemePart } from 'imaginarity-react-ui';
import _ from 'lodash';
import * as React from 'react';
import { Actions, WorkingActions } from 'services/ApplicationState/Actions';
import { PostProps } from 'services/ApplicationState/ApplicationComponentsFactory';
import { ApplicationState, useAppDispatch, useAppSelector } from 'services/ApplicationState/ApplicationState';
import { CurrentSettings } from 'services/Config';
import { getLink, splitTextOnlyLastLink } from 'services/Helpers';
import { gotoNamedRouteMethod } from 'services/Helpers/RoutingHelper';
import { getOrTranslatePostLanguageStrings, getTranslated, getTranslatedStandardDate } from 'services/Helpers/TranslationHelpers';
import { useImgI18N } from 'services/ImgI18N';
import { feedTheme } from 'views/Feed/FeedMain';
import PodcastPlayer from 'views/Podcast/PodcastPlayer';
import { getMediaLink } from '../../../services/Helpers/MediaHelpers';
import { MediaContainer } from '../PostClasses';
import { PorscheMomentPostSC as T } from './PorscheMomentPostSC';

const slideIn = keyframes`
  from {
    transform: translateY(100%);
  }
  to {
    transform: translateY(0);
  }
`;

const slideOut = keyframes`
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(100%);
  }
`;

const heartAnimation = keyframes`
from {
  transform-origin: center center;
  transform: scale(0.3) translate(-100%, 200%);
    opacity: 1;
  }
to {
  transform-origin: center center;
    transform: scale(5) translate(50%, -300%);
    opacity: 1;
  }
`;






const heartAnimationUndo = keyframes`
0% {
        transform: scale(0) translate(100%, 100%);
        opacity: 0;
}
90% {
      transform: scale(3) translate(-17%, -35%);
      opacity: 1;
  }
  100% {
      transform: scale(5) translate(-17%, -40%);
      opacity: 0;
  }
`;
export const HeartContainerUndo = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  z-index: 5000;
  transform-origin: center;
  animation: ${heartAnimationUndo} 1.5s ease-in-out;
  pointer-events: none;
  svg{
    fill: ${p => fadeColor(p, "@accent", 60)};
    stroke: ${p => fadeColor(p, "@mainBackground", 90)};
  }
`;


export const HeartContainer = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  z-index: 5000;
  transform-origin: center center;
  transform: translate(-50%, -50%);
  animation: ${heartAnimation} 1.5s ease-in-out;
  pointer-events: none;
  svg{
    fill: ${p => fadeColor(p, "@accent", 60)};
    stroke: ${p => fadeColor(p, "@mainBackground", 90)};
  }
`;

export const ShowInfoContainer = styled.div<{ isVisible: boolean }>`
  position: fixed;
  left: 0;
  right: 0;
  top: 119px;
  bottom: 50px;
  background: rgba(255,255,255,1);
  animation: ${({ isVisible }) => (isVisible ? slideIn : slideOut)} 0.3s ease forwards;
  pointer-events: ${({ isVisible }) => (isVisible ? "auto" : "none")};
  padding: 10px;
  overflow-x: hidden;
  overflow-y: auto;
  z-index: 2000;
`;


const elementResizeDetectorMaker = require("element-resize-detector");
const erdUltraFast = elementResizeDetectorMaker({
    strategy: "scroll"
});

const mapper = (state: ApplicationState) => ({
    contentLanguage: state.contentLanguage,
    userGroups: state.userGroups,
});
export interface FeedPostProps extends PostProps {
    next?: () => void;
    prev?: () => void;
    canNext: boolean;
    canPrev: boolean;
}

const useTouchSwipe = (swipeDirectionOne: (() => void) | undefined, swipeDirectionTwo: (() => void) | undefined, isSwipeDirectionTwo?: (deltaX: number, deltaY: number) => boolean, isSwipe?: (deltaX: number, deltaY: number) => boolean): Partial<React.DOMAttributes<HTMLDivElement>> => {
    const touchStartY = React.useRef<number>(0);
    const touchEndY = React.useRef<number>(0);
    const touchStartX = React.useRef<number>(0);
    const touchEndX = React.useRef<number>(0);
    const touchStartTime = React.useRef<number>(0);
    const callBacks = React.useRef<{ directionOne?: () => void, directionTwo?: () => void }>({})

    callBacks.current = { directionOne: swipeDirectionOne, directionTwo: swipeDirectionTwo };

    const isSwipeCheck = React.useMemo(() => isSwipe ?? ((deltaX: number, deltaY: number) => Math.abs(deltaY) > 50 && Math.abs((deltaY ?? 1) / deltaX) > 1.5), [isSwipe])
    const isSwipeDirectionTwoCheck = React.useMemo(() => isSwipeDirectionTwo ?? ((deltaX: Number, deltaY: number) => deltaY > 0), [isSwipeDirectionTwo]);

    const [onTouchStart, onTouchMove, onTouchEnd] = React.useMemo(() => {
        return [(e: React.TouchEvent) => {
            touchStartY.current = e.touches[0].clientY;
            touchStartX.current = e.touches[0].clientX;
            touchStartTime.current = Date.now();
        },
        (e: React.TouchEvent) => {
            touchEndY.current = e.touches[0].clientY;
            touchEndX.current = e.touches[0].clientX;
        },
        (e: React.TouchEvent) => {
            touchEndX.current = e.changedTouches?.[0]?.clientX || touchStartX.current;
            touchEndY.current = e.changedTouches?.[0]?.clientY || touchStartY.current;

            const deltaY = touchStartY.current - touchEndY.current;
            const deltaX = touchStartX.current - touchEndX.current;

            if (isSwipeCheck(deltaX, deltaY)) {
                if (isSwipeDirectionTwoCheck(deltaX, deltaY))
                    callBacks.current.directionTwo?.();
                else
                    callBacks.current.directionOne?.();
            }
        }
        ];
    }, [isSwipeCheck, isSwipeDirectionTwoCheck]);
    return { onTouchMove, onTouchEnd, onTouchStart }
}


const FeedPost = (p: FeedPostProps) => {
    const { post, postChanged, next, prev, canNext, canPrev } = p;
    const { contentLanguage } = useAppSelector(mapper);
    const dispatch = useAppDispatch();
    const { t } = useImgI18N("feed");
    const [sliderWidth, setSliderWidth] = React.useState<number>();
    const [titleHeight, setTitleHeight] = React.useState<number>(0);
    // const [imgIdx, setImgIdx] = React.useState<number>(0);

    const titleRef = React.useRef<HTMLDivElement>(null);
    // const browser = useThemePart(x => x.browser);
    // const isIOS = MobileHelper.getInstance().iOSClient || (browser === "ios" || browser === "crios" || browser === "fxios");
    // const isMobile = useBelowBreakpointOrEqual("tablet");
    // const isMobile = useBelowBreakpointOrEqual("mobile");
    const isMobile = useBelowBreakpoint("tablet");

    const emptyAvatarImageUrl = useThemePart(x => x.emptyAvatarImageUrl);

    const [showInactivityMessage, setShowInactivityMessage] = React.useState(false);
    const inactivityTimer = React.useRef<NodeJS.Timeout | null>(null);

    const resetInactivityTimer = React.useCallback(() => {
        if (inactivityTimer.current) {
            clearTimeout(inactivityTimer.current);
        }
        setShowInactivityMessage(false);
        inactivityTimer.current = setTimeout(() => {
            setShowInactivityMessage(true);
        }, 15000);
    }, []);


    const [showInfoContainer, setInfoContainer] = React.useState<boolean>(false);
    const [isRendered, setIsRendered] = React.useState<boolean>(false);

    const openInfoContainer = React.useCallback(() => {
        setIsRendered(true);
        setInfoContainer(true);
    }, []);

    const closeInfoContainer = React.useCallback(() => {
        setInfoContainer(false);

        setTimeout(() => {
            setIsRendered(false);
        }, 300);
    }, []);


    React.useEffect(() => {
        const handleUserAction = () => resetInactivityTimer();

        window.addEventListener("click", handleUserAction);
        window.addEventListener("mousemove", handleUserAction);
        window.addEventListener("keydown", handleUserAction);
        window.addEventListener("touchstart", handleUserAction);

        resetInactivityTimer();

        return () => {
            if (inactivityTimer.current) {
                clearTimeout(inactivityTimer.current);
            }
            window.removeEventListener("click", handleUserAction);
            window.removeEventListener("mousemove", handleUserAction);
            window.removeEventListener("keydown", handleUserAction);
            window.removeEventListener("touchstart", handleUserAction);
        };
    }, [resetInactivityTimer]);

    React.useEffect(() => {
        if (!titleRef.current) return;
        const observer = new ResizeObserver(() => {
            if (titleRef.current) {
                setTitleHeight(titleRef.current.offsetHeight);
            }
        });
        observer.observe(titleRef.current);
        return () => observer.disconnect();
    }, []);

    React.useEffect(() => {
        const t = async () => {
            const tPost = await getOrTranslatePostLanguageStrings(post, contentLanguage);
            if (tPost) {
                postChanged(tPost);
            }
        }
        t();
    }, [post, contentLanguage, postChanged]);

    const {
        avatar, pm, images, thumbUri, background, color, gradColor1, gradColor2, ctry,
        showCarbon, media0Type, media0uri, mediaArray, isLuxuryMoment, flag } = React.useMemo(() => {
            const mediaArray: MediaDownloadDto[] = [];
            const type = post.type;

            if (type === "PorscheMomentPost") {
                const pm = post as PorscheMomentPostDownloadDto;
                if (pm.media)
                    mediaArray.push(pm.media);
                if (pm.media1)
                    mediaArray.push(pm.media1);
                if (pm.media2)
                    mediaArray.push(pm.media2);
                if (pm.media3)
                    mediaArray.push(pm.media3);
                if (pm.media4)
                    mediaArray.push(pm.media4);
            } else {
                if (post.media)
                    mediaArray.push(post.media);
            }
            const media0Type = mediaArray.length !== 0 ? mediaArray[0].mediaType : undefined;
            const media0uri = media0Type ? getUriFromLinkByName(mediaArray[0], "self") ?? "" : "";
            const isExcitePost = type === "PorscheMomentPost";
            const isCommunityPost = type === "CommunityFeedPost";
            const avatar = getMediaLink(post, y => y.creator?.avatar);
            const pm = isExcitePost ? post as PorscheMomentPostDownloadDto : undefined;
            const mayDelete = getLink(post.links, "delete") !== undefined;
            const mayUpdate = getLink(post.links, "update") !== undefined;
            const fm = post.media ?? pm?.media ?? pm?.media1 ?? pm?.media2 ?? pm?.media3 ?? pm?.media4;
            const w = (fm?.width ?? 16) === 0 ? 16 : (fm?.width ?? 16);
            const h = (fm?.height ?? 9) === 0 ? 9 : (fm?.height ?? 9);
            const images = _.filter(_.map(mediaArray, m => ({ url: getUriFromLinkByName(m, "self") ?? "" })), f => f?.url !== undefined);
            const thumb = (post?.media?.thumbnails ?? [])[0];
            const thumbUri = getUriFromLinkByName(thumb);
            const isLuxuryMoment = CurrentSettings.showLuxuryRibbon && pm?.isLuxury;
            const color = isCommunityPost ? "#FFF" : "#FFF";
            const background = isCommunityPost ? "" : "";
            const gradColor1 = isCommunityPost ? "#0061BD" : isLuxuryMoment ? "#D7A200" : "#D5001C";
            const gradColor2 = isCommunityPost ? "#001930" : isLuxuryMoment ? "#1b1400" : "#270005";
            const showCarbon = isCommunityPost ? true : true;
            const shadowed = isCommunityPost ? true : true;
            const showStandards = (_.findIndex(pm?.standards, s => s === "int") >= 0) || (_.findIndex(pm?.standards, s => s === "rel") >= 0) || (_.findIndex(pm?.standards, s => s === "exc") >= 0) || (_.findIndex(pm?.standards, s => s === "eff") >= 0);
            const descLng = pm && pm.headlines && pm.headlines.length > 0 ? pm.headlines[0].lng : undefined;
            let flag: any;
            if (descLng) {
                flag = descLng.indexOf("-") > -1 ? descLng.split("-")[1].toUpperCase() : descLng.toUpperCase();
                if (flag === "EN") {
                    flag = "GB";
                }
            }

            let ctry = post?.organization1 as any;
            const deletePost = () => {
                if (post)
                    PostController.DeletePost({ postid: post.id },
                        () => {
                            dispatch(WorkingActions.FEED.initialLoadPosts());
                        },
                        error => console.log(error)
                    );
            };




            return ({
                avatar, pm, mayDelete, mayUpdate, w, h, images, thumbUri, color, background, gradColor1, gradColor2, showCarbon, shadowed,
                media0Type, media0uri, mediaArray, isLuxuryMoment, showStandards, flag, ctry, deletePost,
            });
        },
            [post, dispatch]);

    const { desc, headline, descUnfiltered } = React.useMemo(() => {
        const headline = getTranslated(post.headlines, contentLanguage).text ?? "";
        const descUnfiltered = getTranslated(post.descriptions, contentLanguage).text ?? "";
        // const desc = getTranslated(post.descriptions, contentLanguage).text ?? "";
        let desc = descUnfiltered;
        desc = desc.replace(/<p><br><\/p>/g, "");
        return ({
            headline,
            desc,
            descUnfiltered
        });
    }, [post, contentLanguage]);

    const setMediaContainerRef = React.useMemo(() => (contextRef: HTMLElement | null) => {
        if (contextRef) {

            erdUltraFast.listenTo(contextRef, () => {
                setSliderWidth(contextRef.offsetWidth);
            });
        }
    }, []);

    const mediaOuterContainerRef = React.useRef<HTMLDivElement>(null);
    const postContentHeadlineRefRef = React.useRef<HTMLDivElement>(null);
    const postContentRef = React.useRef<HTMLDivElement>(null);
    const likesRef = React.useRef<HTMLDivElement>(null);
    const commentsRef = React.useRef<HTMLDivElement>(null);
    const mediaContainerRef = React.useRef<HTMLDivElement>(null);

    React.useEffect(() => {
        const updateDimensions = () => {
            if (mediaContainerRef.current) { setSliderWidth(mediaContainerRef.current.offsetWidth); }
        };
        const resizeObserver = new ResizeObserver(updateDimensions);

        if (mediaOuterContainerRef.current) { resizeObserver.observe(mediaOuterContainerRef.current); }
        if (postContentHeadlineRefRef.current) { resizeObserver.observe(postContentHeadlineRefRef.current); }
        if (postContentRef.current) { resizeObserver.observe(postContentRef.current); }
        if (likesRef.current) { resizeObserver.observe(likesRef.current); }
        if (commentsRef.current) { resizeObserver.observe(commentsRef.current); }

        updateDimensions();

        return () => {
            resizeObserver.disconnect();
        };
    }, []);

    const contentHeight = useThemePart((t: Theme) => t.content.height);
    // const mediaContainerHeight = media0Type?.startsWith("audio") ? 235 : (contentHeight - 40 - 120 - titleHeight + 5);
    const mediaContainerHeight = media0Type?.startsWith("audio") ? 235 : (contentHeight - 78 - 30 - 100 - titleHeight + 5);
    const videoRef = React.useRef<HTMLVideoElement | null>(null);

    React.useEffect(() => {
        const handleFullscreenChange = () => {
            if (videoRef.current) {
                const isFullscreen =
                    document.fullscreenElement === videoRef.current ||
                    (document as any).webkitFullscreenElement === videoRef.current ||
                    (document as any).mozFullScreenElement === videoRef.current ||
                    (document as any).msFullscreenElement === videoRef.current;
                videoRef.current.style.objectFit = isFullscreen ? 'contain' : 'cover';
            }
        };

        document.addEventListener('fullscreenchange', handleFullscreenChange);
        document.addEventListener('webkitfullscreenchange', handleFullscreenChange);
        document.addEventListener('mozfullscreenchange', handleFullscreenChange);
        document.addEventListener('MSFullscreenChange', handleFullscreenChange);

        return () => {
            document.removeEventListener('fullscreenchange', handleFullscreenChange);
            document.removeEventListener('webkitfullscreenchange', handleFullscreenChange);
            document.removeEventListener('mozfullscreenchange', handleFullscreenChange);
            document.removeEventListener('MSFullscreenChange', handleFullscreenChange);
        };
    }, []);

    // const hasMedia = mediaArray.length > 0 && mediaArray[0].fileName;
    const hasMedia = mediaArray.length > 0;



    // const handleFullscreen = () => {
    //     if (videoRef.current) {
    //         const doc = document as any;
    //         if (doc.fullscreenElement || doc.webkitFullscreenElement) {
    //             if (document.exitFullscreen) {
    //                 document.exitFullscreen();
    //             } else if (doc.webkitExitFullscreen) {
    //                 doc.webkitExitFullscreen();
    //             }
    //         } else {
    //             if (videoRef.current.requestFullscreen) {
    //                 videoRef.current.requestFullscreen();
    //             } else if ((videoRef.current as any).webkitEnterFullscreen) {
    //                 (videoRef.current as any).webkitEnterFullscreen();
    //             }
    //         }
    //     }
    // };

    const [onOpenImage, setOnOpenImage] = React.useState<boolean>(false);

    const touchAttributes = useTouchSwipe(canPrev ? prev : undefined, canNext ? next : undefined);

    const [tooHigh, setTooHigh] = React.useState(false);

    React.useEffect(() => {
        if (postContentRef.current) {
            const lineHeight = parseInt(window.getComputedStyle(postContentRef.current).lineHeight, 10) || 20;
            const maxHeight = lineHeight * 3;

            setTooHigh(postContentRef.current.scrollHeight > maxHeight);
        }
    }, [desc]);

    const [showLikeHeart, setShowLikeHeart] = React.useState<boolean>(false);
    const [showUnLikeHeart, setShowUnLikeHeart] = React.useState<boolean>(false);

    const onLike = (like: boolean) => async () => {
        if (post) {
            const newPost = _.clone(post);
            newPost.likeCount += like ? 1 : -1;
            newPost.didLike = like;
            dispatch(Actions.updatePost(newPost));

            if (like) {
                setShowLikeHeart(true);
                setTimeout(() => setShowLikeHeart(false), 1500);
            } else {
                setShowUnLikeHeart(true);
                setTimeout(() => setShowUnLikeHeart(false), 1500);
            }

            await ControllerHelper.singleCall({ id: post.id }, like ? PostInteraction.LikePost : PostInteraction.DislikePost);
        }
    };
    const clickTimeout = React.useRef<number | null>(null);
    const memoizedImages = React.useMemo(() => images, [images]);
    return (
        <>
            <div
                style={{
                    background: "#000",
                    position: "fixed",
                    top: 50,
                    bottom: 50,
                    left: 0,
                    right: 0,
                    display: "grid",
                    gridTemplateColumns: "1fr",
                    overflow: "hidden",
                    userSelect: "none",
                    touchAction: "none",
                    transition: "all 0.2s ease",
                    gridTemplateRows: (hasMedia
                        ?
                        (media0Type?.startsWith("audio") ? `69px 135px 30px max-content 1fr` : (media0Type?.startsWith("video") ? `69px max-content 30px max-content 1fr` : `69px 1fr 30px max-content 120px`))
                        : `100px 40px max-content 1fr`),
                }}
                {...isMobile && !showInfoContainer && !onOpenImage ? touchAttributes : undefined}
            >
                <div
                    style={{
                        position: "relative", // Basis für absolute Positionierung
                        width: "100%",
                        background: "#f5f4f7", // Standard-Hintergrundfarbe
                        overflow: "hidden", // Verhindert, dass der Blur-Effekt aus dem Container hinausragt
                        marginTop: -9
                    }}
                >
                    {/* {!media0Type?.startsWith("audio") &&
                        <div
                            style={{
                                position: "absolute",
                                top: 0,
                                left: 0,
                                width: "100%",
                                height: "100%",
                                backgroundImage: media0Type?.startsWith("image") ? `url(${memoizedImages[imgIdx].url})` : undefined,
                                backgroundPosition: "center",
                                backgroundSize: "400%",
                                backgroundRepeat: "no-repeat",
                                filter: "blur(6px)", // Anwenden des Blur-Filters
                                zIndex: 0, // Hinter den eigentlichen Inhalt legen
                            }}
                        />
                    } */}
                    {/* <T.DarkenContainer trans={media0Type === undefined || media0Type?.startsWith("audio")} /> */}

                    <T.PostContainer
                        background={background}
                        gradColor1={gradColor1}
                        gradColor2={gradColor2}
                        showCarbon={showCarbon}
                        style={{
                            zIndex: 5,
                            // position: "absolute",
                            // top: 0,
                            // left: 0,
                            boxShadow: hasMedia ? "0 6px 8px -6px #000" : "none",
                            marginTop: 50,
                            padding: "2px 10px",
                            lineHeight: "26px",
                            // opacity: 0.5
                        }}
                    >
                        {isLuxuryMoment &&
                            <T.LuxuryContainer right={pm ? (((pm.responsibleUsers.length - 1) * 15) + 50) : 70}>
                                <Image src={feedTheme.luxuryRibbonUrl} width='100px' />
                            </T.LuxuryContainer>
                        }
                        <T.PostContainerImage
                            onClick={(post && post.creator) ? gotoNamedRouteMethod("user", { id: post.creator.id }) : undefined} style={{ bottom: 15, left: 15 }}
                        >
                            <Image src={avatar ?? emptyAvatarImageUrl} rounded />
                        </T.PostContainerImage>
                        <T.PostTime style={{ paddingLeft: 69 }}>
                            <DateTimeFns
                                iconSize={16}
                                marginTop={3}
                                date={post.changed}
                                iconColor={color}
                                textColor={color}
                                dateFormat="P"
                                timeFormat='p'
                                fluid />
                        </T.PostTime>
                        {post?.creator?.organisation && <T.Organisation style={{ top: -21, left: 80 }}>{post?.creator?.organisation}</T.Organisation>}
                        {pm && _.filter(pm.responsibleUsers, r => r.id !== pm.creator?.id).length > 0 && _.map(pm.responsibleUsers, (r, i) => {
                            return (
                                <T.AvatarMouseOverContainer
                                    key={i}
                                    idx={i}
                                    onClick={gotoNamedRouteMethod("user", { id: r.id })}
                                >
                                    <Tooltip tooltipText={r.firstName + " " + r.secondName} noMargin notInline >
                                        <Image src={getMediaLink(r, u => u.avatar) ?? emptyAvatarImageUrl} width="24px" rounded />
                                    </Tooltip>
                                </T.AvatarMouseOverContainer>
                            );
                        })
                        }
                        <T.Poster hasOrganisation={post?.creator?.organisation ? true : false} style={{ left: 80 }}>
                            {(post?.creator?.firstName ?? "") + " " + (post?.creator?.secondName ?? "")}
                        </T.Poster>
                    </T.PostContainer>
                </div>

                <div style={{ background: !media0Type?.startsWith("image") ? "#000" : "#313639", position: "relative", overflow: "hidden" }} id="top">
                    <div style={{ maxWidth: "calc(100vw + 5px)" }} ref={mediaOuterContainerRef}>
                        {mediaArray.length > 0 &&
                            <MediaContainer ref={setMediaContainerRef} >
                                {media0Type?.startsWith("image") && sliderWidth && sliderWidth > 0 &&
                                    <ImageSliderImgFlex
                                        width={sliderWidth}
                                        height={mediaContainerHeight}
                                        images={memoizedImages}
                                        isIphone={isMobile}
                                        onOpen={(open) => {
                                            setOnOpenImage(open);
                                        }}
                                    // idx={(id) => { setImgIdx(id) }}
                                    />
                                }

                                {media0Type?.startsWith("video") && (
                                    <div
                                        style={{
                                            width: "100%",
                                            // height: mediaContainerHeight,
                                            height: "auto",
                                            overflow: "hidden",
                                            position: "relative",
                                            background: "#000",
                                        }}
                                    >
                                        <video
                                            ref={videoRef}
                                            width="100%"
                                            autoPlay={false}
                                            controls={true}
                                            src={`${media0uri}${thumbUri ? "" : "#t=0.01"}`}
                                            typeof={media0Type}
                                            crossOrigin="anonymous"
                                            playsInline={true}
                                            preload="metadata"
                                            poster={thumbUri}
                                            style={{
                                                // position: "absolute",
                                                // top: "50%",
                                                // left: "50%",
                                                // transform: "translate(-50%, -50%)",
                                                // width: "100%",
                                                // height: "auto",
                                                // minHeight: "100%",
                                                // minWidth: "100%",
                                                // objectFit: "cover",
                                                maxHeight: mediaContainerHeight - 25,
                                                marginBottom: -5
                                                // objectFit: "contain",
                                            }}
                                        />
                                        {/* <div
                                            style={{
                                                position: 'absolute',
                                                top: 110,
                                                right: 10,
                                                zIndex: 10,
                                            }}
                                        >
                                            <div onClick={handleFullscreen}>
                                                <Icon name='expand' color="#fff" size={22} />
                                            </div>

                                        </div> */}
                                    </div>
                                )}
                                {media0Type?.startsWith("audio") &&
                                    <T.AudioContainer style={{ padding: 0, marginTop: 0 }}>
                                        <PodcastPlayer src={media0uri} mini />
                                    </T.AudioContainer>
                                }
                            </MediaContainer>
                        }
                    </div>
                </div>

                <div style={{ marginTop: 0, zIndex: 1, userSelect: "none" }} id="standards" ref={titleRef}>
                    <T.PostContainerStandards background={background}
                        gradColor1={gradColor1}
                        gradColor2={gradColor2}
                        showCarbon={showCarbon}
                        style={{
                            zIndex: 5,
                            maxWidth: "calc(100vw + 5px)",
                            boxShadow: hasMedia ? "none" : "0 6px 8px -6px #000",
                            // borderTop: "1px solid #000"

                        }}>
                        <div><T.Standard selected={_.findIndex(pm?.standards, s => s === "int") >= 0}>Integrity</T.Standard></div>
                        <div><T.Standard selected={_.findIndex(pm?.standards, s => s === "rel") >= 0}>Relationship</T.Standard></div>
                        <div><T.Standard selected={_.findIndex(pm?.standards, s => s === "exc") >= 0}>Excitement</T.Standard></div>
                        <div><T.Standard selected={_.findIndex(pm?.standards, s => s === "eff") >= 0}>Efficiency</T.Standard></div>
                    </T.PostContainerStandards>
                </div>
                <div style={{ marginTop: 0 }} id="title">
                    <T.PostContentHeadline ref={postContentHeadlineRefRef} style={{ padding: 10, paddingTop: 10, lineHeight: "20px" }}>
                        {flag &&
                            <>
                                <T.PostContentHeadlineFlag>
                                    <Tooltip tooltipText={t("post language {{flag}}", { flag })} noMargin>
                                        <Flag name={flag} size={'18px'} />
                                    </Tooltip>
                                </T.PostContentHeadlineFlag>
                                <T.PostContentHeadlineText>
                                    {t("post language")}:
                                </T.PostContentHeadlineText>
                            </>
                        }
                        <SafeHTML html={headline} />
                    </T.PostContentHeadline>

                </div>
                <div style={{ background: "#fff", position: "relative" }} id="bottom"
                    // onDoubleClick={(post && post.didLike) ? undefined : onLike(true)}
                    // onClick={tooHigh ? (e) => {
                    //     e.stopPropagation();
                    //     e.preventDefault();
                    //     openInfoContainer();
                    // } : undefined}

                    onClick={(e) => {
                        e.stopPropagation();
                        e.preventDefault();

                        if (clickTimeout.current) {
                            clearTimeout(clickTimeout.current);
                            clickTimeout.current = null;
                            if (!post?.didLike) {
                                onLike(true)();
                            }
                            if (post?.didLike) {
                                onLike(false)();
                            }
                        } else {
                            clickTimeout.current = window.setTimeout(() => {
                                clickTimeout.current = null;
                                if (tooHigh) {
                                    openInfoContainer();
                                }
                            }, 300);
                        }
                    }}
                >

                    {desc !== "" &&
                        <T.PostContentP>
                            <div
                                ref={postContentRef}
                                style={{
                                    display: '-webkit-box',
                                    WebkitBoxOrient: 'vertical',
                                    WebkitLineClamp: 3,
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    position: 'relative',
                                }}
                            >
                                <SafeHTML html={desc ?? ""} />
                            </div>
                            {tooHigh && !media0Type?.startsWith("audio") && (
                                <T.ReadMoreInfo
                                    style={{ marginTop: 5, fontSize: "0.9em", float: "left", touchAction: "none", background: "#fff" }}
                                // onClick={(e) => {
                                //     e.stopPropagation();
                                //     e.preventDefault();
                                //     openInfoContainer();
                                // }}
                                >
                                    [{t("read more")}]
                                </T.ReadMoreInfo>
                            )}
                        </T.PostContentP>

                    }
                    {post.changed > post.created &&
                        <T.PostContentInfo style={{ position: "absolute", bottom: 12, left: 11, right: 15, background: "unset", border: "unset", fontSize: "0.8em", textOverflow: "ellipsis", whiteSpace: "nowrap", overflow: "hidden" }}>
                            {t("created on {{created}}, edited on {{edited}}", { created: getTranslatedStandardDate(post.created, "P -p"), edited: getTranslatedStandardDate(post.changed, "P -p") })}
                        </T.PostContentInfo>
                    }
                </div>
                {ctry &&
                    <T.TopRightFlag style={{ top: 10 }}>
                        <Tooltip tooltipText={t("post organization1 {{flag}}", { flag: ctry })} noMargin position='top'>
                            <Flag name={ctry} size={'18px'} />
                        </Tooltip>
                    </T.TopRightFlag>
                }
            </div>

            {isRendered && (
                <>
                    <ShowInfoContainer
                        isVisible={showInfoContainer}
                        style={{ touchAction: "none" }}
                        onClick={(e) => {
                            e.stopPropagation();
                            closeInfoContainer();
                        }}
                    // onTouchStart={(e) => {
                    //     touchStartY.current = e.touches[0].clientY;
                    //     touchStartTime.current = Date.now();
                    // }}
                    // onTouchMove={(e) => {
                    //     touchEndY.current = e.touches[0].clientY;
                    // }}
                    // onTouchEnd={(e) => {
                    //     const deltaY = touchStartY.current - touchEndY.current;
                    //     const touchDuration = Date.now() - touchStartTime.current;

                    //     const isSwipeDown = deltaY < -150 && touchDuration > 100;
                    //     const isClick = Math.abs(deltaY) <= 20 && touchDuration <= 200;


                    //     if (isClick) {
                    //         e.stopPropagation();
                    //         return;
                    //     }

                    //     if (isSwipeDown) {
                    //         closeInfoContainer();
                    //     }
                    // }}
                    >
                        <div
                        // onClick={(e) => {
                        //     e.stopPropagation();
                        // }}
                        >

                            <T.PostContentHeadline ref={postContentHeadlineRefRef} style={{ padding: "none", border: "none", fontSize: "larger", fontWeight: "bolder", position: "sticky", top: -10, marginLeft: -10, width: "calc(100% + 20px)", borderBottom: "1px solid #cfcfcf", marginBottom: 10 }}>
                                {flag &&
                                    <>
                                        <T.PostContentHeadlineFlag>
                                            <Flag name={flag} size={'18px'} />
                                        </T.PostContentHeadlineFlag>
                                        <T.PostContentHeadlineText>
                                            {t("post language")}:
                                        </T.PostContentHeadlineText>
                                    </>
                                }
                                <SafeHTML html={headline} />
                            </T.PostContentHeadline>
                            <div style={{ overflowY: "auto", overflowX: "hidden", touchAction: "pan-y", paddingBottom: 20 }}

                            >
                                <SafeHTML html={descUnfiltered ?? ""} />
                                {descUnfiltered.includes("https://") &&
                                    <div>
                                        <Divider color='@middleLightGrey' />
                                        {splitTextOnlyLastLink(descUnfiltered, UrlComponent)}
                                    </div>
                                }
                                {post.changed > post.created &&
                                    <div style={{ fontSize: "0.8em" }}>
                                        <Divider color='@middleLightGrey' />

                                        {t("created on {{created}}, edited on {{edited}}", { created: getTranslatedStandardDate(post.created, "P -p"), edited: getTranslatedStandardDate(post.changed, "P -p") })}
                                    </div>
                                }

                                <T.ReadMoreInfo
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        closeInfoContainer();
                                    }}
                                >
                                    [{t("close")}]
                                </T.ReadMoreInfo>
                            </div>
                        </div>
                    </ShowInfoContainer>
                </>
            )}


            {showInactivityMessage && !isRendered && (
                <div
                    style={{
                        position: "fixed",
                        bottom: 50,
                        right: 0,
                        left: 0,
                        height: "calc(100vh - 100px)",
                        zIndex: 1000,
                        pointerEvents: "none"
                    }}
                >
                    <T.LottieHint>
                        <LottieIcon url={"images/lottiIcons/swipeUp.json"}
                            speed={1}
                            autoplay
                            loop

                        />
                    </T.LottieHint>
                </div>
            )}
            {showLikeHeart && (
                <HeartContainer>
                    <Icon name="heart" size="100px" color="#fff" />
                </HeartContainer>
            )}

            {showUnLikeHeart && (
                <HeartContainerUndo>
                    <Icon name="heart undo" size="100px" color="#fff" />
                </HeartContainerUndo>
            )}
        </>
    );
}
export default FeedPost;

import { aboveBreakpoint, belowBreakpointOrEqual, fadeColor, getColor, lightenColor, styled } from 'imaginarity-react-ui';
import { extraFadeIn } from 'views/BestPractice/BestPracticeSC';

export class PlaylistDetailSC {

    public static PLItemContainer = styled.div<{ isMobile: boolean, isPreview: boolean }>`
    width: 100%;
    display: grid;
    grid-template-columns: ${p => p.isMobile ? 48 : 172}px 1fr ${p => p.isPreview !== true && "120px"};
    grid-template-rows: ${p => p.isMobile ? 48 : 96}px;
    align-items: center center;
    margin-bottom: 16px;
    
    cursor: pointer;
    /* transition: 0.2s all ease-out;
    z-index: 6;
    &:hover{
        background: ${p => fadeColor(p, "color", 5)};
    } */

`;
    public static PLItemImage = styled.div<{ imageUrl: string, isMobile: boolean, color: string, highlighted?: boolean }>`
    background-image: url(${p => p.imageUrl});
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
    z-index: 1;
    height: ${p => p.isMobile ? 48 : 96}px;
    border: ${p => p.highlighted ? `1px solid ${getColor(p, p.color)}` : `1px solid ${getColor(p, "@middleLightGrey")}`};
    box-shadow: ${p => p.highlighted ? `0 10px 5px -8px ${getColor(p, "@lightGrey")}` : "none"};
`;
    public static PLItemImageLottie = styled.div`
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    *{stroke: ${p => getColor(p, "@accentRed")};}
`;

    public static UpcomingNodesHeadline = styled.div`
    margin: 10px 0;
    font-size: 1.2em;
    line-height: 40px;
    font-weight: bolder;
    text-transform: capitalize;
`;
    public static PLItemContaineClickArea = styled.a<{ accColor: string, isMobile: boolean }>`
    background: ${p => fadeColor(p, "color", 0)};
    left: 0;
    right: 0;
    height: ${p => p.isMobile ? 48 : 96}px;
    position: absolute;
    cursor: pointer;
    transition: 0.2s all ease-out;
    z-index: 6;
    &:hover{
        background: ${p => fadeColor(p, "color", 30)};
}
`;
    public static PLItemContainerTitle = styled.a<{ isMobile: boolean }>`
    font-size: 1em;
    padding: 0 20px;
    font-weight: 600;
    height: ${p => p.isMobile ? 28 : 40}px;
    line-height: ${p => p.isMobile ? 28 : 40}px;
    cursor: pointer;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    color: ${p => getColor(p, "@darkerGrey")};
    a{
        color: ${p => getColor(p, "@darkerGrey")};
    }
    a:hover{
        color: ${p => getColor(p, "@accent")};
    }
`;

    public static PLItemContainerStatus = styled.a<{ isMobile: boolean }>`
        cursor: pointer;
        color: ${p => getColor(p, "@darkerGrey")};
        a{
            color: ${p => getColor(p, "@darkerGrey")};
        }
        a:hover{
            color: ${p => getColor(p, "@accent")};
        }
`;
    public static PLItemContainerTitleDot = styled.div<{ color?: string }>`
    float: left;
    margin-right: 10px;
    width: 10px;
    height: 10px;
    background: ${p => getColor(p, p.color ?? "@accent")};
    border-radius: 50%;
    margin-top: 15px;

`;
    public static PLItemContainerDescription = styled.div`
    padding: 0 0 0 20px;
    color: ${p => getColor(p, "@darkGrey")};
    width: 100%;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    line-height: 20px;
    height: 20px;
    div{
        width: 100%;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
    }
`;
    public static PLItemContainerBottomCat = styled.div<{ isMobile: boolean }>`
    font-size: 0.85em;
    padding-left: 20px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    height: ${p => p.isMobile ? 20 : 32}px;
    line-height: ${p => p.isMobile ? 20 : 32}px;
`;
    public static PLItemContainerBottomCatIconLink = styled.a`
    float: left;
    margin-left: -2px;
    margin-top: 5px;
    margin-right: 4px;
    width: 22px;
    height: 22px;
    border-radius: 11px;
    transition: 0.2s all ease-out;
    border: 1px solid ${p => lightenColor(p, "@veryLightGrey", 95)};
    svg{
        stroke: ${p => getColor(p, "@darkGrey")};
    }
    &:hover{
        border: 1px solid ${p => getColor(p, "@darkGrey")};
        background: ${p => getColor(p, "@veryLightGrey")};
        svg{
            stroke: ${p => getColor(p, "@accent")};
    }
    }
`;
    public static PLItemContainerRight = styled.div<{ isMobile: boolean }>`
    padding-left: 20px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    text-align: right;
    line-height: ${p => p.isMobile ? 48 : 96}px;
`;
    public static ColoredDiv = styled.div<{ color: string }>`
        color: ${p => getColor(p, p.color)};
    `;
    public static ColoredSpan = styled.span<{ color: string }>`
        color: ${p => getColor(p, p.color)};
    `;

    public static PLItemContainerContent = styled.div<{ isMobile: boolean }>`
    display: grid;
    grid-template-columns : 1fr;
    grid-template-rows: ${p => p.isMobile ? "28px 20px" : "40px 20px 36px"};
    align-items: center;
`;

    public static Container = styled.div<{ items: number }>`
    position: relative;
    margin: 0 -1px 0 4px;
    color: ${p => getColor(p, "@darkerGrey")};
    padding: ${p => p.items}px 0px;
`;
    public static ContainerInner = styled.div`
    position: relative;
    min-height: 60px;
    
    left: 20px;
    right: 20px;
    width: calc(100% - 40px);
    //left: 0px;
    //right: 0px;
    //width: calc(100% - 0px);
`;
    public static TimeLineAll = styled.div`
    position: absolute;
    left: 0;
    right: 0;
    top: 50%;
    height: 2px;
    background-color: ${p => getColor(p, "@neutralLow")};
`;
    public static TimeTodayLine = styled.div<{ left: number }>`
    position: absolute;
    left: calc(${p => p.left}% - 1px);
    top: 50%;
    width: 2px;
    height: 42px;
    background-color: ${p => getColor(p, "@lightGrey")};
`;
    public static Dot = styled.div<{ left: number, hovered: boolean, color: string }>`
    position: absolute;
    width: 6px;
    height: 6px;
    left: ${p => p.left}%;
    top: 50%;
    transform-origin: center center;
    transform: translate(-50%, -2px);
    border: 1px solid ${p => getColor(p, p.color === "background" ? "@middleLightGrey" : p.hovered ? getColor(p, "@accentBlue") : getColor(p, p.color))};
    background: ${p => (p.hovered ? getColor(p, "@accentBlue") : getColor(p, p.color))};
    z-index: 10;
`;
    public static TimeLineAllProgress = styled.div<{ width: number, left: number, color: string }>`
    position: absolute;
    width: ${p => p.width}%;
    left: ${p => p.left}%;
    height: 2px;
    top: 50%;
    margin-top: 0px;
    background: ${p => getColor(p, p.color)};
    z-index: 1;
`;
    public static Category = styled.div<{ left: number, idx: number, hovered: boolean, color: string }>`
    position: absolute;
    left: ${p => p.left}%;
    top: calc(50% - 15px);
    //top: ${p => p.hovered ? "-5px" : "calc(50% - 15px)"};
    line-height: 15px;
    transform: translate(-50%, -50%) ${p => p.hovered && "scale(1.1)"} ${p => p.hovered && "translateY(-10px)"};
    z-index: ${p => p.hovered ? p.idx * 100 : p.idx};
    font-size: 1em;
    color: ${p => getColor(p, p.color)};
    width: 150px;
    text-align: center;
    transition: 0.2s all ease-out;
    background: ${p => p.hovered ? getColor(p, "@mainBackground") : "transparent"};
    border: ${p => p.hovered ? `1px solid ${getColor(p, "@veryLightGrey")}` : "1px solid transparent"};
    box-shadow: ${p => p.hovered ? "0 6px 8px -6px #000" : "none"};
       
        div{
        width: 100%;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        
        }
`;
    public static DueDate = styled(this.Category)`
    top: calc(50% + 20px);
    font-size: 0.86em;
    background: transparent;
    box-shadow: none;
    border: none;
    color: ${p => p.hovered ? getColor(p, "@accentBlue") : getColor(p, p.color)};
    font-weight: ${p => p.hovered ? "bolder" : "normal"};
    transform: translate(-50%, -50%) ${p => p.hovered && "scale(1.1)"} ${p => p.hovered && "translateY(0)"};
`;
    public static ToolTipDiv = styled.div`
        margin-top: 10px;
`;
    public static CategoryItem = styled.div`
        padding: 0 5px;
        background: transparent;
        &:hover{
            color: ${p => getColor(p, "@accentBlue")};
        }
`;

    public static ToolTipFooter = styled.div`
    display: grid;
    grid-template-columns: 1fr max-content;
    column-gap: 20px;
    width: calc(100% + 28px);
    padding: 10px 14px;
    margin-left: -14px;
    margin-bottom: -14px;
    font-size: 0.9em;
    border-top: 1px solid ${p => getColor(p, "@veryLightGrey")};
`;

    public static BannerContainer = styled.div<{ stickyHeader: boolean, imageUrl: string }>`
    width: ${p => belowBreakpointOrEqual(p, "largeMobile") ? "100%" : "calc(110% + 20px)"};
    /* height: ${p => belowBreakpointOrEqual(p, "largeMobile") ? 140 : 280}px; */
    height: 60px;
    left: ${p => belowBreakpointOrEqual(p, "largeMobile") ? 0 : "-5%"};
    margin-left: ${p => belowBreakpointOrEqual(p, "largeMobile") ? 0 : "calc(-5% - 10px)"};
    padding: 0px calc(5% + 11px) 20px calc(5% + 11px);
    background-image: url('${props => props.imageUrl ?? props.theme.emptyImageUrl}');
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    position: sticky;
    /* top: ${p => p.stickyHeader ? -220 : -1000}px; */
    top: ${p => p.stickyHeader ? 0 : -1000}px;
    z-index: 100 ;

`;
    public static BannerTitleContainerBG = styled.div`
    position: absolute;
    bottom:0px;
    left:0px;
    width: 100%;
    height:60px;
    background: ${p => fadeColor(p, getColor(p, p.theme.colors.accentBlack), 50)};
`;
    public static BannerTitle = styled.div`
    position: absolute;
    bottom:0px;
    left:0px;
    font-size:1.4rem;
    line-height:60px;
    white-space: nowrap;
    width: calc(100% - 0px);                   
    overflow: hidden;   
    text-overflow: ellipsis;
    padding-left: calc(5% - 10px);
    padding-right: calc(5% - 10px);
    color: ${p => getColor(p, "background")};
    background: linear-gradient(90deg, ${p => fadeColor(p, getColor(p, p.theme.colors.accentBlack), 50)} 0%, transparent 100%);
    button{
        transform: translateY(0px);
    }
    button>div{
        margin: 0;
        }
`;
    public static OuterContainer = styled.div`
    //width: calc(110% + 20px);
    //margin-left: calc(-5% - 10px);
    //padding: 0 5%;
    border-bottom: 1px solid ${p => getColor(p, "@middleLightGrey")};

    
    width: ${p => belowBreakpointOrEqual(p, "largeMobile") ? "100%" : "calc(110% + 20px)"};
    margin-left: ${p => belowBreakpointOrEqual(p, "largeMobile") ? 0 : "calc(-5% - 10px)"};
    padding: ${p => belowBreakpointOrEqual(p, "largeMobile") ? "0px 10px" : "0px calc(5% + 11px) 20px calc(5% + 11px)"};
`;
    public static OuterContainerTopGridSticky = styled(this.OuterContainer) <{ stickyHeader: boolean }>`
    position: sticky;
    //top: ${p => p.stickyHeader ? 60 : -1000}px;
    top: ${p => p.stickyHeader ? aboveBreakpoint(p, "tablet") ? 60 : -1000 : -1000}px;
    z-index: 100;
    background: ${p => getColor(p, "@mainBackground")};
`;

    public static TimelineSticky = styled.div<{ stickyHeader: boolean }>`
    width: calc(110% + 20px);
    margin-left: calc(-5% - 10px);
    padding: 0px calc(5% + 11px) 0px calc(5% + 11px);
    position: sticky;
    top: ${p => p.stickyHeader ? aboveBreakpoint(p, "tablet") ? 107 : -1000 : -1000}px;
    box-shadow: ${p => p.stickyHeader ? "0 16px 20px -20px black" : "none"};
    z-index: ${p => p.stickyHeader ? aboveBreakpoint(p, "tablet") ? 99 : 99 : 101};
    background: ${p => getColor(p, "@mainBackground")};
`;
    public static TopGrid = styled.div`
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    padding: 20px 0;
    position: relative;
    button>div{margin: 0;}
`;
    public static TopGridHeadline = styled.div`
    font-weight: 600;
    font-size: 0.85em;
    line-height: 16px;
    color: #626669;
`;
    public static TopGridContent = styled.div<{ color?: string }>`
    font-weight: 500;
    line-height: 20px;
    font-size: 1em;
    color: ${p => getColor(p, p.color === "Finished" ? "@accentGreen" : p.color === "On Track" ? "@accentGreen" : p.color === "Overdue" ? "@accentRed" : p.color === "Warning" ? "@warning" : "@accentBlack")}; 
`;
    public static RatingViewContainer = styled.div<{ color?: string }>`
    position: absolute;
    right: 0px;
    top: 20px;
    span{
        margin-right: -5px;
    }
`;
    public static Description = styled.div`
    font-weight: 600;
    font-size: 1.1em;
    height: 22px;
    line-height: 22px;
    //margin-top: ${p => belowBreakpointOrEqual(p, "largeMobile") ? "10px" : "32px"};
    //margin-bottom: ${p => belowBreakpointOrEqual(p, "largeMobile") ? 0 : "20px"};
    margin-top: ${p => belowBreakpointOrEqual(p, "largeMobile") ? "10px" : "10px"};
    margin-bottom: ${p => belowBreakpointOrEqual(p, "largeMobile") ? 0 : "0px"};
`;

    public static OpenArea = styled.div`
    position: absolute;
    width: 80px;
    height: 5px;
    background: ${p => getColor(p, "@veryLightGrey")};
    left: 50%;
    bottom: -6px;
    transform-origin: center top;
    transform: translateX(-50%);
    transition: 0.1s all ease-out;
    cursor: pointer;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    /* box-shadow: 0 0 0 0 transparent; */
    box-shadow: 0px 1px 4px -3px ${p => getColor(p, "@darkGrey")};
    text-align: center;
    &:hover{
        height: 30px;
        bottom: -31px;
        background: ${p => getColor(p, "@darkGrey")};
        box-shadow: 0px 6px 8px -6px ${p => getColor(p, "@darkGrey")};
        svg{
                stroke: ${p => getColor(p, "@veryLightGrey")};
        }   
    }       
`;
    public static DescriptionText = styled.div`
    font-weight: 400;
    margin-top: 5px;
    margin-bottom: 10px;
`;
    public static NodeBar = styled.div<{ flushText: boolean, isMobile: boolean }>`
    height: 56px;
    line-height: 56px;
    /* background: #f2f2f27f; */
    /* background: ${p => getColor(p, "@veryLightGrey")}; */
    background: linear-gradient(
        to right, 
        ${p => lightenColor(p, "@veryLightGrey", 95)} 0%, 
        ${p => lightenColor(p, "@veryLightGrey", 98)} 10px
        );
    width: calc(110% + 26px);
    margin-left: calc(-5% - 13px);
    padding: 0 calc(5% + 10px);
    margin-top: 0px;
    margin-bottom: -8px;
    display: grid;
    box-shadow: 0 6px 8px -6px ${p => getColor(p, "@lightGrey")};
    cursor: pointer;
    grid-template-columns: 40px 1fr ${p => p.isMobile ? "max-content" : p.flushText ? "90px minmax(110px, max-content) minmax(100px, max-content)" : "max-content max-content max-content"};
    transition: all 0.2s ease-out;
    &:hover{
        background: linear-gradient(
        to right, 
        ${p => lightenColor(p, "@veryLightGrey", 93)} 0%, 
        ${p => lightenColor(p, "@veryLightGrey", 96)} 10px
        );   
    }
`;
    public static NodeBarNext = styled.div<{ flushText: boolean, active: boolean }>`
    height: 56px;
    line-height: 56px;
    width: calc(110% + 26px);
    margin-left: calc(-5% - 13px);
    padding: 0 calc(5% + 10px);
    display: grid;
    cursor: ${p => p.active ? "pointer" : "default"};
    grid-template-columns: 1fr ${p => p.flushText ? "minmax(110px, max-content) minmax(100px, max-content)" : "max-content max-content"};
    background: linear-gradient(
        to right, 
        ${p => lightenColor(p, "@veryLightGrey", p.active ? 95 : 97)} 0%, 
        ${p => lightenColor(p, "@veryLightGrey", p.active ? 98 : 100)} 10px
        );
    box-shadow: 0 6px 8px -6px ${p => getColor(p, "@middleLightGrey")};    
    margin-bottom: 10px;
    transition: all 0.2s ease-out;
    &:hover{
        background: linear-gradient(
        to right, 
        ${p => lightenColor(p, "@veryLightGrey", p.active ? 93 : 97)} 0%, 
        ${p => lightenColor(p, "@veryLightGrey", p.active ? 96 : 100)} 10px
        );   
    }
`;
    public static NodeBarPreviewContainer = styled.div`
    pointer-events: none;
    user-select: none;
    padding: 10px;
    margin-bottom: 10px;
    margin-left: -3px;
    margin-top: 0px;
    padding-bottom: 0px;
    opacity: 0.7;
    //box-shadow: 0 6px 8px -6px ${p => getColor(p, "@middleLightGrey")}; ;
    `;

    public static NodeBarNextFixed = styled.div<{ flushText: boolean }>`
    height: 56px;
    line-height: 56px;
    width: calc(110% + 20px);
    margin-left: calc(-5% - 13px);
    padding: 0 calc(5% + 10px);
    display: grid;
    //grid-template-columns: 1fr ${p => p.flushText ? "minmax(160px, max-content)" : "minmax(120px, max-content) minmax(160px, max-content)"};
    grid-template-columns: 1fr ${p => p.flushText ? "minmax(160px, max-content)" : "minmax(120px, max-content) minmax(160px, max-content) minmax(160px, max-content)"};
    border-top: 1px solid #f2f2f2;
    &:last-child{
        border-bottom: 1px solid #f2f2f2;
    }
`;
    public static NodeBarIcon = styled.div<{ isVisible: boolean }>`
    cursor: pointer;
    display: grid;
    align-items: center;
    line-height: 20px;
    svg{
        transition: all 0.2s ease-out;
        stroke: ${p => getColor(p, p.isVisible ? "@accentBlack" : "@accentRed")};
        stroke-width: 2.5;
        }
    &:hover{
        svg{
            stroke: ${p => getColor(p, p.isVisible ? "@accentRed" : "@accentBlack")};
        }
    }
`;
    public static NodeBarIconPreview = styled.div<{ isVisible: boolean }>`
    cursor: pointer;
    display: grid;
    align-items: center;
    line-height: 20px;
    padding-right: 20px;
        svg{
            transition: all 0.2s ease-out;
            stroke: ${p => getColor(p, p.isVisible ? "@accentBlack" : "@accentRed")};
            stroke-width: 2.5;
        }
    &:hover{
        svg{
            stroke: ${p => getColor(p, p.isVisible ? "@accentRed" : "@accentBlack")};
        }
    }
`;
    public static NodeBarTextPreview = styled.div`
    font-weight: normal;
    font-size: 0.89em;
    color: ${p => getColor(p, "@accentRed")};
`;
    public static NodeBarTitle = styled.div`
    font-weight: 600;
    font-size: 1.1em;
    display: grid;
    align-items: center;
    line-height: 20px;
    button>div{margin: 0;}
`;
    public static NodeBarInfo = styled.div<{ flushText: boolean, color?: string }>`
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    text-align: right;
    padding-left: ${p => p.flushText ? 0 : 0}px;
    color: ${p => getColor(p, p.color ?? "color")};
    svg{
        stroke: ${p => getColor(p, p.color ?? "color")};
    }
`;
    public static NodeContentContainer = styled.div`
`;

    public static NodeContentContainerHint = styled.div`
    text-align: right;
    font-weight: bolder;
`;

    public static NodeContentContainerInfoLine = styled.div`
    font-size: 0.85em;
    line-height: 40px;
    border-bottom: 1px solid #f2f2f2;
    margin-bottom: 16px;
    position: relative;
    margin-top: 10px;
    display: grid;
    grid-template-columns: 1fr max-content;
`;

    public static TLsmallDisplayGrid = styled.div`
        display: grid;
        grid-template-columns: 10px max-content 1fr max-content max-content max-content;
        line-height: 24px;
    `;
    public static TLsmallDisplayGridItem = styled.div`
        padding-left: 20px;
        line-height: 24px;
`;
    public static TLsmallDisplayGridItemInfo = styled.div`
        padding-left: 20px;
        line-height: 24px;
        svg{
            transition: 0.2s all ease-out;
        }
        &:hover svg{
            stroke: ${p => getColor(p, "@accent")};
        }
`;
    public static TLsmallDisplayRowIndicatorContainer = styled.div`
        border-left: 3px solid ${p => getColor(p, "@neutralLow")};
        position: relative;
`;
    public static TLsmallDisplayRowLine = styled.div<{ width: number }>`
        background: ${p => getColor(p, "@neutralLow")};
        position: absolute;
        height: 1px;
        width: ${p => p.width}px;
        top: 13px;
        left: 0px;
`;
    public static TLsmallDisplayRowDot = styled.div<{ color: string, left: number }>`
        background: ${p => getColor(p, p.color)};
        position: absolute;
        height: 8px;
        width: 8px;
        top: 9px;
        left: ${p => p.left}px;
`;

    public static TLsmallDisplayRow = styled.div<{ color: string, isTodayEntry: boolean }>`
    color: ${(p) => p.isTodayEntry ? getColor(p, "@lightGrey") : "inherit"};
    display: contents;
    &:hover > div{
        background: ${(p) => p.isTodayEntry ? "unset" : fadeColor(p, p.color, 5)};
        color: ${(p) => p.isTodayEntry ? "unset" : lightenColor(p, p.color, 70)};
        }
`;

    public static DimmerContainer = styled.div`
            background: ${p => getColor(p, "backgroundColor")};
            color: ${p => getColor(p, "color")};
            width: 90%;
            max-width: 880px;
            left: 50%;
            position: absolute;
            top: 50%;
            transform: translate(-50%, -50%);
            display: grid;
            grid-template-columns: 1fr;
            grid-template-rows: max-content 100px 1fr;
            text-align: left;
            max-height: 90vh;
            padding-bottom: 40px;
    `;

    public static DimmerContainerWiki = styled.div`
            background: ${p => getColor(p, "backgroundColor")};
            color: ${p => getColor(p, "color")};
            width: 90%;
            max-width: 880px;
            left: 50%;
            position: absolute;
            top: 50%;
            transform: translate(-50%, -50%);
            text-align: left;
            max-height: 90vh;
            padding-bottom: 40px;
            overflow-x: hidden;
            overflow-y: auto;
            scrollbar-width: none;
`;

    public static DimmerContainerQuiz = styled.div`
        background: ${p => fadeColor(p, "@accentBlack", 60)};
        color: ${p => getColor(p, "color")};
        width: 90%;
        max-width: 880px;
        left: 50%;
        position: absolute;
        top: 50%;
        transform: translate(-50%, -50%);
        text-align: left;
        height: 100vh;
        padding-bottom: 40px;
`;

    public static UniversalDimmerContainer = styled.div<{ isQuiz: boolean, isPmPost: boolean }>`
    background: ${p => p.isQuiz ? fadeColor(p, "@accentBlack", 60) : getColor(p, "@mainBackground")};
    color: ${p => getColor(p, "color")};
    width: 90%;
    max-width: 880px;
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    text-align: left;
    max-height: ${p => p.isQuiz ? "100vh" : "90vh"};
    min-height: ${p => p.isQuiz ? "100vh" : "100px"};
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: ${p => p.isQuiz ? "1fr" : "max-content 80px 1fr"};
    text-align: left;
    overflow-y: ${p => p.isQuiz ? "hidden" : "auto"};
    scrollbar-width: none;
`;
    public static RenderImageHeaderContainer = styled.div`
    background: #000;
    margin-top: -1px;
`;
    public static RenderDescriptionContainer = styled.div<{ isPdf: boolean, isWiki: boolean }>`
    padding: 0 40px 20px 40px;
    max-height: ${p => p.isPdf ? "100px" : "unset"};
    overflow-y: ${p => (p.isPdf || p.isWiki) ? "auto" : "hidden"};
    overflow-x: hidden;
`;
    public static DimmerVideoContainer = styled.div`
        background: ${p => getColor(p, "@accentBlack")};
        width: 100%;
        aspect-ratio: 16 / 9;
`;
    public static DimmerFileEntryContainer = styled.div`
        overflow-x: auto;
        width: 100%;
        overflow-y: hidden;
        text-align: center;
        max-height: 70vh;
        display: flex;
        justify-content: center;
        img{
            max-height: 70vh;
            height: auto;
            width: auto;
        }
`;
    public static DimmerImageContainer = styled.div<{ imageUrl: string, lightBG?: boolean }>`
        background: ${p => p.lightBG ? getColor(p, "@veryLightGrey") : getColor(p, "@accentBlack")};
        background-image: url(${p => p.imageUrl});
        background-position: center center;
        background-size: cover;
        background-repeat: no-repeat;
        width: 100%;
        aspect-ratio: 11 / 5;
`;
    public static DimmerInfoContainer = styled.div`
        width: 100%;
        padding: 20px 40px 0 40px;
        display: grid;
        grid-template-columns: 1fr max-content;
        grid-template-rows: 25px 15px max-content;
        //border-top: 1px solid ${p => getColor(p, "@middleLightGrey")};
`;
    public static UniversalDimmerInfoContainer = styled.div`
        width: 100%;
        padding: 20px 40px 0 40px;
        display: grid;
        grid-template-columns: 1fr max-content;
        grid-template-rows: 25px 15px max-content;
        position: sticky;
        top: 0;
        z-index: 1;
        background: ${p => getColor(p, "@mainBackground")};
`;
    public static DimmerInfoContainerWiki = styled.div`
        width: 100%;
        padding: 40px;
        display: grid;
        grid-template-columns: 1fr max-content;
        grid-template-rows: 25px 15px max-content;
        border-top: 1px solid ${p => getColor(p, "@middleLightGrey")};
        position: sticky;
        top: 0px;
        background: ${p => getColor(p, "backgroundColor")};
        z-index: 1;
        border-bottom: 1px solid ${p => getColor(p, "@middleLightGrey")};
        margin-bottom: 20px;
`;
    public static StickyCloseButton = styled.div`
        width: 100%;
        padding: 0;
        position: sticky;
        top: -16px;
        z-index: 10;
`;
    public static DimmerInfoContainerTitle = styled.div`
        font-size: 1.2em;
        font-weight: bolder;
        line-height: 25px;
        grid-column-start: 1;
        grid-column-end: 2;
        grid-row-start: 1;
        grid-row-end: 2;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
`;

    public static DimmerInfoContainerButton = styled.div`
        grid-column-start: 2;
        grid-column-end: 3;
        grid-row-start: 1;
        grid-row-end: 3;
        display: grid;
        grid-template-columns: 1fr max-content max-content max-content;
        column-gap: 5px;
        button>div{margin: 0;}
`;
    public static DimmerInfoContainerRating = styled.div`
        float: left;
        margin-top: 6px;
        margin-right: 15px;
`;
    public static DimmerInfoContainerDate = styled.div`
        grid-column-start: 1;
        grid-column-end: 2;
        grid-row-start: 2;
        grid-row-end: 3;
        font-size: 0.9;
        line-height: 15px;
        color: ${p => getColor(p, "@lightGrey")};
`;
    public static DimmerInfoContainerDescription = styled.div`
        overflow-x: hidden;
        overflow-y: auto;
        padding: 1px 40px 40px 40px;
`;
    public static DimmerInfoContainerLink = styled.div`
        overflow-x: hidden;
        overflow-y: auto;
        padding: 1px 40px;
        margin-top: -5px;
        font-size: 0.85em;
        a{
            color: ${p => getColor(p, "color")};
        }
        a:hover{
            color: ${p => getColor(p, "@accentRed")};
        }
`;
    public static DimmerInfoContainerDescriptionGrid = styled.div`
        display: grid;
        grid-template-columns: max-content 1fr;
        column-gap: 20px;
        row-gap: 5px;
        margin-top: 20px;
`;
    public static DimmerInfoContainerDescriptionGridTitle = styled.div`
        font-weight: bolder;
        `;

    public static DimmerInfoContainerDescriptionGridTListItem = styled.div`
        margin-right: 10px;
        display: inline-block;
`;
    public static DimmerInfoContainerQuizMarkbutton = styled.div`
    position: absolute;
      bottom: 10px;
      left: 50%;
      transform: translateX(-50%);
      z-index: 9999999999;
`;
    public static DimmerInfoContainerClose = styled.div`
      position: absolute;
      top: 20px;
      right: 20px;
      background: ${p => getColor(p, "backgroundColor")};
      width: 32px;
      height: 32px;
      text-align: center;
      line-height: 32px;
      cursor: pointer;
      transition: 0.2s all ease-out;
      z-index: 9999999999;
      &:hover{
        background: ${p => getColor(p, "@accentBlack")};
        svg{
                stroke: ${p => getColor(p, "@accentRed")};
            }
      }
`;

    public static DimmerInfoContainerSticky = styled.div`
      background: ${p => getColor(p, "backgroundColor")};
      width: 32px;
      height: 32px;
      text-align: center;
      line-height: 32px;
      cursor: pointer;
      transition: 0.2s all ease-out;
      z-index: 9999999999;
      float: right; 
      margin: 20px;
      &:hover{
        background: ${p => getColor(p, "@accentBlack")};
        svg{
                stroke: ${p => getColor(p, "@accentRed")};
            }
      }
`;

    public static DimmerImageContainerImage = styled.div`
        background: ${p => getColor(p, "@darkGrey")};
        background-image: url('https://cdn.imaginarity.com/public/images/postBG.png');
        background-repeat: no-repeat;
        background-position: top left;
        background-size: cover;
        width: 100%;
        overflow: hidden;
        aspect-ratio: 11 / 5;
    img{
        object-fit: cover;
        animation: ${extraFadeIn} 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) 0.1s both;
        width: 100%;
        aspect-ratio: 11 / 5;
        margin-top: -1px;
    }
    video{
        object-fit: cover;
        animation: ${extraFadeIn} 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) 0.1s both;
        width: 100%;
        aspect-ratio: 11 / 5;
        margin-top: -1px;
    }
`;

    public static DueDateOptionalHint = styled.span`    
    color: ${p => getColor(p, "@middleLightGrey")};
    text-transform: lowercase;
    margin-right: 3px;
    font-size: 0.9em;
`;
    public static InfoBoxBottom = styled.div<{ mobile: boolean }>`    
    border: 1px solid ${p => getColor(p, "@middleLightGrey")};
    border-top: none;
    margin: ${p => p.mobile ? "0 5px" : 0};
`;



};

import { ControllerHelper, PlayListNodeDownloadDto, PlaylistDownloadDto, PlaylistItemStateDownloadDto, PlaylistItemStateEnum, PlaylistStateDownloadDto, PlaylistUploadDto, PostController, PostDownloadDto, PostInteraction } from 'collaboration-service';
import CommentsRatingsView from 'components/General/CommentsRatingsView';
import SafeHTML from 'components/SafeHTML/SafeHTML';
import TabsBar from 'components/TabsBar/TabsBar';
import { differenceInDays } from 'date-fns';
import { Button, Icon, Loader, RatingView, Theme, aboveBreakpoint, belowBreakpointOrEqual, useThemePart } from 'imaginarity-react-ui';
import _ from 'lodash';
import * as React from 'react';
import { Actions, WorkingActions } from 'services/ApplicationState/Actions';
import { ApplicationState, useAppDispatch, useAppSelector } from 'services/ApplicationState/ApplicationState';
import { CurrentSettings, bannerTop } from 'services/Config';
import { addHoursToISODate, calculateDueInfo, convertMinutesToDaysHoursMinutes, countNodeItemsToBeDonePerNode, countNodeItemsToBeDoneSingleNode, countPlaylistItemsToBeDone, getHigherDate, getHighestAbsDueDate, getHighestDueDateRelativeInHours, getTransitionMessage, initPostDownloadDto, shallowCompare, sortReferenceIdsInNodes, visit } from 'services/Helpers';
import { getMediaLink } from 'services/Helpers/MediaHelpers';
import { gotoNamedRouteMethod, linkToRoute } from 'services/Helpers/RoutingHelper';
import ScrollHelper from 'services/Helpers/ScrollHelper';
import { getTranslated, getTranslatedStandardDate } from 'services/Helpers/TranslationHelpers';
import History from 'services/History';
import { useImgI18N } from 'services/ImgI18N';
import { isAdmin } from 'services/StoreDependantHelpers';
import { useAppTheme } from 'services/useAppTheme';
import { ThemeContext } from 'styled-components';
import PlaylistDetailNodeItem from './PlaylistDetailNodeItem';
import { PlaylistDetailSC as T } from './PlaylistDetailSC';
import { PlaylistTheme } from './PlaylistMain';
import Timeline from './Timeline/Timeline';

const mapper = (state: ApplicationState) => ({
    contentLanguage: state.contentLanguage,
    routeParams: state.params,
    // sidebarRightOpen: state.playlistsState.rightSidebar,
    playlistsStates: state.playlistsState.playlistStatesTopic,
});

export declare type LocalStateEnum = 'Finished' | 'On Track' | 'Overdue' | 'Warning' | 'Optional';
export interface NodeState {
    state: LocalStateEnum;
    finished: number;
}

interface RefPostsInterface { [id: string]: PostDownloadDto }
interface PlaylistDetailProps {
}

const PlaylistDetail = (p: PlaylistDetailProps) => {
    const { routeParams, contentLanguage, /*sidebarRightOpen,*/ playlistsStates } = useAppSelector(mapper, shallowCompare);

    const dispatch = useAppDispatch();

    const [loading, setLoading] = React.useState<boolean>(false);
    const [allNodes, setAllNodes] = React.useState<PlayListNodeDownloadDto[]>([]);
    const [warning, /*setWarning*/] = React.useState<boolean>(false);
    // const [nodeContentVisible, setNodeContentVisible] = React.useState<boolean[]>([]);
    const [nodeContentVisible, setNodeContentVisible] = React.useState<boolean[]>(() => {
        const initialVisibility = allNodes.map((n, index) => {
            const firstChild = n.children?.[0];
            const isFallThru = firstChild?.transitionFunction === "FallThru";
            return index === 0 && isFallThru;
            // return true;
        });

        // console.log("Initial Visibility State:", initialVisibility);
        return initialVisibility;
    });


    const [playlistState, setPlaylistState] = React.useState<PlaylistStateDownloadDto>();
    const [refPosts, setRefPosts] = React.useState<RefPostsInterface>({});
    const [scrollHelper] = React.useState(new ScrollHelper());
    const { t } = useImgI18N("playlist");
    const id = routeParams?.id as string;
    const dataOrig = playlistState?.playlist;
    const data = dataOrig as PlayListNodeDownloadDto;

    const prevNodes = sortReferenceIdsInNodes(playlistState?.prevNodes ?? []);
    const curNodes = sortReferenceIdsInNodes(playlistState?.curNodes ?? []);
    const nextNodes = sortReferenceIdsInNodes(playlistState?.nextNodes ?? []);
    const hasNextNodes = nextNodes.length > 0;
    const nextNodeHeadline = hasNextNodes ? <T.UpcomingNodesHeadline>{t(nextNodes.length === 1 ? "upcoming phase" : "upcoming phases")}</T.UpcomingNodesHeadline> : undefined;
    const nodesToShow = prevNodes.concat(curNodes);
    const curNodesElementsToBeDoneCount = countPlaylistItemsToBeDone(curNodes);
    const prevNodesElementsToBeDoneCount = countPlaylistItemsToBeDone(prevNodes);
    const nextNodesElementsToBeDoneCount = countPlaylistItemsToBeDone(nextNodes);
    const allNodesElementsToBeDoneCount = curNodesElementsToBeDoneCount + prevNodesElementsToBeDoneCount + nextNodesElementsToBeDoneCount;
    const absNodeDueDate = playlistState ? getHighestAbsDueDate(playlistState) : undefined;
    const absNodeDueDateRelativeInHours = playlistState ? getHighestDueDateRelativeInHours(playlistState) : undefined;
    const playlistTheme = useAppTheme<PlaylistTheme>("playlistTheme");
    const dueToDate = playlistState ? getHigherDate(absNodeDueDate, absNodeDueDateRelativeInHours) : undefined;
    const curNode = playlistState?.curNodes[0];
    const add = curNode?.absDueDate;
    const ddrih = curNode?.dueDateRelativeInHours;
    const dueDate = (curNode?.dueDateRelativeTo === "Absolute" && add) ? add : ((curNode?.dueDateRelativeTo !== undefined && curNode?.dueDateRelativeTo !== "Absolute" && ddrih && playlistState) ? addHoursToISODate(playlistState.assignmentDate, ddrih) : undefined);
    const banner = getMediaLink(dataOrig, d => d?.media);
    const theme = React.useContext(ThemeContext);
    const isMobile = belowBreakpointOrEqual({ theme }, "largeMobile");
    const isTablet = aboveBreakpoint({ theme }, "largeMobile");
    const contentWidth = useThemePart((t: Theme) => t.content.width);

    React.useEffect(() => {
        if (allNodes && allNodes.length > 0 && curNode) {
            setNodeContentVisible(allNodes.map((n, index) => {
                const firstChild = n.children?.[0];
                const isFallThru = firstChild?.transitionFunction === "FallThru";
                return isFallThru || n.id === curNode.id;
                // return n.id === curNode.id;
            }));
        }
        if (playlistState?.state === "Finished") {
            setNodeContentVisible(_.map(playlistState?.prevNodes, () => true));
        }
    }, [allNodes, playlistState, curNode]);

    const toggleNodeContent = (index: number) => {
        const updatedVisibleState = [...nodeContentVisible];
        updatedVisibleState[index] = !updatedVisibleState[index];
        setNodeContentVisible(updatedVisibleState);
    };

    React.useEffect(() => {
        dispatch(WorkingActions.WIKI.loadPlaylistState());
    }, [playlistsStates, dispatch]);

    const loadPlaylistStates = React.useMemo(() => async () => {
        setRefPosts({});
        setPlaylistState(undefined);
        if (id && playlistsStates) {
            setLoading(true);

            const s = _.find(playlistsStates, p => p.playlist.id === id);
            if (s !== undefined) {
                setPlaylistState(s);
                const nodes: PlayListNodeDownloadDto[] = [];
                visit((s.playlist as PlaylistDownloadDto).startNode, (cur) => _.compact(_.map(cur.children, edge => edge.to)), cur => nodes.push(cur));
                setAllNodes(nodes);
                let ids: string[] = [];
                _.forEach(s.curNodes, n => {
                    ids = ids.concat(n.referenceIds ?? []);
                });
                _.forEach(s.prevNodes, n => {
                    ids = ids.concat(n.referenceIds ?? []);
                });
                _.forEach(s.nextNodes, n => {
                    ids = ids.concat(n.referenceIds ?? []);
                });
                const contents = (s.playlist as PlaylistDownloadDto).contents;
                const pContents = _.filter(contents, c => c.referenceType === "PALMS");
                const pIds = _.map(pContents, p => p.referenceId!);
                const tasks = _.map(_.difference(ids, pIds), refid => ControllerHelper.singleCall({ id: refid, context: s.id }, PostController.GetSinglePostWithContext, true));
                const res = await Promise.all(tasks);
                setRefPosts(p => {
                    const toRet = _.clone(p);
                    _.forEach(_.compact(res), r => toRet[r.id] = r);
                    _.forEach(pContents, p => toRet[p.referenceId!] = initPostDownloadDto("", "PALMS", p.referenceId));
                    return toRet;
                });
            }

            setLoading(false);
        }
    }, [id, playlistsStates]);

    React.useEffect(() => {
        loadPlaylistStates();
    }, [loadPlaylistStates]);

    React.useEffect(() => {
        if (Object.keys(refPosts).length > 0) {
            scrollHelper.scrollTo(curNode?.id ?? '');
        }
    }, [refPosts, curNode, scrollHelper]);

    const getState = React.useMemo(() => (states: PlaylistItemStateDownloadDto[]): PlaylistItemStateEnum => {
        let ret = 'NotAvailable' as PlaylistItemStateEnum;
        _.forEach(states, st => {
            if (st.state === 'Available')
                ret = 'Available';
        });
        _.forEach(states, st => {
            if (st.state === 'Started')
                ret = 'Started';
        });
        let finisheds = 0;
        _.forEach(states, st => {
            if (st.state === 'Finished')
                finisheds++;
        });
        if (finisheds === states.length)
            ret = 'Finished';
        return ret;


    }, []);

    const getCurrentLearningStatus = React.useMemo(() => (): LocalStateEnum => {

        const status = getState(playlistState?.itemStates ?? []);
        const absDueDate = curNode?.absDueDate;
        const assignmentDate = playlistState?.assignmentDate;
        const dueDateRelativeTo = curNode?.dueDateRelativeTo;
        const dueDateRelativeInHours = curNode?.dueDateRelativeInHours;

        let dueTime: number = 0;
        if (absDueDate && dueDateRelativeTo === "Absolute") {
            dueTime = new Date(absDueDate).getTime() - new Date().getTime();
        }
        if (assignmentDate && dueDateRelativeInHours && dueDateRelativeTo !== "Absolute") {
            dueTime = (new Date(assignmentDate).getTime() + (dueDateRelativeInHours * 60 * 60 * 1000)) - new Date().getTime();
        }

        if (status === 'Finished' && playlistState?.nextNodes.length === 0)
            return 'Finished';

        if (status !== 'Finished' && dueTime > 0) {
            if (warning === true)
                return 'Warning';
            else
                return 'On Track';
        }

        return 'Overdue';
    }, [playlistState, getState, curNode, warning]);

    const getNodeState = React.useMemo(() => (n: PlayListNodeDownloadDto, dif: number): NodeState => {
        let ret = {
            state: 'Overdue',
            finished: 0
        } as NodeState;

        if (playlistState) {
            _.forEach(n.referenceIds, refId => {
                const state = _.find(playlistState.itemStates, st => st.referenceId === refId) ?? _.find(playlistState.prevItemStates, st => st.referenceId === refId);
                if (state?.state === 'Finished')
                    ret.finished++;
            });

            const first = _.first(n.children);
            const transFunction = first?.transitionFunction;
            const transValue = first?.referenceValue ?? 0;
            const refId = first?.referenceContentId ?? '';

            if (transFunction === "FallThru") {
                ret.state = "Finished";
            }
            if (transFunction === "AbsCountDone") {
                const f = _.filter(playlistState.prevItemStates, st => st.state === "Finished");
                if (f.length >= transValue)
                    ret.state = "Finished";
            }
            if (transFunction === "PercentageDone") {
                const f = _.filter(playlistState.itemStates, st => st.state === "Finished");
                const doneNow = f.length / playlistState.itemStates.length;

                if (doneNow >= transValue)
                    ret.state = "Finished";
            }
            if (transFunction === "ReferenceDone") {
                const s = _.find(playlistState.prevItemStates, st => st.referenceId === refId);
                if (s && s.state === 'Finished')
                    ret.state = "Finished";
            }
            if (transFunction === "ReferenceDoneWithValue") {
                const s = _.find(playlistState.prevItemStates, st => st.referenceId === refId);
                if (s && s.state === 'Finished')
                    ret.state = "Finished";
            }
            if (n.referenceIds?.length === ret.finished)
                ret.state = 'Finished';
            else {
                if (dif >= 0 && ret.state !== 'Finished')
                    ret.state = 'On Track';
            }
        }
        return ret;
    }, [playlistState]);

    const isAllAtOnce = playlistState && playlistState.playlist ? (playlistState.playlist as PlaylistUploadDto).sequencingMode === "AllAtOnce" : false;
    const AAOcontents = playlistState && (playlistState.playlist as PlaylistUploadDto).contents;

    React.useEffect(() => {
        const loadSinglePostsWithContextByContextId = async (contextId: string) => {
            const tasks = _.map(AAOcontents, content => ControllerHelper.singleCall({ id: content.referenceId ?? '', context: contextId }, PostController.GetSinglePostWithContext, true));
            const res = await Promise.all(tasks);
            setRefPosts(p => {
                const toRet = _.clone(p);
                _.forEach(_.compact(res), r => toRet[r.id] = r);
                return toRet;
            });
        };
        if (AAOcontents) {
            const s = _.find(playlistsStates, p => p.playlist.id === id);
            if (s)
                loadSinglePostsWithContextByContextId(s.id);
        }
    }, [AAOcontents, id, playlistsStates]);

    const flushText = playlistTheme.flushText;

    const playlistIsFinished = playlistState?.state === "Finished";
    const itemsToBeDone = isAllAtOnce ? (AAOcontents?.length ?? 0) : (allNodesElementsToBeDoneCount ?? 0);
    const loadingData = !isAllAtOnce && Object.keys(refPosts).length === 0;

    React.useEffect(() => {
        let optional = 0;
        let finished = 0;
        let ontrack = 0;
        let overdue = 0;
        let warn = 0;
        _.forEach(nodesToShow, n => {
            const itemStates = playlistState?.itemStates;
            const prevItemStates = playlistState?.prevItemStates;
            const dif = differenceInDays(new Date(dueToDate as Date), new Date());
            const nodeState = getNodeState(n, dif);
            const stateOfNode = nodeState.state;
            const hasAbsoluteDueDate = n.dueDateRelativeTo === "Absolute" && n.absDueDate !== undefined;
            const hasRelativeDueDate = (n.dueDateRelativeTo !== undefined || n.dueDateRelativeTo !== "Absolute") && n.dueDateRelativeInHours !== undefined;
            const dueToType = hasAbsoluteDueDate ? "absolute" : hasRelativeDueDate ? "relative" : undefined;
            const transition = _.first(n.children)?.transitionFunction;

            _.forEach(n.referenceIds, (refId, idx) => {

                const isOptional = transition === "FallThru" || stateOfNode === "Finished";
                const state = _.find(itemStates, st => st.referenceId === refId)
                    ?? _.find(prevItemStates, st => st.referenceId === refId);

                const result = calculateDueInfo(dueToType, state?.state ?? 'Available', playlistState?.assignmentDate, n.absDueDate, n.dueDateRelativeInHours, isOptional, warning);

                if (result.text === 'optional')
                    optional++;
                else if (result.text === '100%')
                    finished++;
                else if (result.text === 'on Track')
                    ontrack++;
                else if (result.text === 'overdue')
                    overdue++;
                else if (result.text === 'warning')
                    warn++;
                else
                    console.log('unknown type!!!!');

            });
        });
        dispatch(Actions.setPlaylistFilterCount({ finished, ontrack, overdue, warning: warn, optional, total: (finished + ontrack + overdue + warn + optional) }));
    }, [nodesToShow, getNodeState, warning, playlistState, dueToDate, dispatch]);

    const [remainingDurationInMin, setRemainingDurationInMin] = React.useState<number>(0);


    React.useEffect(() => {
        if (playlistState) {
            const sumDuration = (nodes: PlayListNodeDownloadDto[] | undefined): number => {
                if (!nodes) {
                    return 0;
                }
                return nodes.reduce((total, node) => total + (node.durationInMinutes || 0), 0);
            };
            const prevNodesDuration = sumDuration(playlistState.prevNodes);
            const curNodesDuration = sumDuration(playlistState.curNodes);
            const nextNodesDuration = sumDuration(playlistState.nextNodes);
            const totalDurationAllNodes = prevNodesDuration + curNodesDuration + nextNodesDuration;
            const remaining = totalDurationAllNodes - prevNodesDuration;
            setRemainingDurationInMin(remaining);
        }
    }, [playlistState]);


    const remainingDuration = convertMinutesToDaysHoursMinutes(remainingDurationInMin, t, true)

    let criticalCount = 0;
    criticalCount += playlistState ? countNodeItemsToBeDonePerNode(playlistState, curNodes) : 0;
    criticalCount += playlistState ? countNodeItemsToBeDonePerNode(playlistState, prevNodes) : 0;
    criticalCount += playlistState ? countNodeItemsToBeDonePerNode(playlistState, nextNodes) : 0;

    const playlistStateIsOptional = playlistState?.optional;

    type PlaylistTab = "description" | "timeline" | "comment" | "rating" | "";

    const [selectedTab, setSelectedTab] = React.useState<PlaylistTab>("");

    const tabsSelect = React.useMemo(() => (idx: number | undefined) => {
        if (idx === 3) {
            setSelectedTab("timeline");
            scrollHelper.scrollTo("info");
        }
        else if (idx === 2) {
            setSelectedTab("description");
            scrollHelper.scrollTo("info");
        }
        else if (idx === 1) {
            setSelectedTab("comment");
            scrollHelper.scrollTo("bottom");
        }
        else if (idx === 0) {
            setSelectedTab("rating");
            scrollHelper.scrollTo("bottom");
        }
        else
            setSelectedTab("");
    }, [scrollHelper]);

    // const shouldShowRating = playlistState && playlistState.playlist.rating > 0 && contentWidth >= (sidebarRightOpen ? 1024 : 1400);

    // const RatingComponent = () => (
    //     <RatingView
    //         rating={playlistState?.playlist.rating ?? 0}
    //         maxStars={5}
    //         size={20}
    //         filledOut={true}
    //         filledColor='@middleLightGrey'
    //     />
    // );

    const [visibleNodes, setVisibleNodes] = React.useState<{ [key: string]: boolean }>({});

    const toggleVisibility = (nodeId: string) => {
        setVisibleNodes((prev) => ({
            ...prev,
            [nodeId]: !prev[nodeId],
        }));
    };
    const admin = isAdmin("WIKI");

    return (
        <div style={{ padding: isMobile ? "10px 0" : 0, paddingBottom: 50 }} ref={scrollHelper.getRef("info")}>

            {loading ?
                <Loader active size="small" light />
                :
                <div style={{ position: "relative", marginTop: -2 }}>
                    <T.BannerContainer imageUrl={banner ?? bannerTop.playlist} stickyHeader={true}>
                        <div style={{ position: "absolute", inset: 0, background: "linear-gradient(90deg, transparent 0%, rgba(255,255,255,0.7) 70%)" }} />
                        {data &&
                            <>
                                <T.BannerTitle>
                                    {getTranslated(data.headlines, contentLanguage)?.text}
                                    {playlistState && playlistState.playlist.rating > 0 && isTablet &&
                                        <div style={{ position: "absolute", right: `calc(5% + 4px + ${admin ? 110 : 70}px)`, top: 10, padding: "0 15px 0 10px", height: 40, background: "rgba(255,255,255,0.4)", boxShadow: "0 6px 8px -6px #000" }}>
                                            <div style={{ marginTop: -8 }} onClick={() => tabsSelect(0)}>
                                                <RatingView
                                                    rating={playlistState?.playlist.rating ?? 0}
                                                    maxStars={5}
                                                    size={20}
                                                    filledOut={true}
                                                    filledColor='@accentRed'
                                                />
                                            </div>
                                        </div>
                                    }
                                    {isTablet &&
                                        <div style={{
                                            position: "absolute", right: "calc(5% + 4px)", top: 10,
                                            width: "auto",
                                            height: 40, background: "rgba(255, 255, 255, 0.0)", boxShadow: "0 6px 8px -6px #000"
                                        }}>
                                            {admin &&
                                                <Button
                                                    kind='primary'
                                                    tooltip={{ tooltipText: t("edit this playlist in new window"), position: "bottom" }}
                                                    asHtmlLinkToRoute={linkToRoute(`playlist/editPlaylist/${id}`)}
                                                    asHtmlLinkToRouteTarget="_blank"
                                                    floated='right'
                                                    icon="playlist edit"
                                                />
                                            }
                                            <Button
                                                kind='cancelButton'
                                                tooltip={{ tooltipText: t("home"), position: "bottom" }}
                                                floated='right'
                                                icon="home"
                                                onClick={gotoNamedRouteMethod("playlist")}
                                            />
                                            <Button
                                                kind='cancelButton'
                                                tooltip={{ tooltipText: t("back"), position: "bottom" }}
                                                floated='right'
                                                icon="chevron left"
                                                onClick={() => History.goBack()}
                                            />

                                            {/* <Button iconColor={selectedTab === 'rating' ? "@accentRed" : undefined} kind={selectedTab === 'rating' ? "cancelButton" : 'cancelButton'} tooltip={{ tooltipText: t("goto ratings") }} floated='right' icon="star half" onClick={() => tabsSelect(0)} />
                                        {CurrentSettings.showCommentsInPlaylistDetail &&
                                            <Button iconColor={selectedTab === 'comment' ? "@accentRed" : undefined} kind={selectedTab === 'comment' ? "cancelButton" : 'cancelButton'} tooltip={{ tooltipText: t("goto comments") }} floated='right' icon="comment" onClick={() => tabsSelect(1)} />
                                        }
                                        <Button iconColor={selectedTab === 'description' ? "@accentRed" : undefined} kind={selectedTab === 'description' ? "cancelButton" : 'cancelButton'} tooltip={{ tooltipText: t("{{what}} desctiption", { what: selectedTab === 'description' ? "hide" : "show" }) }} floated='right' icon="info" onClick={() => selectedTab === 'description' ? tabsSelect(3) : tabsSelect(2)} />
                                        {!isAllAtOnce &&
                                            <Button iconColor={selectedTab === 'timeline' ? "@accentRed" : undefined} kind={selectedTab === 'timeline' ? "cancelButton" : 'cancelButton'} tooltip={{ tooltipText: t("{{what}} dates", { what: selectedTab === 'timeline' ? "hide" : "show" }) }} floated='right' icon="calendar" onClick={() => selectedTab === 'timeline' ? tabsSelect(undefined) : tabsSelect(3)} />
                                        } */}
                                        </div>
                                    }
                                </T.BannerTitle>
                            </>
                        }
                    </T.BannerContainer>

                    <Loader active={loadingData} size="small" light infoText={playlistState ? t('loading playlist states') : t("loading playlist phases")} />
                    {data &&
                        <>
                            <T.OuterContainerTopGridSticky stickyHeader={true}>
                                <T.TopGrid style={{ gridTemplateColumns: isMobile ? "1fr max-content max-content" : "1fr 1fr 1fr 1fr 1fr max-content", columnGap: isMobile ? 20 : 0, padding: "10px 0 0 0" }}>
                                    <div>
                                        <T.TopGridHeadline>{playlistStateIsOptional ? t("your playlist status") : contentWidth < 580 ? t("learning status") : t("current learning status")}</T.TopGridHeadline>
                                        {/* <T.TopGridContent color={(isAllAtOnce || playlistStateIsOptional) ? "On Track" : playlistIsFinished ? "Finished" : getCurrentLearningStatus()} style={{ fontWeight: 600 }}>
                                            {t((isAllAtOnce || playlistStateIsOptional) ? "on track" : playlistIsFinished ? "done" : getCurrentLearningStatus())} */}
                                        <T.TopGridContent
                                            color={playlistStateIsOptional ? undefined : playlistIsFinished ? "Finished" : isAllAtOnce ? "open" : getCurrentLearningStatus()}
                                            style={{ fontWeight: 600 }}>
                                            {t(playlistStateIsOptional ? "optional" : playlistIsFinished ? "done" : isAllAtOnce ? "open" : getCurrentLearningStatus())}
                                        </T.TopGridContent>
                                    </div>
                                    {(!isAllAtOnce && contentWidth >= 580) ?
                                        <div>
                                            <T.TopGridHeadline>{t("completed")}</T.TopGridHeadline>
                                            <T.TopGridContent style={{ display: "grid", gridTemplateColumns: "max-content 1fr" }}>
                                                <div>
                                                    {criticalCount} / {itemsToBeDone}
                                                </div>

                                                <div />
                                            </T.TopGridContent>
                                        </div>
                                        : <div />
                                    }
                                    {(isTablet && remainingDurationInMin > 0 && contentWidth >= 520) ?
                                        <div>
                                            <T.TopGridHeadline>{t("remaining duration")}</T.TopGridHeadline>
                                            <T.TopGridContent>
                                                {remainingDuration}
                                            </T.TopGridContent>
                                        </div>
                                        : <div />
                                    }
                                    {(!isAllAtOnce && dueDate && !playlistStateIsOptional) ?
                                        <div style={{ paddingRight: isMobile ? 60 : 0 }}>
                                            <T.TopGridHeadline>{t("next date")}</T.TopGridHeadline>
                                            <T.TopGridContent color={(new Date(dueDate as Date)) >= (new Date()) ? undefined : "Overdue"}>
                                                {dueDate && !isNaN(new Date(dueDate as Date).getTime()) ? (
                                                    (new Date(dueDate as Date)) >= (new Date())
                                                        ? getTranslatedStandardDate(new Date(dueDate as Date), "PP")
                                                        : getTranslatedStandardDate(new Date(dueDate as Date), "PP")
                                                    // : formatDistance(new Date(dueDate as Date), new Date(), { locale: getDateFnsLng, addSuffix: true })
                                                ) : (
                                                    t("invalid date")
                                                )}
                                            </T.TopGridContent>

                                        </div>
                                        : <div />
                                    }

                                    {(!isAllAtOnce && dueToDate && isTablet && !playlistStateIsOptional) ?
                                        <div>
                                            <T.TopGridHeadline>{t("final date")}</T.TopGridHeadline>
                                            <T.TopGridContent>{getTranslatedStandardDate(dueToDate, "PP")}</T.TopGridContent>
                                        </div>
                                        : <div />
                                    }
                                    <div>
                                        {!isTablet &&
                                            <div style={{ marginTop: -40 }}>
                                                {admin &&
                                                    <Button
                                                        kind='primary'
                                                        tooltip={{ tooltipText: t("edit this playlist in new window"), position: "bottom" }}
                                                        asHtmlLinkToRoute={linkToRoute(`playlist/editPlaylist/${id}`)}
                                                        asHtmlLinkToRouteTarget="_blank"
                                                        floated='right'
                                                        icon="playlist edit"
                                                    />
                                                }
                                                <Button
                                                    kind='cancelButton'
                                                    tooltip={{ tooltipText: t("home"), position: "bottom" }}
                                                    floated='right'
                                                    icon="home"
                                                    onClick={gotoNamedRouteMethod("playlist")}
                                                />
                                                <Button
                                                    kind='cancelButton'
                                                    tooltip={{ tooltipText: t("back"), position: "bottom" }}
                                                    floated='right'
                                                    icon="chevron left"
                                                    onClick={() => History.goBack()}
                                                />
                                            </div>
                                        }
                                        <Button iconColor={selectedTab === 'rating' ? "@accentRed" : undefined} kind={selectedTab === 'rating' ? "cancelButton" : 'cancelButton'} tooltip={{ tooltipText: t("goto ratings") }} floated='right' icon="star half" onClick={() => tabsSelect(0)} />
                                        {CurrentSettings.showCommentsInPlaylistDetail &&
                                            <Button iconColor={selectedTab === 'comment' ? "@accentRed" : undefined} kind={selectedTab === 'comment' ? "cancelButton" : 'cancelButton'} tooltip={{ tooltipText: t("goto comments") }} floated='right' icon="comment" onClick={() => tabsSelect(1)} />
                                        }
                                        <Button iconColor={selectedTab === 'description' ? "@accentRed" : undefined} kind={selectedTab === 'description' ? "cancelButton" : 'cancelButton'} tooltip={{ tooltipText: t("{{what}} desctiption", { what: selectedTab === 'description' ? "hide" : "show" }) }} floated='right' icon="info" onClick={() => selectedTab === 'description' ? tabsSelect(undefined) : tabsSelect(2)} />
                                        {!isAllAtOnce &&
                                            <Button iconColor={selectedTab === 'timeline' ? "@accentRed" : undefined} kind={selectedTab === 'timeline' ? "cancelButton" : 'cancelButton'} tooltip={{ tooltipText: t("{{what}} dates", { what: selectedTab === 'timeline' ? "hide" : "show" }) }} floated='right' icon="calendar" onClick={() => selectedTab === 'timeline' ? tabsSelect(undefined) : tabsSelect(3)} />
                                        }
                                    </div>
                                </T.TopGrid>
                                {selectedTab !== 'timeline' &&
                                    <T.OpenArea onClick={() => tabsSelect(3)}>
                                        <Icon name='chevron down double' color='transparent' size="24px" />
                                    </T.OpenArea>
                                }
                            </T.OuterContainerTopGridSticky>

                            <T.OuterContainer style={{ borderBottom: "none", marginTop: 10 }}>
                                {selectedTab === "description" &&
                                    <>
                                        <T.Description>
                                            <Icon name="info" style={{ float: "left", marginRight: 10 }} marginTop={2} />
                                            {t("description")}
                                        </T.Description>
                                        <T.DescriptionText>
                                            {data && <SafeHTML
                                                html={getTranslated(data.descriptions, contentLanguage)?.text.replace(/<p><br><\/p>/g, "")}
                                            />}
                                        </T.DescriptionText>
                                    </>
                                }
                                {selectedTab === "timeline" &&
                                    <>
                                        {!isAllAtOnce && !playlistStateIsOptional &&
                                            <T.TimelineSticky stickyHeader={true}>
                                                {playlistState &&
                                                    <>
                                                        <T.Description>
                                                            <Icon name="calendar" style={{ float: "left", marginRight: 10 }} marginTop={2} />
                                                            {t("dates")}
                                                        </T.Description>
                                                        <Timeline data={playlistState} onClose={() => tabsSelect(undefined)} />
                                                    </>
                                                }
                                            </T.TimelineSticky>
                                        }

                                    </>
                                }


                                {/* {!isMobile && <div style={{ height: 40 }} />} */}
                                {isAllAtOnce ?
                                    _.map(AAOcontents, (content, idx) => {
                                        const state = playlistState?.itemStates[idx];
                                        const item = state ? refPosts[state?.referenceId] : undefined;
                                        return (<React.Fragment key={content.referenceId + ':' + idx}>
                                            <PlaylistDetailNodeItem
                                                item={item ?? {} as PostDownloadDto}
                                                isMobile={isMobile}
                                                state={state ?? { state: 'Available' } as PlaylistItemStateDownloadDto}
                                                playlistId={playlistState?.playlist?.id ?? ""}
                                                orderNumber={idx + 1}
                                                playlistStateId={playlistState?.id ?? ''}
                                                assignmentDate={playlistState?.assignmentDate}
                                                dueToType={"absolute"}
                                                isOptional={false}
                                                warning={false}
                                                accentColor=''
                                                loadPlaylistStates={loadPlaylistStates}
                                                playlistContent={content}
                                            />
                                        </React.Fragment>
                                        );
                                    })
                                    :
                                    <>
                                        {_.map(nodesToShow, (n, index) => {
                                            const estDuration = n.durationInMinutes ? convertMinutesToDaysHoursMinutes(n.durationInMinutes, t, true) : undefined;
                                            const isAbsDueDate = n.dueDateRelativeTo && n.dueDateRelativeTo === "Absolute";
                                            const isRelDueDate = n.dueDateRelativeTo && n.dueDateRelativeTo !== "Absolute";
                                            const hasAbsDueDate = isAbsDueDate && n.absDueDate;
                                            const hasRelDueDate = isRelDueDate && n.dueDateRelativeInHours && n.dueDateRelativeInHours > 0;
                                            let dueToDate = hasAbsDueDate ? n.absDueDate : (hasRelDueDate && playlistState?.assignmentDate && n.dueDateRelativeInHours) ? addHoursToISODate(playlistState.assignmentDate, n.dueDateRelativeInHours) : new Date();
                                            const dif = differenceInDays(new Date(dueToDate as Date), new Date());
                                            // const dueToDateText = dif < 14 ? formatDistanceToNow(new Date(dueToDate as Date), { addSuffix: true, locale: getDateFnsLng }) : formatRelative(new Date(dueToDate as Date), new Date(), { locale: getDateFnsLng });
                                            const nodeState = getNodeState(n, dif);
                                            const stateOfNode = nodeState.state;
                                            const finished = playlistState ? countNodeItemsToBeDoneSingleNode(playlistState, n) : 0;
                                            const transition = _.first(n.children)?.transitionFunction;
                                            const isOptionalHint = transition === "FallThru" ? t("optional") : undefined;
                                            const hasAbsoluteDueDate = n.dueDateRelativeTo === "Absolute" && n.absDueDate !== undefined;
                                            const hasRelativeDueDate = (n.dueDateRelativeTo !== undefined || n.dueDateRelativeTo !== "Absolute") && n.dueDateRelativeInHours !== undefined;
                                            const dueToType = hasAbsoluteDueDate ? "absolute" : hasRelativeDueDate ? "relative" : undefined;
                                            // const dueToDateText = dif === 0 ? (isOptionalHint ?? "") : (dif < 14 ? formatRelative(new Date(dueToDate as Date), new Date(), { locale: getDateFnsLng }) : formatRelative(new Date(dueToDate as Date), new Date(), { locale: getDateFnsLng }));
                                            const dueToDateText = dif === 0 ? (isOptionalHint ?? "") : (dueToDate && getTranslatedStandardDate(dueToDate, "PP"));


                                            const chechIfIsToday = (dateValue: Date): boolean => {
                                                const today = new Date();

                                                return dateValue.getDate() === today.getDate() &&
                                                    dateValue.getMonth() === today.getMonth() &&
                                                    dateValue.getFullYear() === today.getFullYear();
                                            };

                                            const toCheck = new Date(dueToDate as Date);
                                            const isToday = chechIfIsToday(toCheck);

                                            const timeRunsOut = isToday || (warning && !playlistIsFinished);
                                            const isInPrevNode = _.filter(prevNodes, p => p.id === n.id).length > 0;

                                            let countNode = 0;
                                            if (_.first(n.children)?.transitionFunction === "FallThru" && Array.isArray(n.referenceIds)) {
                                                countNode += 0;
                                            }
                                            if (_.first(n.children)?.transitionFunction === "AbsCountDone" && _.first(n.children)?.referenceValue && Array.isArray(n.referenceIds)) {
                                                countNode += _.first(n.children)?.referenceValue ?? 0;
                                            }
                                            if ((_.first(n.children)?.transitionFunction === "ReferenceDone" || _.first(n.children)?.transitionFunction === "ReferenceDoneWithValue") && Array.isArray(n.referenceIds)) {
                                                const contentIds = n.children ? _.first(n.children)?.referenceContentIds?.length : 0;
                                                countNode += contentIds ?? 0;
                                            }
                                            if (_.first(n.children)?.transitionFunction === "PercentageDone" && Array.isArray(n.referenceIds)) {
                                                countNode += Math.ceil(n.referenceIds.length * (_.first(n.children)?.referenceValue ?? 0));
                                            }

                                            const finishedPercent = n.referenceIds ? (finished / countNode * 100) + "%" : "0%";
                                            const nodeOverdue = !timeRunsOut && stateOfNode === "Overdue";
                                            const nodeOnTrack = !timeRunsOut && stateOfNode === "On Track";
                                            const nodeFinished = !timeRunsOut && (stateOfNode === "Finished" || playlistIsFinished);
                                            const infoText = transition && getTransitionMessage(t, transition, hasNextNodes, playlistIsFinished, nodeFinished, _.first(n.children)?.referenceValue, n.referenceIds?.length, _.first(n.children)?.referenceContentId);

                                            return (
                                                <React.Fragment key={n.id}>
                                                    <T.NodeBar flushText={flushText} isMobile={isMobile} onClick={() => toggleNodeContent(index)}>
                                                        <T.NodeBarIcon isVisible={nodeContentVisible[index]}>
                                                            <Icon name={nodeContentVisible[index] ? "chevron up" : "chevron down"} size="20px" style={{ float: "left" }} marginTop={0} />
                                                        </T.NodeBarIcon>
                                                        <T.NodeBarTitle>
                                                            <SafeHTML html={getTranslated(n.headlines, contentLanguage)?.text} allowedTags={[]} allowedAttributes={{}} />
                                                        </T.NodeBarTitle>
                                                        {isMobile ?
                                                            <>
                                                                {isOptionalHint ?
                                                                    <T.NodeBarInfo flushText={flushText}>
                                                                        {isOptionalHint}
                                                                    </T.NodeBarInfo>
                                                                    :
                                                                    <T.NodeBarInfo flushText={flushText} color={isInPrevNode ? "@accentGreen" : timeRunsOut ? "@warning" : stateOfNode === "Overdue" ? "@accentRed" : stateOfNode === "Finished" ? "@accentGreen" : undefined} style={{ textAlign: flushText ? "left" : "right" }}>
                                                                        {timeRunsOut && !playlistStateIsOptional && <> <Icon name="exclamation triangle" style={{ float: "left", marginRight: 5 }} />{t("warning")}</>}
                                                                        {nodeFinished && !playlistStateIsOptional && <> <Icon name="check circle" style={{ float: "left", marginRight: 5 }} />{t("done")}</>}
                                                                        {nodeOnTrack && !playlistStateIsOptional && <> <Icon name={isInPrevNode ? "check circle" : "clock"} style={{ float: "left", marginRight: 5 }} />{isInPrevNode ? t("done") : t("on track")}</>}
                                                                        {nodeOverdue && !playlistStateIsOptional && <> <Icon name="exclamation circle" style={{ float: "left", marginRight: 5 }} />{t("overdue")}</>}
                                                                    </T.NodeBarInfo>
                                                                }
                                                            </>
                                                            :
                                                            <>
                                                                {!isOptionalHint &&
                                                                    <T.NodeBarInfo
                                                                        flushText={flushText}
                                                                        color={isInPrevNode ? "@accentGreen" : timeRunsOut ? "@warning" : stateOfNode === "Overdue" ? "@accentRed" : stateOfNode === "Finished" ? "@accentGreen" : undefined}
                                                                        style={{ textAlign: flushText ? "left" : "right", width: 200 }}
                                                                    >
                                                                        {nodeFinished ?
                                                                            <>
                                                                                <Icon name="check circle" style={{ float: flushText ? "left" : "right", marginRight: flushText ? 5 : 0, marginLeft: flushText ? 0 : 5 }} />
                                                                                {t("done")}
                                                                            </>
                                                                            :
                                                                            <>
                                                                                {timeRunsOut && !isInPrevNode && !playlistStateIsOptional &&
                                                                                    <>
                                                                                        <Icon name="exclamation triangle" style={{ float: flushText ? "left" : "right", marginRight: flushText ? 5 : 0, marginLeft: flushText ? 0 : 5 }} />
                                                                                        {t("warning")}
                                                                                    </>
                                                                                }
                                                                                {nodeOnTrack && !isInPrevNode && !playlistStateIsOptional &&
                                                                                    <>
                                                                                        <Icon name={"clock"} style={{ float: flushText ? "left" : "right", marginRight: flushText ? 5 : 0, marginLeft: flushText ? 0 : 5 }} />
                                                                                        {t("on track")}
                                                                                    </>
                                                                                }
                                                                                {nodeOverdue && !isInPrevNode && !playlistStateIsOptional &&
                                                                                    <>
                                                                                        <Icon name="exclamation circle" style={{ float: flushText ? "left" : "right", marginRight: flushText ? 5 : 0, marginLeft: flushText ? 0 : 5 }} />
                                                                                        {t("overdue")}
                                                                                    </>
                                                                                }

                                                                                {isInPrevNode &&
                                                                                    <>
                                                                                        <Icon name="check circle" style={{ float: flushText ? "left" : "right", marginRight: flushText ? 5 : 0, marginLeft: flushText ? 0 : 5 }} />
                                                                                        {t("done")}
                                                                                    </>
                                                                                }
                                                                            </>
                                                                        }
                                                                    </T.NodeBarInfo>
                                                                }
                                                                {playlistStateIsOptional ?
                                                                    <T.NodeBarInfo flushText={flushText} style={{ width: 100 }} />
                                                                    :
                                                                    <T.NodeBarInfo flushText={flushText} style={{ width: 100 }}>
                                                                        {estDuration}
                                                                    </T.NodeBarInfo>
                                                                }
                                                                {playlistStateIsOptional ?
                                                                    <T.NodeBarInfo flushText={flushText} style={{ width: 150 }}>
                                                                        {estDuration}
                                                                    </T.NodeBarInfo>
                                                                    :
                                                                    // isOptionalHint ?
                                                                    //     <T.NodeBarInfo flushText={flushText} style={{ width: 150 }}>
                                                                    //         <T.DueDateOptionalHint>{isOptionalHint}</T.DueDateOptionalHint>
                                                                    //         {dueToDateText}
                                                                    //     </T.NodeBarInfo>
                                                                    //     :
                                                                    <T.NodeBarInfo flushText={flushText} style={{ width: 150 }}>
                                                                        {dueToDateText}
                                                                    </T.NodeBarInfo>
                                                                }

                                                            </>
                                                        }
                                                    </T.NodeBar>
                                                    {(nodeContentVisible[index]) ?
                                                        <T.NodeContentContainer ref={scrollHelper.getRef(n.id ?? '')} >
                                                            <T.NodeContentContainerInfoLine>
                                                                <div>
                                                                    {t("{{done}} / {{of}} completed", { done: finished, of: countNode })}
                                                                </div>


                                                                {infoText && !playlistIsFinished && !nodeFinished &&
                                                                    <T.NodeContentContainerHint >
                                                                        <Icon name="info" style={{ float: "left", marginRight: 5 }} marginTop={5} />
                                                                        {infoText}
                                                                    </T.NodeContentContainerHint>
                                                                }

                                                                <div style={{ position: "absolute", bottom: -1, left: 0, width: finishedPercent, height: 1, background: "#313639" }} />
                                                            </T.NodeContentContainerInfoLine>

                                                            {n.descriptions && n.descriptions.length > 0 &&
                                                                <T.NodeContentContainerInfoLine style={{ lineHeight: "unset" }}>
                                                                    <SafeHTML html={getTranslated(n.descriptions, contentLanguage)?.text} />
                                                                </T.NodeContentContainerInfoLine>
                                                            }

                                                            {Object.keys(refPosts).length > 0 && _.map(n.referenceIds, (refId, idx) => {
                                                                const p = refPosts[refId];
                                                                if (p === undefined)
                                                                    return false;
                                                                const key = p.id;
                                                                const isOptional = transition === "FallThru" || stateOfNode === "Finished";
                                                                const state = _.find(playlistState?.itemStates, st => st.referenceId === refId)
                                                                    ?? _.find(playlistState?.prevItemStates, st => st.referenceId === refId);

                                                                const firstChild = _.first(n.children);
                                                                const transIsRef = firstChild?.transitionFunction === "ReferenceDone" || firstChild?.transitionFunction === "ReferenceDoneWithValue";
                                                                const transIsRefVal = (firstChild && firstChild.referenceValue);
                                                                const nodeReferences: string[] | undefined = (firstChild && transIsRef && transIsRefVal) ? firstChild.referenceContentIds : undefined;
                                                                const nodeReference = _.find(nodeReferences, nr => nr === state?.referenceId) ?? undefined;
                                                                const content = _.find((playlistState?.playlist as PlaylistDownloadDto)?.contents, c => c.referenceId === refId);

                                                                return (<React.Fragment key={key}>
                                                                    <PlaylistDetailNodeItem
                                                                        item={p}
                                                                        isMobile={isMobile}
                                                                        state={state ?? { state: 'NotAvailable' } as PlaylistItemStateDownloadDto}
                                                                        playlistId={playlistState?.playlist?.id}
                                                                        orderNumber={idx + 1}
                                                                        playlistStateId={playlistState?.id ?? ''}
                                                                        isOptional={isOptional || nodeFinished || playlistStateIsOptional}
                                                                        dueToType={dueToType}
                                                                        assignmentDate={playlistState?.assignmentDate}
                                                                        absoluteDueDate={n.absDueDate}
                                                                        dueDateRelativeInHours={n.dueDateRelativeInHours}
                                                                        nodeReference={nodeReference}
                                                                        transition={firstChild?.transitionFunction}
                                                                        warning={timeRunsOut}
                                                                        accentColor=''
                                                                        nodeIsDone={isInPrevNode}
                                                                        loadPlaylistStates={loadPlaylistStates}
                                                                        playlistContent={content}
                                                                    />
                                                                </React.Fragment>
                                                                );
                                                            })}
                                                        </T.NodeContentContainer>
                                                        :
                                                        <div style={{ height: 24 }} />
                                                    }
                                                </React.Fragment>
                                            );
                                        })}
                                        <div style={{ height: 8 }} />
                                        {nextNodeHeadline}
                                        {_.map(nextNodes, (n) => {
                                            const isAbsDueDate = n.dueDateRelativeTo && n.dueDateRelativeTo === "Absolute";
                                            const isRelDueDate = n.dueDateRelativeTo && n.dueDateRelativeTo !== "Absolute";
                                            const hasAbsDueDate = isAbsDueDate && n.absDueDate;
                                            const hasRelDueDate = isRelDueDate && n.dueDateRelativeInHours && n.dueDateRelativeInHours > 0;
                                            const dueToDate = hasAbsDueDate ? n.absDueDate : (hasRelDueDate && playlistState?.assignmentDate && n.dueDateRelativeInHours) ? addHoursToISODate(playlistState.assignmentDate, n.dueDateRelativeInHours) : undefined;
                                            const dueToDateText = dueToDate && getTranslatedStandardDate(dueToDate, "PP");
                                            const estDuration = n.durationInMinutes ? convertMinutesToDaysHoursMinutes(n.durationInMinutes, t, true) : undefined;
                                            const isVisible = visibleNodes[n.id as string] ?? false;
                                            const showPreviewButton = n.visible === true;

                                            return (
                                                <React.Fragment key={n.id}>
                                                    <T.NodeBarNext flushText={flushText} active={showPreviewButton} onClick={showPreviewButton ? () => toggleVisibility(n.id as string) : undefined}>
                                                        <T.NodeBarTitle style={{ display: "grid", gridTemplateColumns: "max-content 1fr max-content" }}>
                                                            {showPreviewButton ?
                                                                <T.NodeBarIconPreview isVisible={isVisible}>
                                                                    <Icon name={isVisible ? 'chevron up' : 'chevron down'} size="20px" style={{ float: "left" }} marginTop={0} />
                                                                </T.NodeBarIconPreview>
                                                                :
                                                                <div />
                                                            }
                                                            <div style={{ paddingLeft: showPreviewButton ? 0 : 4 }}>
                                                                <SafeHTML html={getTranslated(n.headlines, contentLanguage)?.text} allowedTags={[]} allowedAttributes={{}} />
                                                            </div>
                                                            {showPreviewButton && isVisible ?
                                                                <T.NodeBarTextPreview>
                                                                    <Icon name="info" style={{ float: "left", marginRight: 5 }} marginTop={1} color='@accentRed' />
                                                                    {t("preview only")}
                                                                </T.NodeBarTextPreview>
                                                                :
                                                                <div />
                                                            }
                                                        </T.NodeBarTitle>
                                                        <T.NodeBarInfo flushText={!flushText} style={{ width: 100 }}>{estDuration}</T.NodeBarInfo>
                                                        <div>
                                                            {!playlistStateIsOptional &&
                                                                <T.NodeBarInfo flushText={flushText} style={{ width: 150 }}>
                                                                    {_.first(n.children)?.transitionFunction === "FallThru" && <T.DueDateOptionalHint>({t("optional")})</T.DueDateOptionalHint>}
                                                                    {dueToDateText}
                                                                </T.NodeBarInfo>
                                                            }
                                                        </div>

                                                    </T.NodeBarNext>
                                                    {isVisible &&
                                                        <T.NodeBarPreviewContainer>
                                                            {_.map(n.referenceIds, (refId, idx) => {
                                                                const p = refPosts[refId];
                                                                if (p === undefined)
                                                                    return false;
                                                                const key = p.id;
                                                                const isOptional = false;
                                                                const state = _.find(playlistState?.itemStates, st => st.referenceId === refId)
                                                                    ?? _.find(playlistState?.prevItemStates, st => st.referenceId === refId);

                                                                const firstChild = _.first(n.children);
                                                                const transIsRef = firstChild?.transitionFunction === "ReferenceDone" || firstChild?.transitionFunction === "ReferenceDoneWithValue";
                                                                const transIsRefVal = (firstChild && firstChild.referenceValue);
                                                                const nodeReferences: string[] | undefined = (firstChild && transIsRef && transIsRefVal) ? firstChild.referenceContentIds : undefined;
                                                                const nodeReference = _.find(nodeReferences, nr => nr === state?.referenceId) ?? undefined;
                                                                const content = _.find((playlistState?.playlist as PlaylistDownloadDto)?.contents, c => c.referenceId === refId);
                                                                return (
                                                                    <React.Fragment key={key}>
                                                                        <PlaylistDetailNodeItem
                                                                            item={p}
                                                                            isMobile={isMobile}
                                                                            state={state ?? { state: 'NotAvailable' } as PlaylistItemStateDownloadDto}
                                                                            playlistId={playlistState?.playlist?.id}
                                                                            orderNumber={idx + 1}
                                                                            playlistStateId={playlistState?.id ?? ''}
                                                                            isOptional={isOptional || playlistStateIsOptional}
                                                                            dueToType={undefined}
                                                                            assignmentDate={playlistState?.assignmentDate}
                                                                            absoluteDueDate={n.absDueDate}
                                                                            dueDateRelativeInHours={n.dueDateRelativeInHours}
                                                                            nodeReference={nodeReference}
                                                                            transition={firstChild?.transitionFunction}
                                                                            accentColor=''
                                                                            loadPlaylistStates={loadPlaylistStates}
                                                                            playlistContent={content}
                                                                            preview={true}
                                                                        />
                                                                    </React.Fragment>
                                                                );
                                                            })}
                                                        </T.NodeBarPreviewContainer>
                                                    }
                                                </React.Fragment>
                                            );
                                        })}

                                    </>
                                }
                                <div ref={scrollHelper.getRef("bottom")} style={{ marginTop: 20 }} />
                                {/* {playlistState &&
                                    <div style={{ border: "1px solid #cfcfcf" }}>
                                        <CommentsRatingsView
                                            post={playlistState?.playlist}
                                            // onCountChanged={this.ratingCountChanged}
                                            noLeftBorder
                                            asRating
                                            getIncoming={PostInteraction.GetPostRatings}
                                            addCommentRating={PostInteraction.AddPostRating}
                                            commentsOptional
                                            filledColor={"@accentBlack"}
                                            filledOut={true}
                                            showNoPostInfo
                                            autoFocus={false}
                                        />
                                    </div>
                                } */}
                                <TabsBar
                                    input={[
                                        {
                                            title: t("ratings"),
                                            icon: "star half",
                                            active: selectedTab !== "comment",
                                        },
                                        {
                                            title: t("comments"),
                                            icon: "comment",
                                            active: selectedTab === "comment",
                                            hidden: !CurrentSettings.showCommentsInPlaylistDetail
                                        },
                                    ]}
                                    onChange={tabsSelect}
                                    activeBold
                                />
                                <T.InfoBoxBottom mobile={isMobile}>
                                    {playlistState &&
                                        <>
                                            {selectedTab !== "comment" ?
                                                <CommentsRatingsView
                                                    post={playlistState?.playlist}
                                                    // onCountChanged={this.ratingCountChanged}
                                                    noLeftBorder
                                                    asRating
                                                    getIncoming={PostInteraction.GetPostRatings}
                                                    addCommentRating={PostInteraction.AddPostRating}
                                                    // commentsOptional
                                                    filledColor={"@accentBlack"}
                                                    filledOut={true}
                                                    showNoPostInfo
                                                    autoFocus={false}
                                                />
                                                :
                                                <CommentsRatingsView
                                                    post={playlistState?.playlist}
                                                    noLeftBorder
                                                    getIncoming={PostInteraction.GetPostComments}
                                                    addCommentRating={PostInteraction.AddPostComment}
                                                    onDeleteComment={PostInteraction.DeletePostComment}
                                                    onSubmitComment={PostInteraction.UpdatePostComment}
                                                    showNoPostInfo
                                                    autoFocus={false}
                                                />
                                            }

                                        </>
                                    }
                                </T.InfoBoxBottom>


                            </T.OuterContainer>
                        </>
                    }
                </div>
            }
        </div >
    );
}
export default PlaylistDetail;
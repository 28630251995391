
import { ControllerHelper, PowerBiController, PowerBiObjectDownloadDto, PowerBiObjectInfoDownloadDto, PowerBiPermissionListDownloadDto } from 'collaboration-service';
import InfoBox from 'components/InfoBox/InfoBox';
import TabsBar from 'components/TabsBar/TabsBar';
import { Button, getColor, Loader, styled } from 'imaginarity-react-ui';
import * as _ from 'lodash';
import { models } from 'powerbi-client';
import { PowerBIEmbed } from 'powerbi-client-react';
import * as React from 'react';
import { gotoNamedRouteMethod } from 'services/Helpers/RoutingHelper';
import { useIsInSpecificGroup } from 'services/StoreDependantHelpers';

const OuterContainer = styled.div`
    height: calc(100vh - 140px);
    overflow-x: hidden;
    overflow-y: auto;
    padding-top: 0px;
    border: 1px solid ${p => getColor(p, "@middleLightGrey")};
    border-top: none;
    box-shadow: 0 6px 8px -6px #313639;
`;

export type MyHomeReportTab = "GLP" | "CMS" | "GLPAdmin" | "GLPOverview";
export interface MyHomeStartGLPRProps {
}

const MyHomeStartGLPR = (p: MyHomeStartGLPRProps) => {




    console.debug("loading of report started");

    const [selectedTab, setSelectedTab] = React.useState<number>(0);
    const [loading, setLoading] = React.useState<boolean>(false);

    const [loadingAvReports, setLoadingReports] = React.useState<PowerBiObjectInfoDownloadDto[]>();

    const [report, setReport] = React.useState<PowerBiObjectDownloadDto>();
    const [permissions, setPermissions] = React.useState<PowerBiPermissionListDownloadDto>();


    //const hasGLP = useIsInGroup("REPORTING");
    const hasGLPAdmin = useIsInSpecificGroup(["fd442634-9741-479d-b21c-919c25395087", "9de2c204-b0d6-436a-9f8a-09dee1817e5d"]);
    //const hasCMS = useIsInGroup("REPORTING");


    React.useEffect(() => {
        setLoading(true);

        const l = async () => {

            const poBiReportList: any = await ControllerHelper.singleCall({}, PowerBiController.GetReportingInfo);
            setLoadingReports(poBiReportList);

        };
        l();
    }, []);

    React.useEffect(() => {
        setLoading(true);

        const l = async () => {

            console.debug("call API");

            if (loadingAvReports && loadingAvReports[selectedTab]) {
                const poBiReport: PowerBiObjectDownloadDto = await ControllerHelper.singleCall({ reportId: loadingAvReports[selectedTab].objectId }, PowerBiController.GetReport, true);

                if (poBiReport?.permissionsList !== undefined) {
                    setPermissions(poBiReport.permissionsList);
                }
                setReport(poBiReport);
            }
            else {
                setReport(undefined);
            }

            setLoading(false);
        };
        l();
    }, [selectedTab, loadingAvReports]);


    const tabsSelect = React.useMemo(() => async (idx: number) => {
        setSelectedTab(idx);
    }, []);

    const advancedFilter = [{
        $schema: "https://powerbi.com/product/schema#advanced",
        target: {
            table: "GV_PPX_EXPORT_MODUL",
            column: "FilterString"
        },
        logicalOperator: "Or",
        conditions: [{
            operator: "DoesNotStartWith",
            value: "1"
        }],
        filterType: models.FilterType.Advanced
    }]
    const adminFilter = [{
        $schema: "https://powerbi.com/product/schema#advanced",
        target: {
            table: "GV_PPX_EXPORT_MODUL",
            column: "Curriculum_Title"
        },
        logicalOperator: "Or",
        conditions: [{
            operator: "Contains",
            value: "["
        }],
        filterType: models.FilterType.Advanced
    }];

    permissions?.marketList.forEach(element => {
        console.debug("M " + element);
        let con = {
            operator: "Contains",
            value: "1$" + element + "2$"
        }
        advancedFilter[0].conditions.push(con);

    });
    permissions?.countryList.forEach(element => {
        console.debug("C " + element);
        let con = {
            operator: "Contains",
            value: "2$" + element + "3$"
        }
        advancedFilter[0].conditions.push(con);
    });
    permissions?.retailerList.forEach(element => {
        console.debug("R " + element);
        let con = {
            operator: "Contains",
            value: "3$" + element + "4$"
        }
        advancedFilter[0].conditions.push(con);
    });

    const noFilter = advancedFilter[0].conditions.length === 1;
    console.debug(loadingAvReports);

    return (
        <div>
            <Loader active={loading} infoText='loading report data' size='small' light />
            <TabsBar
                // use Custom array to create Tabs // automaticly will me a tab for each arrayMember
                input={
                    _.map(loadingAvReports, (r, i) => ({

                        title: r.name,
                        icon: "file",
                        active: selectedTab === i

                    }))
                }
                onChange={tabsSelect}
                additionalContent={<Button kind="cancelButton" onClick={gotoNamedRouteMethod("myhome")} floated='right' icon='chevron left' />}
                verticalSlider
                activeBold
            // shadowed
            />
            {report ?
                <OuterContainer >
                    <PowerBIEmbed
                        embedConfig={{
                            type: 'report',   // Supported types: report, dashboard, tile, visual, qna, paginated report and create
                            id: report.objectId,
                            embedUrl: report.embedUrl,
                            accessToken: report.token,
                            tokenType: models.TokenType.Embed, // Use models.TokenType.Aad for SaaS embed
                            filters: hasGLPAdmin ? adminFilter : !noFilter ? advancedFilter : adminFilter,

                            settings: {
                                filterPaneEnabled: hasGLPAdmin,
                                navContentPaneEnabled: false,
                            }
                        }}

                        cssClassName={
                            "report-style-class"
                        }

                        eventHandlers={
                            new Map([
                                ['loaded', function () { console.log('Report loaded'); }],
                                ['rendered', function () { console.log('Report rendered'); }],
                                ['error', function (event: any) { console.log(event.detail); }],
                                ['visualClicked', () => console.log('visual clicked')],
                                ['pageChanged', (event) => console.log(event)],
                            ])
                        }

                        getEmbeddedComponent={(embeddedReport) => {
                            //this.report = embeddedReport as Report;
                        }}
                    />
                </OuterContainer>
                :
                <div style={{ marginTop: 20 }}>
                    {!loading &&
                        <InfoBox type='alert' content='no data found' icon='exclamation circle' />
                    }
                </div>
            }
        </div>
    );

}
export default MyHomeStartGLPR;

import * as _ from "lodash";
import * as React from 'react';
import PostHubConnection, { PostHubEventType, PostHubRegistration, PostUpdateCallback } from 'services/signalR/PostHubConnection';

export interface UsePostHubProps {
    onUpdate: PostUpdateCallback;
    ids: string | string[];
    eventTypes?: PostHubEventType[];
}

const usePostHub = (p: UsePostHubProps) => {
    const [pr, setPr] = React.useState<PostHubRegistration>();
    const { onUpdate, ids, eventTypes } = p;

    React.useEffect(() => {
        const newPR = eventTypes ? PostHubConnection.getInstance().subscribeMultiple(onUpdate, eventTypes) : PostHubConnection.getInstance().subscribe(onUpdate);
        setPr(newPR);
        return () => {
            if (newPR)
                newPR.unsubscribe();
        }
    }, [onUpdate, eventTypes])

    React.useEffect(() => {
        if (pr)
            pr.signForIds(_.isArray(ids) ? ids : [ids]);
    }, [pr, ids])
}
export default usePostHub;
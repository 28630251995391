import { PlaylistStateDownloadDto, PlaylistUploadDto } from 'collaboration-service';
import { Button, Checkbox, Icon, ImgSelect, Input, Loader, aboveBreakpoint, aboveBreakpointOrEqual, belowBreakpointOrEqual, useThemePart } from 'imaginarity-react-ui';
import _ from 'lodash';
import Carousel from 'nuka-carousel';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { WorkingActions } from 'services/ApplicationState/Actions';
import { ApplicationState, useAppDispatch } from 'services/ApplicationState/ApplicationState';
import { countNodeItemsToBeDonePerNode, getCriticalAndPendingCounts, getPlaylistStateDueDate, getProgressStatus, shallowCompare } from 'services/Helpers';
import { gotoNamedRouteMethod } from 'services/Helpers/RoutingHelper';
import { getTranslated } from 'services/Helpers/TranslationHelpers';
import { useImgI18N } from 'services/ImgI18N';
import { isAdmin } from 'services/StoreDependantHelpers';
import { ThemeContext } from 'styled-components';
import { getSlideProperties } from 'views/MyHome/myHomeHelpers';
import PlaylistOverviewCarouselItem from './PlaylistOverviewCarouselItem';
import PlaylistOverviewItem from './PlaylistOverviewItem';
import { PlaylistSC as T } from './PlaylistSC';
import History from 'services/History';
import Pagination from 'components/Pagination/Pagination';
import InfoBox from 'components/InfoBox/InfoBox';
import { FloatedRight } from 'layout/masterLayouts/MasterLayoutsSC';

interface ITS {
    value: number;
    label: string;
}

const itemsToShow: ITS[] = [
    { value: 5, label: "5" },
    { value: 10, label: "10" },
    { value: 20, label: "20" },
    { value: 50, label: "50" },
];

const mapper = (state: ApplicationState) => ({
    playlistStatesTopic: state.playlistsState.playlistStatesTopic,
    contentLanguage: state.contentLanguage,
});

interface PlaylistOverviewProps {
}

type SortBy = "assigned" | "name" | "duedate" | "duration" | "progress";
const PlaylistOverview = (p: PlaylistOverviewProps) => {
    const { playlistStatesTopic, contentLanguage } = useSelector(mapper, shallowCompare);

    const dispatch = useAppDispatch();
    const { t } = useImgI18N("playlist");
    const contentWidth = useThemePart(x => x.content.width);
    const { slidesToShow, slidesGap, slideWidth } = getSlideProperties(contentWidth);
    const [showBtns, setShowBtns] = React.useState<boolean>(false);
    const [showCarousel, setShowCarousel] = React.useState<boolean>(true);
    const [sortedLists, setSortedLists] = React.useState<{ undone: PlaylistStateDownloadDto[], done: PlaylistStateDownloadDto[], optional: PlaylistStateDownloadDto[], undoneStarted: PlaylistStateDownloadDto[] }>({ undone: [], done: [], optional: [], undoneStarted: [] });
    const [sortBy, setSortBy] = React.useState<SortBy>("duedate");
    const [reverseSort, setReverseSort] = React.useState(false);
    const [searchTerm, setSearchTerm] = React.useState<string>("");

    const carHeight = React.useMemo(() => {
        return Math.ceil(((contentWidth - ((slidesToShow - 1) * slidesGap)) / 4) * 9 / 16) + 69;
    }, [contentWidth, slidesToShow, slidesGap]);

    const [pageCountUndone, setPageCountUndone] = React.useState<number>(0);
    const [pageCountOptional, setPageCountOptional] = React.useState<number>(0);
    const [pageCountDone, setPageCountDone] = React.useState<number>(0);
    const [itemsPerPage, setItemsPerPage] = React.useState<ITS | undefined>({ value: 10, label: "10" });

    const undoneStartedSortedByDate = React.useMemo(() => {
        return [...sortedLists.undoneStarted].sort((a, b) =>
            new Date(b.playlist.changed).getTime() - new Date(a.playlist.changed).getTime()
        );
    }, [sortedLists.undoneStarted]);

    const listLinear = _.filter(sortedLists.undone, f => (f.playlist as PlaylistUploadDto).sequencingMode === "Linear");
    const hasMandatoryEntries = sortedLists.undone.length > 0;
    const hasOptionalEntries = sortedLists.optional.length > 0;
    const hasDoneEntries = sortedLists.done.length > 0;

    React.useEffect(() => {
        dispatch(WorkingActions.WIKI.loadPlaylistState());
    }, [dispatch]);

    const loading = playlistStatesTopic === undefined;

    const filteredLists = React.useMemo(() => {
        const filteredNotDone = _.filter(playlistStatesTopic, p =>
            p.state !== "Finished" &&
            p.optional === false &&
            p.playlist.headlines.some(headline => headline.text.toLowerCase().includes(searchTerm.toLowerCase()))
        );
        const filterDone = _.filter(playlistStatesTopic,
            p => p.state === "Finished" &&
                p.playlist.headlines.some(headline => headline.text.toLowerCase().includes(searchTerm.toLowerCase()))
        );
        const filteredOptional = _.filter(playlistStatesTopic,
            p => p.state !== "Finished" &&
                p.optional === true &&
                p.playlist.headlines.some(headline => headline.text.toLowerCase().includes(searchTerm.toLowerCase()))
        );
        const filteredNotDoneStarted = _.filter(playlistStatesTopic, p => {
            let criticalCount = 0;
            criticalCount += countNodeItemsToBeDonePerNode(p, p.curNodes);
            criticalCount += countNodeItemsToBeDonePerNode(p, p.prevNodes);
            criticalCount += countNodeItemsToBeDonePerNode(p, p.nextNodes);

            return (
                p.state !== "Finished" &&
                p.optional === false &&
                criticalCount > 0 &&
                p.playlist.headlines.some(headline => headline.text.toLowerCase().includes(searchTerm.toLowerCase()))
            );
        });
        return [filteredNotDone, filterDone, filteredOptional, filteredNotDoneStarted]
    }, [playlistStatesTopic, searchTerm]);

    React.useEffect(() => {
        const [filteredNotDone, filterDone, filteredOptional, filteredNotDoneStarted] = filteredLists;
        let sortFunction: _.ListIteratee<PlaylistStateDownloadDto>;
        switch (sortBy) {
            case 'assigned':
                sortFunction = (p: PlaylistStateDownloadDto) => p.assignmentDate;
                break;
            case 'name':
                sortFunction = (p: PlaylistStateDownloadDto) => getTranslated(p.playlist.headlines, contentLanguage)?.text ?? "";
                break;
            case 'duedate':
                sortFunction = getPlaylistStateDueDate;
                break;
            case 'duration':
                sortFunction = (p: PlaylistStateDownloadDto) => {
                    const durations = p.curNodes
                        .concat(p.prevNodes)
                        .concat(p.nextNodes)
                        .map(node => node.durationInMinutes || 0);

                    return durations.reduce((total, duration) => total + duration, 0);
                };
                break;
            case 'progress':
                sortFunction = (p: PlaylistStateDownloadDto) => {
                    const curNode = p.curNodes[0];
                    const progressStatus = getProgressStatus(curNode, p);
                    const toBeDoneResult = getCriticalAndPendingCounts(p);
                    const x = toBeDoneResult.criticalCount;

                    switch (progressStatus) {
                        case "done":
                            return 0 + x;
                        case "overdue":
                            return 100 + x;
                        case "warning":
                            return 200 + x;
                        case "on Track":
                            return 300 + x;
                    }
                };
                break;
        }

        if (reverseSort)
            setSortedLists({
                undone: _.reverse(_.sortBy(filteredNotDone, sortFunction)),
                done: _.reverse(_.sortBy(filterDone, sortFunction)),
                optional: _.reverse(_.sortBy(filteredOptional, sortFunction)),
                undoneStarted: _.reverse(_.sortBy(filteredNotDoneStarted, sortFunction)),
            });
        else
            setSortedLists({
                undone: _.sortBy(filteredNotDone, sortFunction),
                done: _.sortBy(filterDone, sortFunction),
                optional: _.sortBy(filteredOptional, sortFunction),
                undoneStarted: _.sortBy(filteredNotDoneStarted, sortFunction),
            });

    }, [sortBy, contentLanguage, filteredLists, reverseSort])

    const theme = React.useContext(ThemeContext);
    const isMobile = belowBreakpointOrEqual({ theme }, "largeMobile");
    const isTablet = aboveBreakpoint({ theme }, "largeMobile");
    const isLaptop = aboveBreakpointOrEqual({ theme }, "tablet");
    const isDesktop = aboveBreakpoint({ theme }, "desktop");
    const admin = isAdmin("WIKI");
    const showDescs = contentWidth > 800;

    const setSort = React.useCallback((by: SortBy) => () => {
        setSortBy(a => {
            setReverseSort(b => by === a ? !b : false);
            return by;
        })
    }, []);

    const getVisibleItems = React.useCallback(
        (list: PlaylistStateDownloadDto[], pageCount: number) => {
            if (!itemsPerPage) return list;
            const perPage = itemsPerPage.value;
            const startIndex = pageCount * perPage;
            const endIndex = startIndex + perPage;
            return list.slice(startIndex, endIndex);
        },
        [itemsPerPage]
    );

    const visibleUndone = React.useMemo(() => getVisibleItems(sortedLists.undone, pageCountUndone), [sortedLists.undone, pageCountUndone, getVisibleItems]);
    const visibleOptional = React.useMemo(() => getVisibleItems(sortedLists.optional, pageCountOptional), [sortedLists.optional, pageCountOptional, getVisibleItems]);
    const visibleDone = React.useMemo(() => getVisibleItems(sortedLists.done, pageCountDone), [sortedLists.done, pageCountDone, getVisibleItems]);

    const paginateUndone = React.useCallback((i: number) => setPageCountUndone(i), []);
    const paginateOptional = React.useCallback((i: number) => setPageCountOptional(i), []);
    const paginateDone = React.useCallback((i: number) => setPageCountDone(i), []);

    const hasResults = sortedLists.undoneStarted.length > 0 || sortedLists.done.length > 0 || sortedLists.optional.length > 0 || sortedLists.undone.length > 0;

    return (
        <div style={{ padding: isMobile ? 10 : 0, paddingBottom: 50 }}>
            <T.HeadlineTop isMobile={isMobile}>
                <div style={{ lineHeight: "40px", fontSize: "1.5em", fontWeight: "bolder", gridRowStart: 1 }}>
                    {t("playlists")}
                </div>
                <div style={{ lineHeight: "40px", paddingRight: 5, gridRowStart: isMobile ? 2 : 1 }}>
                    {t("playlists to be displayed")}:</div>
                <div style={{ gridRowStart: isMobile ? 2 : 1 }}>
                    <ImgSelect
                        options={itemsToShow}
                        placeholder={t("all")}
                        value={itemsPerPage ?? null}
                        onChange={(selectedOption) => {
                            if (selectedOption) {
                                setItemsPerPage(selectedOption);
                            }
                            setPageCountUndone(0);
                            setPageCountOptional(0);
                            setPageCountDone(0);
                        }}
                    />

                </div>
                <div style={{ paddingRight: 5, gridRowStart: isMobile ? 2 : 1 }}>
                    {itemsPerPage !== undefined && (
                        <Button
                            icon="times"
                            kind='secondary'
                            onClick={() => {
                                setItemsPerPage(undefined);
                                setPageCountUndone(0);
                                setPageCountOptional(0);
                                setPageCountDone(0);
                            }}
                            tooltip={{ tooltipText: t("reset pagination") }}
                        />

                    )}

                </div>
                <div style={{ gridRowStart: isMobile ? 4 : 1 }}>
                    <T.HeadlineInput>
                        <div style={{ marginRight: admin ? 0 : 0 }}>
                            <div style={{ float: "right" }}>
                                <Input onChange={e => setSearchTerm(e.target.value)}
                                    width={isMobile ? "100%" : 200}
                                    value={searchTerm}
                                    autoComplete='off'
                                    placeholder={t(isMobile ? "search" : "search by keyword...")}
                                    icon='search'
                                    type='search'
                                    kind={searchTerm === "" ? 'default' : hasResults ? 'bottomLine' : 'bottomLineError'}
                                />
                            </div>
                        </div>
                        {searchTerm !== "" &&
                            <T.HeadlineInputClear onClick={() => setSearchTerm("")} style={{ right: 10 }}>
                                <Icon name='times' color='@lightGrey' marginTop={0} />
                            </T.HeadlineInputClear>
                        }
                    </T.HeadlineInput>
                </div>
                <div style={{ paddingLeft: 5, gridRowStart: isMobile ? 4 : 1 }}>
                    {admin &&
                        <Button
                            icon="playlist edit"
                            kind="primary"
                            onClick={gotoNamedRouteMethod("playlist_manage")}
                            tooltip={{ tooltipText: t("manage playlist elements") }}
                            floated='right'
                        />
                    }
                    <Button
                        icon="chevron left"
                        kind="halfTransparentButton"
                        onClick={() => History.goBack()}
                        tooltip={{ tooltipText: t("back") }}
                        floated='right'
                    />
                    <FloatedRight style={{ marginRight: 5 }}>
                        <Button
                            icon="carousel"
                            kind="halfTransparentButton"
                            onClick={() => setShowCarousel(!showCarousel)}
                            tooltip={{ tooltipText: t(showCarousel ? "hide your already processed playlists" : "show your already processed playlists") }}
                            floated='right'
                            iconColor={showCarousel ? undefined : "@accentRed"}
                        />
                    </FloatedRight>

                </div>
            </T.HeadlineTop>
            {loading ? <Loader active infoText={t("loading playlists")} size='small' light /> :
                <>
                    {hasResults ?
                        <>
                            {/* {hasMandatoryEntries && sortedLists.undoneStarted.length > 0 &&
                                <>
                                    <T.Headline style={{ marginTop: 30 }}>
                                        {searchTerm === "" ? t("my already processed playlists") : t("my filtered playlists")}
                                    </T.Headline>
                                    <T.CarouselContainer>
                                        <Carousel
                                            slidesToShow={slidesToShow}
                                            dragging={listLinear.length > slidesToShow}
                                            slideWidth={slideWidth}
                                            cellSpacing={slidesGap}
                                            slidesToScroll={slidesToShow}
                                            renderCenterRightControls={null}
                                            renderCenterLeftControls={null}
                                            renderBottomCenterControls={null}
                                            renderTopRightControls={slidesToShow >= sortedLists.undone.length ? null : ({ previousSlide, nextSlide, currentSlide, slideCount }) => (listLinear.length > slidesToShow &&
                                                <>
                                                    {contentWidth >= 480 &&
                                                        <T.CarouselButtonLeft onClick={previousSlide} disabled={currentSlide === 0}>
                                                            <Icon name="chevron left" size="22px" />
                                                        </T.CarouselButtonLeft>
                                                    }
                                                    {contentWidth >= 480 &&
                                                        <T.CarouselButtonRight onClick={nextSlide} disabled={slideCount === currentSlide + slidesToShow}>
                                                            <Icon name="chevron right" size="22px" />
                                                        </T.CarouselButtonRight>
                                                    }

                                                </>
                                            )}
                                        >
                                            {_.map(undoneStartedSortedByDate, (p, i) => {
                                                const isAAO = p.curNodes.length === 0;
                                                return (
                                                    <PlaylistOverviewCarouselItem data={p} isAAO={isAAO} key={i} />
                                                );
                                            })}
                                        </Carousel>
                                    </T.CarouselContainer>
                                </>
                            } */}


                            <div style={{ height: (hasMandatoryEntries && sortedLists.undoneStarted.length > 0 && showCarousel) ? carHeight : 0, overflow: "hidden", transition: "all 0.2s ease-out", opacity: showCarousel ? 1 : 0 }}>
                                <T.Headline style={{ marginTop: 30 }}>
                                    {searchTerm === "" ? t("my already processed playlists") : t("my filtered playlists")}
                                </T.Headline>
                                <T.CarouselContainer>
                                    <Carousel
                                        slidesToShow={slidesToShow}
                                        dragging={listLinear.length > slidesToShow}
                                        slideWidth={slideWidth}
                                        cellSpacing={slidesGap}
                                        slidesToScroll={slidesToShow}
                                        renderCenterRightControls={null}
                                        renderCenterLeftControls={null}
                                        renderBottomCenterControls={null}
                                        renderTopRightControls={slidesToShow >= sortedLists.undone.length ? null : ({ previousSlide, nextSlide, currentSlide, slideCount }) => (listLinear.length > slidesToShow &&
                                            <>
                                                {contentWidth >= 480 &&
                                                    <T.CarouselButtonLeft onClick={previousSlide} disabled={currentSlide === 0}>
                                                        <Icon name="chevron left" size="22px" />
                                                    </T.CarouselButtonLeft>
                                                }
                                                {contentWidth >= 480 &&
                                                    <T.CarouselButtonRight onClick={nextSlide} disabled={slideCount === currentSlide + slidesToShow}>
                                                        <Icon name="chevron right" size="22px" />
                                                    </T.CarouselButtonRight>
                                                }

                                            </>
                                        )}
                                    >
                                        {_.map(undoneStartedSortedByDate, (p, i) => {
                                            const isAAO = p.curNodes.length === 0;
                                            return (
                                                <PlaylistOverviewCarouselItem data={p} isAAO={isAAO} key={i} />
                                            );
                                        })}
                                    </Carousel>
                                </T.CarouselContainer>
                            </div>

                            <T.Headline>
                                {searchTerm === "" ? t("all playlists") : t('all playlists filtered by "{{search}}"', { search: searchTerm })}
                            </T.Headline>
                            <T.TableGrid showButtons={showBtns} style={{ marginTop: 14 }}>
                                <T.TitleRowCell style={{ cursor: "default" }} />
                                <T.TitleRowCell style={{ padding: 0, cursor: "default" }}  >
                                    <Icon name="image" />
                                </T.TitleRowCell>
                                <T.TitleRowCell onClick={setSort("name")}>
                                    <div>{t("name")}</div>
                                    {sortBy === "name" ?
                                        <div><Icon name={reverseSort ? "arrow down" : "arrow up"} style={{ float: "left", marginLeft: 5, }} size="14px" marginTop={3} /></div>
                                        : <div />}
                                </T.TitleRowCell>
                                {showDescs ? <T.TitleRowCell style={{ cursor: "default" }}>
                                    <div>{t("description")}</div>
                                </T.TitleRowCell> : <div />}
                                {isDesktop ? <T.TitleRowCell onClick={setSort("assigned")}>
                                    <div>{t("assigned")}</div>
                                    {sortBy === "assigned" ?
                                        <div><Icon name={reverseSort ? "arrow down" : "arrow up"} style={{ float: "left", marginLeft: 5, }} size="14px" marginTop={3} /></div>
                                        : <div />}
                                </T.TitleRowCell> : <div />}
                                {isTablet ? <T.TitleRowCell onClick={setSort("duedate")}>
                                    <div>{t("due date")}</div>
                                    {sortBy === "duedate" ?
                                        <div><Icon name={reverseSort ? "arrow down" : "arrow up"} style={{ float: "left", marginLeft: 5, }} size="14px" marginTop={3} /></div>
                                        : <div />}
                                </T.TitleRowCell> : <div />}
                                {isLaptop ? <T.TitleRowCell onClick={setSort("duration")}>
                                    <div>{t("duration")}</div>
                                    {sortBy === "duration" ?
                                        <div><Icon name={reverseSort ? "arrow down" : "arrow up"} style={{ float: "left", marginLeft: 5, }} size="14px" marginTop={3} /></div>
                                        : <div />}
                                </T.TitleRowCell> : <div />}
                                <T.TitleRowCell
                                    onClick={setSort("progress")}
                                >
                                    <div>{t("progress")}</div>
                                    {sortBy === "progress" ?
                                        <div><Icon name={reverseSort ? "arrow down" : "arrow up"} style={{ float: "left", marginLeft: 5, }} size="14px" marginTop={3} /></div>
                                        : <div />}
                                </T.TitleRowCell>
                                {showBtns && <T.TitleRowCell style={{ cursor: "default" }}>actions</T.TitleRowCell>}
                                <T.TitleRowCell style={{ cursor: "default" }} />
                                {hasMandatoryEntries &&
                                    <>
                                        <T.HeadlineRow style={{ gridColumnEnd: showBtns ? 11 : 10 }}>
                                            {t("my mandatory playlists")}
                                        </T.HeadlineRow>
                                        {_.map(visibleUndone, (p) => (
                                            <div className="rowWrapper" key={p.id}>
                                                <PlaylistOverviewItem data={p} showBtns={showBtns} progress={getProgressStatus(p.curNodes[0], p)} />
                                            </div>
                                        ))}
                                        {itemsPerPage && sortedLists.undone.length > itemsPerPage.value &&
                                            <T.PaginationRow showBtns={showBtns}>
                                                <Pagination
                                                    count={Math.ceil(sortedLists.undone.length / itemsPerPage.value)}
                                                    initialIndex={pageCountUndone}
                                                    paginate={paginateUndone}
                                                />
                                            </T.PaginationRow>
                                        }
                                    </>
                                }
                                {hasOptionalEntries &&
                                    <>
                                        <T.HeadlineRow style={{ gridColumnEnd: showBtns ? 11 : 10 }}>
                                            {t("my optional playlists")}
                                        </T.HeadlineRow>

                                        {_.map(visibleOptional, (p) => (
                                            <div className="rowWrapper" key={p.id}>
                                                <PlaylistOverviewItem data={p} showBtns={showBtns} progress="on Track" />
                                            </div>
                                        ))}
                                        {itemsPerPage && sortedLists.optional.length > itemsPerPage.value &&
                                            <T.PaginationRow showBtns={showBtns}>
                                                <Pagination
                                                    count={Math.ceil(sortedLists.optional.length / itemsPerPage.value)}
                                                    initialIndex={pageCountOptional}
                                                    paginate={paginateOptional}
                                                />
                                            </T.PaginationRow>
                                        }
                                    </>
                                }
                                {hasDoneEntries &&
                                    <>
                                        <T.HeadlineRow style={{ gridColumnEnd: showBtns ? 11 : 10 }}>
                                            {t("my completed playlists")}
                                        </T.HeadlineRow>

                                        {_.map(visibleDone, (p) => (
                                            <div className="rowWrapper" key={p.id}>
                                                <PlaylistOverviewItem data={p} showBtns={showBtns} progress="done" />
                                            </div>
                                        ))}
                                        {itemsPerPage && sortedLists.done.length > itemsPerPage.value &&

                                            <T.PaginationRow showBtns={showBtns}>
                                                <Pagination
                                                    count={Math.ceil(sortedLists.done.length / itemsPerPage.value)}
                                                    initialIndex={pageCountDone}
                                                    paginate={paginateDone}
                                                />
                                            </T.PaginationRow>
                                        }
                                    </>
                                }
                            </T.TableGrid>

                        </>
                        : <div style={{ marginTop: 40 }}>
                            <InfoBox
                                content={t("no playlists with the term {{searchword}} in the title were found", { searchword: searchTerm })}
                                icon='exclamation circle'
                                noBold
                                noLetterSpacing
                                noUppercase
                                onClick={() => setSearchTerm("")}
                                type='alert'
                                closeable
                            />
                        </div>
                    }
                </>
            }
            {admin &&
                <T.AdminPanel >
                    <div style={{ float: "left", lineHeight: "40px", paddingLeft: 20 }}>Temporary Admin Panel</div>
                    <div style={{ float: "right", borderLeft: "1px solid #e9e9e9", width: 1, height: "100%", marginRight: 10 }} />
                    <div style={{ float: "right", lineHeight: "20px", marginRight: 10, marginTop: 9, cursor: "pointer" }} onClick={() => setShowBtns(!showBtns)}>
                        Reset  Buttons
                    </div>
                    <div style={{ float: "right", lineHeight: "20px", marginRight: 10, marginTop: 7 }}>
                        <Checkbox label='hide' onClick={() => !showBtns ? undefined : setShowBtns(false)} selected={!showBtns} />
                    </div>
                    <div style={{ float: "right", lineHeight: "20px", marginRight: 10, marginTop: 7 }}>
                        <Checkbox label='show' onClick={() => showBtns ? undefined : setShowBtns(true)} selected={showBtns} />
                    </div>
                    <div style={{ float: "right", borderLeft: "1px solid #e9e9e9", width: 1, height: "100%", marginRight: 10 }} />
                </T.AdminPanel>
            }

        </div>
    );
}
export default PlaylistOverview;
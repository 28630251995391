import { BestPracticePostDownloadDto, PostDownloadDto } from "collaboration-service";
import ContentPost from "components/Posts/ContentPost/ContentPost";
import FeedPost from "components/Posts/PorscheMomentPost/FeedPost";
import PorscheMomentPostMobile from "components/Posts/PorscheMomentPost/PorscheMomentPostMobile";
import PorscheMomentPostMobileLoader from "components/Posts/PorscheMomentPost/PorscheMomentPostMobileLoader";
import { FeedMobileLoaderContainer, FeedMobileLoaderContainerLottie, FeedMobileLoaderContainerText, PostContainerDiv, PostContainerDivOuter, PostContainerDivOuterBg } from "components/Posts/PostClasses";
import QuizPost from "components/Posts/QuizPost/QuizPost";
import SlideshowPost from "components/Posts/SlideshowPost/SlideshowPost";
import StandardPost from "components/Posts/StandardPost";
import TubePost from "components/Posts/TubePost/TubePost";
import { LottieIcon, useBelowBreakpoint } from "imaginarity-react-ui";
import * as _ from "lodash";
import * as React from "react";
import { Actions, WorkingActions } from "services/ApplicationState/Actions";
import { ApplicationState, useAppDispatch, useAppSelector } from "services/ApplicationState/ApplicationState";
import { shallowCompare } from "services/Helpers";
import { gotoNamedRoute, useCheckWhereIAm } from "services/Helpers/RoutingHelper";
import ScrollHelper from "services/Helpers/ScrollHelper";
import { useImgI18N } from "services/ImgI18N";
import useOnScreenDiv from "services/useOnScreenDiv";
import BestPracticePost from "views/BestPractice/BestPracticePost";
import NewsPost from "views/News/NewsPost";

const mapper = (s: ApplicationState) => ({
    routeParams: s.params,
    postsToken: s.postsToken,
    posts: s.posts,
    feedSearchProps: s.feedState.searchProps,
    trending: s.feedState.trending,
    prev: s.feedState.prev,
    next: s.feedState.next,
});

interface FeedProps {
    trending?: boolean;
}

export const RenderPost = (p: {
    post: PostDownloadDto;
    postChanged: (post: PostDownloadDto) => void;
    isMobile?: boolean;
    postStyle?: React.CSSProperties;
    padded?: boolean;
    next?: () => void;
    prev?: () => void;
    canNext?: boolean;
    canPrev?: boolean;
}) => {
    const { post, postChanged, postStyle, padded, isMobile, next, prev, canNext, canPrev } = p;
    const renderedPost = React.useMemo(() => {
        const type = post?.type;
        switch (type) {
            case "ContentPost":
                return <ContentPost post={post} postChanged={postChanged} />;
            case "TubePost":
                return <TubePost post={post} postChanged={postChanged} />;
            case "CommunityFeedPost":
                return <PorscheMomentPostMobile post={post} postChanged={postChanged} paddedBottom={padded ?? false} />;
            case "PorscheMomentPost":
                if (isMobile)
                    return <FeedPost post={post} postChanged={postChanged} canNext={canNext ?? false} canPrev={canPrev ?? false} next={next} prev={prev} />;
                return <PorscheMomentPostMobile post={post} postChanged={postChanged} paddedBottom={padded ?? false} />;
            case "SlideShow":
                return <SlideshowPost post={post} postChanged={postChanged} />;
            case "Quiz":
                return <QuizPost post={post} postChanged={postChanged} />;
            case "NewsPost":
                return <NewsPost post={post} postChanged={postChanged} />;
            case "BestPracticePost":
                return <BestPracticePost post={post as BestPracticePostDownloadDto} />;
            default:
                return <StandardPost post={post} postChanged={postChanged} />;
        }
    }, [post, postChanged, padded, isMobile, canNext, canPrev, next, prev]);
    const fs = padded ?? false;
    return (
        <PostContainerDivOuter key={post.id} className="PostContainerDivOuter" style={postStyle}>
            <PostContainerDivOuterBg fs={fs} />
            <PostContainerDiv>
                {renderedPost}
            </PostContainerDiv>
        </PostContainerDivOuter>
    );
}


const Feed = (p: FeedProps) => {
    const { postsToken, posts, feedSearchProps, next, prev } = useAppSelector(mapper, shallowCompare);
    const [scrollHelper,] = React.useState<ScrollHelper>(new ScrollHelper({ behavior: 'instant' }));
    const [loadingNext, setLoadingNext] = React.useState<boolean>(false);
    const [loadingPrev, setLoadingPrev] = React.useState<boolean>(false);
    const [loadingLatest, setLoadingLatest] = React.useState<boolean>(false);
    const { t } = useImgI18N("feed");
    const dispatch = useAppDispatch();
    const [, setForce] = React.useState<boolean>(false);
    // const browser = useThemePart(x => x.browser);
    // const isMobile = useBelowBreakpointOrEqual("mobile");
    const isMobile = useBelowBreakpoint("tablet");
    // const isIOS = MobileHelper.getInstance().iOSClient || (browser === "ios" || browser === "crios" || browser === "fxios") || isMobile;
    // const isMobile = useBelowBreakpointOrEqual("tablet");
    // const isIOS = true;
    // const loadingCount = (isIOS && isMobile) ? 1 : 12;
    const loadingCount = (isMobile) ? 1 : 12;
    const split = (postsToken ?? "")?.split('_');
    const canPrev = split[1] === "1";
    const canNext = split[2] === "1";
    const trending = useCheckWhereIAm()("community_feed_trending");
    React.useEffect(() => {
        dispatch(Actions.setFeedTrending(trending));
    }, [dispatch, trending])

    const reload = React.useMemo(() => (force?: boolean) => {
        setLoadingNext(false);
        setLoadingLatest(true);
        // dispatch(WorkingActions.FEED.initialLoadPosts(force, loadingCount, isIOS ? (t) => {
        dispatch(WorkingActions.FEED.initialLoadPosts(force, loadingCount, isMobile ? (t) => {
            gotoNamedRoute(trending ? "community_feed_trending" : "community_feed", { tokenId: t ?? "" });
            setLoadingLatest(false);
        } : undefined));
    }, [dispatch, loadingCount, isMobile, trending]);

    const loadNext = React.useMemo(() => () => {
        setLoadingPrev(false);
        setLoadingLatest(false);
        setLoadingNext(true);
        dispatch(WorkingActions.FEED.loadPosts(loadingCount, isMobile ? (t) => {
            gotoNamedRoute(trending ? "community_feed_trending" : "community_feed", { tokenId: t ?? "" });
            setLoadingNext(false);
        } : undefined));
    }, [dispatch, loadingCount, isMobile, trending]);

    const loadPrev = React.useMemo(() => () => {
        setLoadingNext(false);
        setLoadingLatest(false);
        setLoadingPrev(true);
        dispatch(WorkingActions.FEED.loadPosts(loadingCount, isMobile ? (t) => {
            gotoNamedRoute(trending ? "community_feed_trending" : "community_feed", { tokenId: t ?? "" });
            setLoadingPrev(false);
        } : undefined, "Prev"));
    }, [dispatch, loadingCount, isMobile, trending]);

    React.useEffect(() => {
        if (feedSearchProps !== undefined)
            setForce(f => {
                reload(f);
                return true;
            });
    }, [dispatch, reload, feedSearchProps])

    React.useEffect(() => {
        dispatch(Actions.setFeedPrevAndNext(canPrev ? loadPrev : undefined, canNext ? loadNext : undefined));
    }, [canNext, canPrev, loadPrev, dispatch, loadNext])

    const updatePost = React.useMemo(() => (post: PostDownloadDto) => {
        dispatch(Actions.updatePost(post));
    }, [dispatch]);

    const useSnapScrolling = false;

    const divStyle: React.CSSProperties = React.useMemo(() => useSnapScrolling ? {
        width: "100%",
        height: isMobile ? "calc(100% + 31px)" : "100%",
        overflowY: "auto",
        overflowX: "hidden",
        scrollBehavior: "smooth",
        scrollSnapType: isMobile ? "y proximity" : "none",
        marginTop: isMobile ? "-20px" : 0,
        position: "relative",
    } : {
        position: "relative",

    }, [isMobile, useSnapScrolling]);


    const postStyle: React.CSSProperties = useSnapScrolling ? {
        scrollSnapAlign: isMobile ? "start" : "none",
        height: "auto"
    } : {};

    const OnScreenDiv = useOnScreenDiv({ work: loadNext });

    const scrollTo = React.useMemo(() => (id: string) => {
        scrollHelper.scrollTo(id);
    }, [scrollHelper]);

    React.useEffect(() => {
        if (isMobile) return;
        dispatch(Actions.setScrollTo(scrollTo));
        return () => {
            dispatch(Actions.setScrollTo(undefined));
        };
    }, [scrollTo, dispatch, isMobile]);


    return (
        <div style={divStyle}>
            {!isMobile && <div ref={scrollHelper.getRef("top")} />}
            {posts?.length === 0 ?
                <PostContainerDivOuter style={{ ...postStyle, height: "100%" }}>
                    <PostContainerDivOuterBg fs={isMobile} />
                    <PostContainerDiv>
                        <PorscheMomentPostMobileLoader infoText={t("no posts found")} icon='exclamation circle' />
                    </PostContainerDiv>
                </PostContainerDivOuter>
                :
                _.map(posts, (p, i) => {
                    return <RenderPost
                        key={p.id}
                        post={p}
                        postChanged={updatePost}
                        postStyle={postStyle}
                        padded={isMobile}
                        isMobile={isMobile}
                        next={next}
                        prev={prev}
                        canNext={canNext}
                        canPrev={canPrev}
                    />
                })}

            {canNext && !isMobile &&
                <PostContainerDivOuter className="PostContainerDivOuter" style={{ ...postStyle }} key={`loader-${posts?.length}`}>
                    <PostContainerDivOuterBg fs={isMobile} />
                    <PostContainerDiv>
                        {OnScreenDiv}
                        <PorscheMomentPostMobileLoader rotate />
                    </PostContainerDiv>
                </PostContainerDivOuter>
            }


            {(loadingPrev || loadingNext || loadingLatest) && isMobile &&
                <FeedMobileLoaderContainer>
                    <FeedMobileLoaderContainerLottie className="lottie">
                        <LottieIcon url={"images/lottiIcons/spinner.json"}
                            speed={2}
                            autoplay
                            loop
                            height={80}
                            width={80}
                        />
                    </FeedMobileLoaderContainerLottie>
                    <FeedMobileLoaderContainerText >{t("loading {{direction}} moment", { direction: loadingLatest ? "latest" : (loadingNext ? "next" : "previous") })}</FeedMobileLoaderContainerText>
                </FeedMobileLoaderContainer>
            }
        </div >
    )
}

export default Feed;

import { CMSController, ControllerHelper, FileEntryDownloadDto, PostDownloadDto, ProjectGroupInfoDownloadDto } from 'collaboration-service';
import InfoBox from 'components/InfoBox/InfoBox';
import SafeHTML from 'components/SafeHTML/SafeHTML';
import { Button, Image } from 'imaginarity-react-ui';
import _ from 'lodash';
import * as React from 'react';
import { ApplicationState, useAppSelector } from 'services/ApplicationState/ApplicationState';
import { shallowCompare } from 'services/Helpers';
import { getMediaLink } from 'services/Helpers/MediaHelpers';
import { sanitizedNothingAllowed } from 'services/Helpers/SanitizedHelper';
import { getCMSContent, getTranslated, getTranslatedUserName } from 'services/Helpers/TranslationHelpers';
import { useImgI18N } from 'services/ImgI18N';
import { isInGroup } from 'services/StoreDependantHelpers';
import { usePreviewImageForFileEntry } from 'views/CMS/Helpers/CMSHelpers';
import { shortenUUID } from './PlaylistEditContentImplementedElement';
import { documentKindByType } from '../PlaylistItems/PlaylistNodeItem';

interface PlaylistEditPreviewElementProps {
    post: PostDownloadDto;
}

const mapper = (s: ApplicationState) => ({
    contentLanguage: s.contentLanguage,
})

const PlaylistEditPreviewElement = (p: PlaylistEditPreviewElementProps) => {
    const { contentLanguage } = useAppSelector(mapper, shallowCompare);
    const { post } = p;
    const { t, currentLanguage } = useImgI18N("playlist");
    const [pInfo, setPInfo] = React.useState<ProjectGroupInfoDownloadDto>();
    const language = contentLanguage ?? currentLanguage;
    const contentFE = post?.type === "FileEntry" ? getCMSContent({ fileEntry: post as FileEntryDownloadDto, filePosts: {}, language }) : undefined;
    const eleType = post?.type === "FileEntry" ? documentKindByType(contentFE?.media?.mediaType) : undefined;
    const eleTypeIsAudio = (eleType === "audio" || post.type === "audio");
    const image = getMediaLink(post, p => p.media)?.toString() ?? "";
    const title = getTranslated(post.headlines, contentLanguage)?.text ?? contentFE?.media?.fileName;

    const description = getTranslated(post.descriptions, contentLanguage)?.text ?? "";
    const preview = usePreviewImageForFileEntry(post, language);
    const gId = shortenUUID(post.group_id);

    React.useEffect(() => {
        if (post.type === "FileEntry" && isInGroup("CMS")) {
            const l = async () => {
                const info = await ControllerHelper.singleCall({ id: gId }, CMSController.GetProjectGroupInfo,);
                setPInfo(info);
            }
            l();
        }
    }, [gId, post.type]);

    return (
        <>
            {preview === undefined && (image !== "" || title !== "" || description !== "") &&
                <Button tooltip={{
                    tooltipText: "",
                    tooltipContent: <div style={{ textAlign: "left" }}>
                        {post.media && image !== "" && <Image src={image} shadowed />}
                        {title &&
                            <h4><SafeHTML
                                allowedTags={sanitizedNothingAllowed}
                                html={title}
                            /></h4>
                        }
                        {description &&
                            <SafeHTML
                                allowedTags={sanitizedNothingAllowed}
                                html={description}
                            />
                        }
                    </div>
                }}
                    kind="fullTransparentButton"
                    icon={"info"}
                    floated="right"
                />
            }
            {preview !== undefined && (image !== "" || title !== "" || description !== "" || preview !== "") &&
                <Button tooltip={{
                    tooltipText: "",
                    tooltipContent: <div style={{ textAlign: "left" }}>

                        {preview &&
                            <>
                                {eleTypeIsAudio ?

                                    <audio
                                        controlsList="nodownload"
                                        controls={false}
                                        preload="metadata"
                                        autoPlay={true}
                                        crossOrigin="anonymous"
                                        style={{ width: "100%", outline: "none" }}
                                    >
                                        <source src={preview} type="audio/mpeg" />
                                        Your browser does not support the audio element.
                                    </audio>
                                    :
                                    <Image src={preview} shadowed />
                                }
                            </>
                        }

                        {eleTypeIsAudio &&
                            <InfoBox animated content={t('stay hovered for audio preview')} icon='music' noUppercase noBold type='info' />
                        }

                        {title &&
                            <div style={{ fontWeight: "bolder", marginTop: 10 }}>
                                <SafeHTML
                                    allowedTags={sanitizedNothingAllowed}
                                    html={title}
                                />
                            </div>
                        }
                        {description &&
                            <div style={{ fontWeight: "normal", marginTop: 10 }}>
                                <SafeHTML
                                    allowedTags={sanitizedNothingAllowed}
                                    html={description}
                                />
                            </div>
                        }
                        {pInfo &&
                            <div style={{ display: "grid", gridTemplateColumns: "max-content 1fr", columnGap: 10, marginTop: 10, borderTop: "1px solid #cfcfcf", paddingTop: 5 }}>
                                <div style={{ fontWeight: "bolder" }}>{t("project")}:</div>
                                <div>{getTranslated(pInfo.headlines, contentLanguage).text}</div>
                                <div style={{ fontWeight: "bolder" }}>{t("project-id")}:</div>
                                <div>{pInfo.projectId}</div>
                                <div style={{ fontWeight: "bolder" }}>{("Responsibles")}:</div>
                                <div>
                                    {_.map(pInfo.responsibleUsers, (r, i) => {
                                        return (
                                            <div key={i}>{getTranslatedUserName(r)}</div>
                                        );
                                    })}
                                </div>
                            </div>
                        }
                    </div>
                }}
                    kind="fullTransparentButton"
                    icon="info"
                    floated="right"
                />
            }
        </>
    );
}
export default PlaylistEditPreviewElement;
import { BestPracticePostDownloadDto, ContentPostDownloadDto, ControllerHelper, FileEntryDownloadDto, LanguageStringDownloadDto, MediaDownloadDto, PodcastPostDownloadDto, PorscheMomentPostDownloadDto, PostDownloadDto, QuizDownloadDto, TubePostDownloadDto, URLController, WikiArticleDownloadDto } from "collaboration-service";
import SafeHTML from "components/SafeHTML/SafeHTML";
import { getLinkByName, Icon, ImgIcons, LottieIcon, Tooltip, useThemePart } from "imaginarity-react-ui";
import * as _ from "lodash";
import * as React from "react";
import { ApplicationState, useAppSelector } from "services/ApplicationState/ApplicationState";
import { getLink, shallowCompare } from "services/Helpers";
import { createNamedRoute } from "services/Helpers/RoutingHelper";
import { sanitizedNothingAllowedAttributes } from "services/Helpers/SanitizedHelper";
import { getCMSContent, getTranslated, getTranslatedForTube } from "services/Helpers/TranslationHelpers";
import { useImgI18N } from "services/ImgI18N";
import { isInGroup } from "services/StoreDependantHelpers";
import { PlaylistDetailNodeItemProps, useHidePlaylistNodeItem } from "../PlaylistDetailNodeItem";
import { PlaylistDetailSC as T } from "../PlaylistDetailSC";
import PlaylistDimmer from "./Dimmer/PlaylistDimmer";
import { getParentIdFromDataBaseId } from "./FileEntryPlaylistItem";
import { documentKinds } from "./usePlaylistButtonText";
import { FloatedRight } from "layout/masterLayouts/MasterLayoutsSC";
import { podcastTheme } from "views/Podcast/PodcastMain";

const mapper = (s: ApplicationState) => ({
    contentLanguage: s.contentLanguage,
    params: s.params,
});

export const documentKindMap: { [mediaType: string]: documentKinds } = {
    "audio/mpeg": "audio",
    "image/tiff": "download",
    "video/mp4": "video",
    "video/quicktime": "video",
    "application/pdf": "pdf",
    "application/zip": "download",
    "application/x-zip-compressed": "download"
}

export const documentKindByType = (type?: string): documentKinds => {
    if (!type)
        return "document";
    var toRet = documentKindMap[type];
    if (toRet)
        return toRet;
    if (type.startsWith("image"))
        return "image";
    if (type.startsWith("application"))
        return "document";
    if (type === "url-file")
        return "url-file";
    return "document";
}

export type PlaylistNodeItemDataType = {
    image: string,
    link?: string,
    icon: ImgIcons,
    title: string,
    type?: string,
};

const PlaylistNodeItem = <T extends PostDownloadDto>(p: PlaylistDetailNodeItemProps<T>) => {
    const { item, state, playlistId, playlistContent, playlistStateId, isOptional, nodeReference, transition, warning, isMobile, nodeIsDone, loadPlaylistStates } = p;
    const { contentLanguage, params } = useAppSelector(mapper, shallowCompare);
    const { t, currentLanguage } = useImgI18N("playlist");
    const emptyImageUrl = useThemePart(t => t.emptyImageUrl);
    const [showModal, setShowModal] = React.useState<boolean>(false);
    const language = contentLanguage ?? currentLanguage;
    const plId = params ? params.id : playlistId;
    const elementIsReference = item.id === nodeReference;
    const [loading, setLoading] = React.useState<boolean>(false);
    const togglePreview = React.useMemo(() => () => {
        setShowModal((prev) => !prev);
    }, []);
    const result = useHidePlaylistNodeItem(p);

    const [itemData, setItemData] = React.useState<PlaylistNodeItemDataType>({
        image: emptyImageUrl,
        icon: "empty",
        link: undefined,
        title: "",
        type: undefined,
    });

    React.useEffect(() => {
        const l = async () => {
            const createTypeGuard = <T extends PostDownloadDto>(type: PostDownloadDto["type"]) => {
                return (item: PostDownloadDto): item is T => item.type === type;
            };

            const isWikiArticle = createTypeGuard<WikiArticleDownloadDto>("WikiArticle");
            const isTubePost = createTypeGuard<TubePostDownloadDto>("TubePost");
            const isPodcastPost = createTypeGuard<PodcastPostDownloadDto>("PodcastPost");
            const isQuizPost = createTypeGuard<QuizDownloadDto>("Quiz");
            const isContentPost = createTypeGuard<ContentPostDownloadDto>("ContentPost");
            const isBestPracticePost = createTypeGuard<BestPracticePostDownloadDto>("BestPracticePost");
            const isPorscheMomentPost = createTypeGuard<PorscheMomentPostDownloadDto>("PorscheMomentPost");
            const isFileEntry = createTypeGuard<FileEntryDownloadDto>("FileEntry");

            const toRet: PlaylistNodeItemDataType = {
                image: emptyImageUrl,
                icon: "empty",
                link: undefined,
                title: "",
                type: undefined,
            };
            let content: { text?: LanguageStringDownloadDto; media?: MediaDownloadDto; post?: PostDownloadDto } = {};

            toRet.title = getTranslated(item.headlines, language)?.text ?? content?.media?.fileName;

            if (isWikiArticle(item)) {
                toRet.image = getLink(item.media, "self")?.uri ?? emptyImageUrl;
                toRet.link = createNamedRoute("wiki_article", { id: item.id }, true);
                toRet.icon = "wiki";
            } else if (isTubePost(item)) {
                const ct = getTranslatedForTube(item.content, language, undefined);
                toRet.image = ((ct.thumbnails ?? [])[0] ? getLink(ct.thumbnails![0], "self")?.uri : undefined) ?? emptyImageUrl;
                toRet.link = createNamedRoute("playlist_tube_video_details", { id: item.id, context: playlistStateId ?? "" }, true);
                toRet.icon = "video player";
            } else if (isPodcastPost(item)) {
                toRet.image = getLink(item.media, "self")?.uri ?? emptyImageUrl;
                toRet.link = createNamedRoute("playlist_podcast_audio_details", { id: item.id, context: playlistStateId ?? "" }, true);
                toRet.icon = "mic on";
            } else if (isQuizPost(item)) {
                const itemState = state.state;
                toRet.image = getLink(item.media, "self")?.uri ?? emptyImageUrl;
                toRet.link = playlistStateId ? createNamedRoute("quiz_singleplayer", { quizId: item.id, mode: itemState === "Started" ? "continue" : "start", context: playlistStateId }, true) : createNamedRoute("quiz_details", { quizId: item.id }, true);
                toRet.icon = "game";
            } else if (isContentPost(item)) {
                toRet.image = getLink(item.media, "self")?.uri ?? emptyImageUrl;
                toRet.link = createNamedRoute("playlist_content_art", { id: item.id, context: playlistStateId ?? "" }, true);
                toRet.icon = "feed";
            } else if (isBestPracticePost(item)) {
                toRet.image = getLink(item.medias[0], "self")?.uri ?? emptyImageUrl;
                toRet.link = createNamedRoute("best_detail", { id: item.id }, true);
                toRet.icon = "bulb";
            } else if (isPorscheMomentPost(item)) {
                toRet.image = getLink(item.media, "self")?.uri ?? emptyImageUrl;
                toRet.link = createNamedRoute("community_view_post", { id: item.id }, true);
                toRet.icon = "feed";
            } else if (isFileEntry(item)) {
                setLoading(true);

                content = getCMSContent({ fileEntry: item, filePosts: {}, language });
                const linkFrom = (content?.media?.thumbnails ?? [])[0] ?? content?.media;
                toRet.type = documentKindByType(content?.media?.mediaType);
                toRet.image = getLinkByName(linkFrom, "self")?.uri ?? emptyImageUrl;
                toRet.title = getTranslated(item.headlines, language)?.text ?? content?.media?.fileName;
                switch (toRet.type) {
                    case "url-file":
                        {
                            content = getCMSContent({ fileEntry: item, filePosts: {}, language });
                            const urlData = await ControllerHelper.singleCall({ data: { url: _.find(content?.media?.links, l => l.ref === "external-link")?.uri ?? "" } as any }, URLController.GetUrlInfo);
                            toRet.title = getTranslated(item.headlines, language)?.text ?? urlData?.title ?? "";
                            toRet.image = urlData?.image ?? emptyImageUrl;
                            toRet.icon = "link external";
                        }
                        break;
                    case "document":
                        toRet.icon = "file";
                        break;
                    case "image":
                        toRet.icon = "image";
                        break;
                    case "video":
                        toRet.icon = "video player";
                        break;
                    case "audio":
                        toRet.icon = "audio";
                        toRet.image = podcastTheme.noPreviewPodcastImage;
                        break;
                    default:
                        toRet.icon = "file";
                }
            }
            setLoading(false);
            setItemData(toRet);
        }
        l();
    }, [emptyImageUrl, item, language, playlistStateId, state.state]);

    if (result.hide || !itemData) return null;

    const itemWithType = { ...item, type: item.type ?? "FileEntry" };
    const isDone = result.text === "100%";

    return (
        <T.PLItemContainer isMobile={isMobile} isPreview={p.preview ?? false} >
            <T.PLItemImage imageUrl={loading ? "" : itemData.image} isMobile={isMobile} highlighted={elementIsReference} color={warning ? "@warning" : "@accentRed"} onClick={togglePreview}>
                {loading &&
                    <T.PLItemImageLottie>
                        <LottieIcon
                            url={"images/lottiIcons/spinner.json"}
                            speed={1}
                            autoplay
                            loop
                            height={50}
                            width={50}
                        />
                    </T.PLItemImageLottie>
                }
            </T.PLItemImage>

            <T.PLItemContainerContent isMobile={isMobile} onClick={togglePreview}>
                <T.PLItemContainerTitle isMobile={isMobile}>
                    {elementIsReference && <T.PLItemContainerTitleDot color={warning ? "@warning" : "@accentRed"} />}
                    <SafeHTML html={itemData.title} allowedTags={[]} allowedAttributes={{}} />
                </T.PLItemContainerTitle>
                {!isMobile && item.descriptions && (
                    <T.PLItemContainerDescription>
                        <SafeHTML
                            html={getTranslated(item.descriptions, language)?.text ?? ""}
                            allowedTags={["strong", "span", "b", "i", "u"]}
                            allowedAttributes={sanitizedNothingAllowedAttributes}
                        />
                    </T.PLItemContainerDescription>
                )}
                <T.PLItemContainerBottomCat isMobile={isMobile}>
                    {(item.type === "FileEntry" && isInGroup("CMS") && !loading) ?
                        <T.PLItemContainerBottomCatIconLink href={createNamedRoute("cms_file", { projectId: getParentIdFromDataBaseId(item.group_id), id: getParentIdFromDataBaseId(item.id), path: "" }, true)} >
                            <Tooltip tooltipText={t("open in cms")} noMargin notInline position="top">
                                <Icon name={itemData.icon} style={{ float: "left", marginTop: -3, marginLeft: 2 }} size="15px" color="@accentRed" />
                            </Tooltip>
                        </T.PLItemContainerBottomCatIconLink>
                        :
                        <Icon name={itemData.icon} marginTop={3} style={{ float: "left", marginLeft: -2, marginRight: 10 }} size="16px" />
                    }

                    {item.type === "FileEntry" ? itemData.type : item.type}
                    {playlistContent?.duration && (
                        <>
                            <span style={{ padding: "0 10px" }}>&bull;</span>
                            {`${Math.floor(playlistContent.duration * 60 * 100) / 100} Min`}
                        </>
                    )}
                </T.PLItemContainerBottomCat>
            </T.PLItemContainerContent>

            {p.preview !== true && (
                <T.PLItemContainerRight isMobile={isMobile} onClick={togglePreview}>
                    {isOptional ? (
                        <T.ColoredDiv color={state.state === "Finished" ? "@accentGreen" : "@lightGrey"}>
                            <FloatedRight>{state.state === "Finished" ? t("done") : t("optional")}</FloatedRight>
                            <Icon name={state.state === "Finished" ? "check circle" : "empty"} style={{ float: "right", marginRight: 5 }} marginTop={4} color={state.state === "Finished" ? "@accentGreen" : "inherit"} />
                        </T.ColoredDiv>
                    ) : (
                        (transition === "ReferenceDone" || transition === "ReferenceDoneWithValue") ? (
                            elementIsReference ? (
                                <T.ColoredDiv color={(isDone || nodeIsDone) ? "@accentGreen" : warning ? "@warning" : result.color}>
                                    <FloatedRight>
                                        {(isDone || nodeIsDone) ? t("done") : warning ? t("warning") : result.text}
                                    </FloatedRight>
                                    <Icon name={(isDone || nodeIsDone) ? "check circle" : warning ? "exclamation triangle" : result.icon as ImgIcons} style={{ float: "right", marginRight: 5 }} marginTop={4} color={(isDone || nodeIsDone) ? "@accentGreen" : warning ? "@warning" : result.color} />
                                </T.ColoredDiv>
                            ) : (
                                <T.ColoredDiv color={isDone ? "@accentGreen" : "@middleLightGrey"}>
                                    <FloatedRight>{isDone ? t("done") : t("optional")}</FloatedRight>
                                    <Icon name="empty" style={{ float: "right", marginRight: 5 }} marginTop={4} />
                                </T.ColoredDiv>
                            )
                        ) : (
                            <T.ColoredDiv color={isDone ? "@accentGreen" : warning ? "@warning" : result.color}>
                                <FloatedRight>
                                    {isDone ? t("done") : t(result.text)}
                                </FloatedRight>
                                <Icon
                                    name={result.icon as ImgIcons}
                                    style={{ float: "right", marginRight: 5 }}
                                    marginTop={4}
                                    color={isDone ? "@accentGreen" : warning ? "@warning" : result.color}
                                />
                            </T.ColoredDiv>
                        )
                    )}
                </T.PLItemContainerRight>
            )}

            {showModal && (
                <PlaylistDimmer
                    id={p.state.referenceId}
                    item={itemWithType}
                    itemData={itemData}
                    result={result}
                    playlistId={plId as string ?? ""}
                    isOptional={isOptional ?? false}
                    transition={transition}
                    elementIsReference={elementIsReference}
                    nodeIsDone={nodeIsDone ?? false}
                    warning={warning ?? false}
                    state={state}
                    closeDimmer={togglePreview}
                    loadPlaylistStates={loadPlaylistStates}
                    playlistStateId={playlistStateId}
                />
            )}
        </T.PLItemContainer>
    );
};

export default PlaylistNodeItem;

import { fadeColor, getColor, styled } from 'imaginarity-react-ui';

export class PlaylistOverviewCarouselItemSC {
    public static CarouselSlideContainerOuter = styled.div<{ img: string }>`
    position: relative;
    width: 100%;
    aspect-ratio: 16 / 9;
    background: #fff;
    background-image: url(${p => p.img});
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    transition: all 0.2s ease-out;
    overflow: hidden;
    &::before{
        content:"";
        position: absolute; 
        top:0;
        right:0;
        bottom:0;
        left:0;
        background:inherit;
        transition: inherit;
    }
    &:hover::before { 
        transform: scale(1.1);
      }

      opacity: 1;
`;
    public static CarouselSlideContainer = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: transparent;
    background: linear-gradient(to top, rgba(31, 31, 31, 0.9) 0%, rgba(31, 31, 31, 0.9) 20%, rgba(31, 31, 31, 0.85) 26.67%, rgba(32, 32, 32, 0.77) 33.33%, rgba(33, 33, 33, 0.667) 40%, rgba(34, 34, 34, 0.557) 46.67%, rgba(35, 35, 35, 0.443) 53.33%, rgba(36, 36, 36, 0.333) 60%, rgba(37, 37, 37, 0.23) 66.67%, rgba(38, 38, 38, 0.15) 73.33%, rgba(39, 39, 39, 0.082) 80%, rgba(39, 39, 39, 0.035) 86.67%, rgba(39, 39, 39, 0.008) 93.33%, rgba(39, 39, 39, 0) 100%);
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr max-content max-content;
    align-items: end;
    text-shadow: 1px 1px 2px #000;
`;
    public static CarouselSlideContainerTitle = styled.div`
        padding: 0 20px;
        color: ${p => getColor(p, "@mainBackground")};
        opacity: 1;
        font-size: 16px;
        font-weight: 600;
        font-style: normal;
        letter-spacing: 0px;
        text-align: left;
        line-height: 22px;
    `;
    public static CarouselSlideContainerInfo = styled.div`
        padding: 0 20px 7px 20px;
        color: ${p => getColor(p, "@mainBackground")};
        opacity: 1;
        font-size: 12px;
        font-weight: 400;
        font-style: normal;
        letter-spacing: 0px;
        text-align: left;
`;
    public static CarouselSlideContainerStatusBarContainer = styled.div<{ show: boolean }>`
        position: relative;
        height: 1px;
        width: calc(100% - 40px);
        margin-left: 20px;
        background: ${p => p.show ? fadeColor(p, "@mainBackground", 20) : "transparent"};
        margin-bottom: 16px;
    `;
    public static CarouselSlideContainerStatusBar = styled.div<{ percentage: number, color: string }>`
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        width: ${p => p.percentage}%;
        background: ${p => getColor(p, p.color)};
    `;
    public static ToolTipDiv = styled.div`
    margin-top: 10px;
`;
    public static ToolTipFooter = styled.div`
    display: grid;
    grid-template-columns: 1fr max-content;
    column-gap: 20px;
    width: calc(100% + 28px);
    padding: 10px 14px;
    margin-left: -14px;
    margin-bottom: -14px;
    font-size: 0.9em;
    border-top: 1px solid ${p => getColor(p, "@veryLightGrey")};
`;
    public static Mandatory = styled.div`
    position: absolute;
    right: 10px;
    top: 10px;
    background: #fff;
    padding: 0 8px;
    font-size: 0.8em;
    color: #000;
    height: 20px;
    line-height: 20px;
    border-radius: 2px;
`;
}
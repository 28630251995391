import { ControllerHelper, EventController, EventUploadDto, FileEntryDownloadDto } from "collaboration-service";
import SafeHTML from "components/SafeHTML/SafeHTML";
import { getLinkByName, Icon, ImgIcons, styled, useThemePart } from "imaginarity-react-ui";
import * as React from "react";
import { ApplicationState, useAppSelector } from "services/ApplicationState/ApplicationState";
import { shallowCompare } from "services/Helpers";
import { nop } from "services/Helpers/EventHelper";
import { createNamedRoute } from "services/Helpers/RoutingHelper";
import { sanitizedAllowedTagsMinimumSafety, sanitizedNothingAllowedAttributes } from "services/Helpers/SanitizedHelper";
import { getCMSContent, getTranslated } from "services/Helpers/TranslationHelpers";
import { useImgI18N } from "services/ImgI18N";
import { isInGroup } from "services/StoreDependantHelpers";
import { PlaylistSC as T } from "../PlaylistSC";
import { PlaylistItemProperties } from "../PlaylstItem";
import usePlaylistButtonText, { documentKinds } from "./usePlaylistButtonText";
import { documentKindByType } from "./PlaylistNodeItem";

const mapper = (s: ApplicationState) => ({
    currentGroup: s.currentGroup,
    contentLanguage: s.contentLanguage,
});




export const FileEntryPlaylistItemMainContainer = styled.div`
        width:100%;
        text-align: left;
        margin: 0;
        user-select: none;
        padding-bottom: 80px;
`;
export const FileEntryPlaylistItemGrid = styled.div<{ mobileView: boolean, contentWidth: number }>`
    width: 100%;
    max-width: 900px;
    transition: 0.2s all ease-out;
    margin: auto;
`;
export const FileEntryPlaylistItemGridLeftHeaderBar = styled.div`
        line-height: 40px;
        font-size: 1rem;
        font-weight: 500;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;  
        width: 100%;
        position: relative;
        display: grid;
        grid-template-columns: 40px 1fr max-content;
        margin: 20px 0;
        //background: rgba(0,0,0,0.2);
        //padding-left: 20px;
        button>div{
            margin: 0;
        }
`;
export const FileEntryPlaylistItemContainer = styled.div`
        position: relative;
        padding-bottom: 56.25%;
        height: 0;
        overflow: hidden;
        video, embed {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
    }
`;
export const FileEntryPlaylistItemInfoContainer = styled.div<{ mobileView: boolean }>`
        display: grid;
        grid-template-columns: 50px 1fr max-content ${p => p.mobileView && "max-content max-content"};
        grid-template-rows: 40px;
        margin-top: 20px;
`;
export const FileEntryPlaylistItemGridLeftDescription = styled.div<{ mobileView: boolean }>`
        grid-row: 6;
        grid-column: 1;
        display: grid;
        grid-template-columns: ${p => p.mobileView ? "1fr 40px" : "50px max-content 1fr 40px"};
        grid-template-rows: ${p => p.mobileView ? "max-content" : "max-content 10px 1fr"};
        margin-bottom: 20px;
`;

export const getParentIdFromDataBaseId = (id: string) => {
    const split = id.split("-");
    return `${split[0]}-${split[1]}-${split[2]}-${split[3]}-${split[4]}`;
}



const FileEntryPlaylistItem = (p: PlaylistItemProperties<FileEntryDownloadDto>) => {
    const { item, state, accentColor, playlistId, playlistStateId } = p;
    const { currentGroup, contentLanguage } = useAppSelector(mapper, shallowCompare);
    const { t, currentLanguage } = useImgI18N("cms");
    const emptyImageUrl = useThemePart(t => t.emptyImageUrl);
    const language = contentLanguage ?? currentLanguage;
    const itemState = state.state;
    const done = itemState === "Finished";
    const started = itemState === "Started";
    const [showFullDescription, setShowFullDescription] = React.useState<boolean>(false);

    const isInGroupCMS = isInGroup("CMS");

    const content = getCMSContent({ fileEntry: item, filePosts: {}, language })
    const feId = item.id;

    const { icon, img, link, action, type }: { icon: ImgIcons, img: string, link: string | undefined, action: (() => void) | undefined, type: string } = React.useMemo(() => {
        const type = documentKindByType(content?.media?.mediaType);
        const dwn = getLinkByName(content?.media, "download")?.uri ?? "";
        const linkFrom = (content?.media?.thumbnails ?? [])[0] ?? content?.media;
        const img = getLinkByName(linkFrom, "self")?.uri ?? emptyImageUrl;
        switch (type) {
            case "document":
                return { icon: "file", img, link: dwn, action: undefined, type };
            case "video":
                return { icon: "video player", img, link: createNamedRoute("playlist_fileentry_video_details", { id: feId, context: playlistStateId ?? "" }, true), action: nop, type };
            case "pdf":
                return { icon: "file", img, link: createNamedRoute("playlist_fileentry_pdf", { id: feId, context: playlistStateId ?? "" }, true), action: nop, type };
            case "image":
                return { icon: "image", img, link: createNamedRoute("playlist_fileentry_image", { id: feId, context: playlistStateId ?? "" }, true), action: undefined, type };
        }
        return { icon: "feed", img, link: undefined, action: undefined, type };
    }, [content, emptyImageUrl, feId, playlistStateId]);

    const clickOnItem = React.useMemo(() => () => {
        if (currentGroup && playlistId) {
            const ev: EventUploadDto = {
                eventType: "PostViewed",
                group_id: currentGroup.id,
                payload: {
                    "PostId": item.id,
                    "From": "playlist",
                    "PlaylistId": playlistId,
                }
            };
            ControllerHelper.singleCall({ ev }, EventController.PostEvent);
            if (action)
                action();
        }
    }, [currentGroup, playlistId, item.id, action]);

    const buttonText = usePlaylistButtonText(type as documentKinds, itemState);
    const title = getTranslated(item?.headlines, contentLanguage)?.text ?? content?.media?.fileName;




    return (
        <T.PLItemContainerOuter style={{ background: "#fff" }}>
            <T.PLItemContainer>
                <T.PLItemImage accentColor={accentColor} imageUrl={img}>
                    <T.PLItemContaineClickArea href={link} onClick={clickOnItem} accColor={accentColor} />
                </T.PLItemImage>
                <T.PLItemContainerTitle accentColor={accentColor} href={link} onClick={clickOnItem}>
                    {isInGroupCMS ?
                        <a href={createNamedRoute("cms_file", { projectId: getParentIdFromDataBaseId(item.group_id), id: getParentIdFromDataBaseId(item.id), path: "" }, true)}>
                            <Icon name={icon} marginTop={4} style={{ float: "left", marginRight: 10 }} size="20px" color="@accentRed" />
                        </a>
                        :
                        <Icon name={icon} marginTop={4} style={{ float: "left", marginRight: 10 }} size="20px" />
                    }
                    <SafeHTML html={title} allowedTags={sanitizedAllowedTagsMinimumSafety} allowedAttributes={sanitizedNothingAllowedAttributes} />
                </T.PLItemContainerTitle>
                <T.PLItemContainerTopRight accentColor={accentColor} href={link} onClick={clickOnItem}>
                    <div>
                        <Icon
                            name={done === true ? "eye check" : started === true ? "eye times" : "eye close"}
                            color={done === true ? "@accentGreen" : started === true ? "@darkGrey" : "@accentRed"}
                            size={done === true ? "25px" : started === true ? "25px" : "22px"}
                            marginTop={done === true ? 8 : started === true ? 8 : 6}
                        />
                    </div>
                    <div style={{ paddingRight: 10 }}>
                        {buttonText}
                    </div>
                </T.PLItemContainerTopRight>
                {item.descriptions &&
                    <T.PLItemContainerDescription showFullDescription={showFullDescription} onClick={() => setShowFullDescription(!showFullDescription)}>
                        <SafeHTML html={getTranslated(item.descriptions, contentLanguage).text ?? ""} allowedTags={sanitizedAllowedTagsMinimumSafety} allowedAttributes={sanitizedNothingAllowedAttributes} />
                    </T.PLItemContainerDescription>
                }
                <T.PLItemContainerBottomCat>
                    {t("file type")}:{t(content?.media?.mediaType ?? "")}
                </T.PLItemContainerBottomCat>
            </T.PLItemContainer>
        </T.PLItemContainerOuter>

    );
}

export default FileEntryPlaylistItem;
import React from "react";
import { PlaylistNodeItemDataType } from "../PlaylistNodeItem";
import PlaylistFileEntryDownloadDimmer from "./PlaylistFileEntryDownloadDimmer";
import PlaylistUniversalDimmer from "./PlaylistUniversalDimmer";

const dimmerComponents = {
    WikiArticle: PlaylistUniversalDimmer,
    TubePost: PlaylistUniversalDimmer,
    PodcastPost: PlaylistUniversalDimmer,
    Quiz: PlaylistUniversalDimmer,
    ContentPost: PlaylistUniversalDimmer,
    BestPracticePost: PlaylistUniversalDimmer,
    PorscheMomentPost: PlaylistUniversalDimmer,
    FileEntryUri: PlaylistUniversalDimmer,
    FileEntryVideo: PlaylistUniversalDimmer,
    FileEntryPDF: PlaylistUniversalDimmer,
    FileEntryImage: PlaylistUniversalDimmer,
    FileEntryDownload: PlaylistFileEntryDownloadDimmer,
    // FileEntryDownload: PlaylistUniversalDimmer,
} as const;

type DimmerType = keyof typeof dimmerComponents;

const isDimmerType = (type: string): type is DimmerType => {
    return type in dimmerComponents;
};

export interface PlaylistDimmerProps<T> {
    item: T;
    itemData: PlaylistNodeItemDataType;
    id: string;
    result: any;
    playlistId: string;
    state: any;
    isOptional: boolean;
    transition: any;
    elementIsReference: boolean;
    nodeIsDone: boolean;
    warning: boolean;
    closeDimmer: () => void;
    loadPlaylistStates: any;
    playlistStateId?: string;
}

const PlaylistDimmer = <T extends { type: string }>({ item, itemData, ...props }: PlaylistDimmerProps<T>) => {
    let resolvedType: DimmerType | undefined = item.type as DimmerType;


    if (item.type === "FileEntry" && itemData.type) {
        switch (itemData.type) {
            case "video":
            case "audio":
                resolvedType = "FileEntryVideo";
                break;
            case "pdf":
                resolvedType = "FileEntryPDF";
                break;
            case "image":
                resolvedType = "FileEntryImage";
                break;
            case "url-file":
                resolvedType = "FileEntryUri";
                break;
            default:
                resolvedType = "FileEntryDownload";
        }
    }

    if (!resolvedType || !isDimmerType(resolvedType)) {
        return null;
    }

    const DimmerComponent = dimmerComponents[resolvedType];

    return <DimmerComponent {...props} item={item as any} itemData={itemData} />;
};
export default PlaylistDimmer;

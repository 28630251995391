import { BestPracticePostDownloadDto, ContentPostDownloadDto, ControllerHelper, EventController, EventUploadDto, FileEntryDownloadDto, PodcastPostDownloadDto, PorscheMomentPostDownloadDto, QuizDownloadDto, TubePostDownloadDto, WikiArticleDownloadDto } from 'collaboration-service';
import DateTimeFns from 'components/DateTime/DateTimeFns';
import SafeHTML from 'components/SafeHTML/SafeHTML';
import { Button, ButtonFloates, ButtonKind, Dimmer, getLinkByName, ImgIcons, RatingView } from 'imaginarity-react-ui';
import { FloatedLeft } from 'layout/masterLayouts/MasterLayoutsSC';
import _ from 'lodash';
import * as React from 'react';
import { ApplicationState, useAppSelector } from "services/ApplicationState/ApplicationState";
import { bannerTop } from 'services/Config';
import { shallowCompare } from 'services/Helpers';
import { getMediaLink } from 'services/Helpers/MediaHelpers';
import { sanitizedNothingAllowedAttributes } from 'services/Helpers/SanitizedHelper';
import { getCMSContent, getTranslated } from 'services/Helpers/TranslationHelpers';
import { useImgI18N } from 'services/ImgI18N';
import { PlaylistDetailSC as T } from "../../PlaylistDetailSC";
import MarkAsReadButton from './MarkAsReadButton';
import { PlaylistDimmerProps } from './PlaylistDimmer';
import PreviewBestPractice from './Previews/PreviewBestPractice';
import PreviewContentPost from './Previews/PreviewContentPost';
import PreviewMoment from './Previews/PreviewMoment';
import PreviewQuiz from './Previews/PreviewQuiz';
import PreviewTubePodcast from './Previews/PreviewTubePodcast';
import PreviewWikiArticle from './Previews/PreviewWikiArticle';
import PreviewFileEntryVideoPodcast from './Previews/PreviewFileEntryVideoPodcast';

const mapper = (s: ApplicationState) => ({
    contentLanguage: s.contentLanguage,
    currentGroup: s.currentGroup,
    currentArticle: s.wikiState.currentArticle
});

interface PlaylistUniversalDimmerProps extends PlaylistDimmerProps<WikiArticleDownloadDto | TubePostDownloadDto | PodcastPostDownloadDto | ContentPostDownloadDto | PorscheMomentPostDownloadDto | BestPracticePostDownloadDto | QuizDownloadDto | FileEntryDownloadDto> {
}

const PlaylistUniversalDimmer = (p: PlaylistUniversalDimmerProps) => {
    const { contentLanguage, currentGroup, currentArticle } = useAppSelector(mapper, shallowCompare);
    const { id, item, itemData, result, playlistId, state, isOptional, transition, elementIsReference, nodeIsDone, warning, closeDimmer, loadPlaylistStates, playlistStateId } = p;
    const { t, currentLanguage } = useImgI18N('playlist');
    const language = contentLanguage ?? currentLanguage;
    const type = item.type;
    const isType = (t: string) => type === t;
    const [downloaded, setDownloaded] = React.useState<boolean>(false);



    const isWiki = isType("WikiArticle");
    const isTube = isType("TubePost");
    const isPodcast = isType("PodcastPost");
    const isContentPost = isType("ContentPost");
    const isPmPost = isType("PorscheMomentPost");
    const isBpPost = isType("BestPracticePost");
    const isQuiz = isType("Quiz");
    const isFileEntry = isType("FileEntry");

    const translatedContent = React.useMemo(() => {
        if (isFileEntry) return null;

        const translatedContent = getCMSContent({ fileEntry: item as FileEntryDownloadDto, filePosts: {}, language });
        return translatedContent;
    }, [item, language, isFileEntry]);

    const download = React.useMemo(() => () => {
        if (playlistId) {
            const ev: EventUploadDto = {
                eventType: "PostViewed",
                group_id: item.group_id,
                payload: {
                    "PostId": item.id,
                    "From": "playlist",
                    "PlaylistId": playlistId,
                }
            };
            ControllerHelper.singleCall({ ev }, EventController.PostEvent);
            const ev1: EventUploadDto = {
                eventType: "Finished",
                group_id: item.group_id,
                payload: {
                    "PostId": item.id,
                    "From": "playlist",
                    "PlaylistId": playlistId,
                }
            };
            ControllerHelper.singleCall({ ev: ev1 }, EventController.PostEvent);
        }
        const dwn = getLinkByName(translatedContent?.media, "download")?.uri ?? "";
        const ele = document.createElement("a");
        ele.setAttribute("download", "download");
        ele.setAttribute("href", dwn ?? "");
        ele.style.display = "none";
        ele.click();
        setDownloaded(true);
        setTimeout(() => {
            if (loadPlaylistStates) {
                loadPlaylistStates();
            }
        }, 5000);
    }, [translatedContent, item.id, playlistId, item.group_id, loadPlaylistStates]);

    console.log("----------------- itemData.type", itemData.type)

    const content = isFileEntry ? getCMSContent({ fileEntry: item as FileEntryDownloadDto, filePosts: {}, language }) : undefined;
    const link = content ? _.find(content?.media?.links, l => l.ref === "external-link")?.uri ?? "" : undefined;

    const renderTitleText = () => {


        if (isWiki || isTube || isPodcast || isContentPost || isPmPost || isBpPost) {
            return (
                <SafeHTML html={getTranslated(item.headlines, language)?.text ?? ""} allowedTags={[]} allowedAttributes={{}} />
            );
        }
        else if (isFileEntry) {
            const content = getCMSContent({ fileEntry: item as FileEntryDownloadDto, filePosts: {}, language });
            const link = _.find(content?.media?.links, l => l.ref === "external-link")?.uri ?? "";

            if (itemData.type === "url-file") {
                const title = itemData.title ?? getTranslated(item?.headlines, language)?.text ?? link;
                return <SafeHTML html={title} allowedTags={[]} allowedAttributes={{}} />
            }
            else {
                const title = itemData.title ?? getTranslated(item?.headlines, language)?.text ?? content?.media?.fileName;
                return <SafeHTML html={title} allowedTags={[]} allowedAttributes={{}} />
            }


        }
        return null;
    };

    const renderReadButton = () => {
        if (itemData.type === "url-file") {
            const content = getCMSContent({ fileEntry: item as FileEntryDownloadDto, filePosts: {}, language })
            const link = _.find(content?.media?.links, l => l.ref === "external-link")?.uri ?? "";
            return (<Button
                icon="link external"
                content={t("open link")}
                bordered
                kind="secondary"
                floated="right"
                asHtmlLinkToRoute={link}
                asHtmlLinkToRouteTarget='_blank'
                onClick={clickOnItem}
                tooltip={{
                    tooltipText: t("link opens in new browser tab"),
                    zIndex: 2147483638
                }}
            />);
        }
        else if (itemData.type === "document" || itemData.type === "download") {
            return (<Button
                icon="link external"
                content={state.state === "Finished" ? t("start download again") : t("start download")}
                bordered
                kind="secondary"
                floated="right"
                onClick={download}
            />);
        }


        else if (!itemData.link) return <div />;

        else {
            const typeToLabelMap: Record<string, string> = {
                WikiArticle: t("open in ppe"),
                TubePost: t("open in tube"),
                PodcastPost: t("open in podcast"),
                ContentPost: t("open in smw"),
                PorscheMomentPost: t("open in moments"),
                BestPracticePost: t("open in pri"),
                FileEntryUri: t("open link"),
            };
            const content = typeToLabelMap[item.type ?? ""] as string | undefined;
            if (!content) return null;

            const commonProps = {
                icon: "link external" as ImgIcons,
                bordered: true,
                kind: "secondary" as ButtonKind,
                floated: "right" as ButtonFloates,
                asHtmlLinkToRoute: itemData.link,
                onClick: clickOnItem,
            };

            return <Button
                {...commonProps}
                content={content}
                {...((isWiki || itemData.type === "url-file") && { asHtmlLinkToRouteTarget: "_blank", tooltip: { tooltipText: t("link opens in new browser tab"), zIndex: 2147483638 } })}
            />;
        }

    };


    const renderImageHeader = () => {
        if (isWiki) {
            return <T.DimmerImageContainer
                imageUrl={(currentArticle ? getMediaLink(currentArticle, a => a.media) : undefined) ?? bannerTop.wikiArticleAlternative}
                style={{ zIndex: 1 }}
            />;
        }
        else if (isTube || isPodcast) {
            return <PreviewTubePodcast
                playlistStateId={playlistStateId}
                id={id}
                isPodcast={isPodcast}
            />;
        }
        else if (isContentPost) {
            return (<PreviewContentPost item={item as ContentPostDownloadDto} playlistStateId={playlistStateId} />);
        }
        else if (isPmPost) {
            return (<PreviewMoment id={id} context={p.playlistStateId} />);
        }
        else if (isBpPost) {
            return (<PreviewBestPractice item={item as BestPracticePostDownloadDto} isTitle id={id} />);
        }
        else if (isQuiz) {
            return (
                <PreviewQuiz
                    item={item as QuizDownloadDto}
                    id={id}
                    closeDimmer={closeDimmer}
                    playlistStateId={playlistStateId}
                />
            );
        }
        else if (isFileEntry) {
            if (itemData.type === "url-file") {
                const imageUrl = itemData.image;
                return (<T.DimmerImageContainer imageUrl={imageUrl} lightBG style={{ backgroundSize: "contain", aspectRatio: "16 / 9", marginTop: -1 }} />);
            }
            else if (itemData.type === "video") {
                return <PreviewFileEntryVideoPodcast
                    playlistStateId={playlistStateId}
                    id={id}
                    isPodcast={false}
                />
            }
            else if (itemData.type === "audio") {
                return <PreviewFileEntryVideoPodcast
                    playlistStateId={playlistStateId}
                    id={id}
                    isPodcast={true}
                />
            }
            else if (itemData.type === "pdf") {
                return <embed src={getLinkByName(translatedContent?.media, "self")?.uri ?? ""} style={{ width: "100%", height: "100%" }} />
            }
            else if (itemData.type === "image") {
                return <img src={getLinkByName(translatedContent?.media, "self")?.uri ?? ""} alt="Scrollable content" style={{ width: "100%", height: "100%", objectFit: "contain", }} />
            }
            else if (itemData.type === "document" || itemData.type === "download") {
                return <div style={{ padding: "30px 40px", fontSize: "1rem", background: "yellow", fontWeight: "bolder", color: "red" }}>
                    {downloaded ? t('states will be actualized, do not wait for it') : t("this content can only be downloaded.")}
                </div>
            }
            else return null;
        }
        return null;
    };
    const renderDescription = () => {
        if (isWiki) {
            return (<PreviewWikiArticle directId={id} context={playlistStateId} />);
        }
        else if (isTube || isPodcast || isContentPost || itemData.type === "url-file" || itemData.type === "video" || itemData.type === "pdf" || itemData.type === "image" || itemData.type === "download" || itemData.type === "document") {
            return (
                <SafeHTML
                    html={getTranslated(item.descriptions, language).text ?? ""}
                    allowedTags={["strong", "em", "b", "i", "u"]}
                    allowedAttributes={sanitizedNothingAllowedAttributes}
                />
            );
        }
        else if (isBpPost) {
            return (<PreviewBestPractice item={item as BestPracticePostDownloadDto} id={id} />);
        }

        return null;
    };

    const postViewed = React.useCallback(() => {
        if (!currentGroup || !playlistId) return;
        const ev: EventUploadDto = {
            eventType: "PostViewed",
            group_id: currentGroup.id,
            payload: {
                PostId: item.id,
                From: "playlist",
                PlaylistId: playlistId,
            },
        };
        ControllerHelper.singleCall({ ev }, EventController.PostEvent);
    }, [currentGroup, playlistId, item.id]);

    React.useEffect(() => {
        postViewed();
    }, [postViewed]);

    const clickOnItem = postViewed;

    return (
        <Dimmer active >
            <T.UniversalDimmerContainer
                isPmPost={isPmPost}
                isQuiz={isQuiz}
                style={{ maxWidth: itemData.type === "pdf" ? "90vw" : 880 }}
            >
                <T.RenderImageHeaderContainer style={{ height: itemData.type === "pdf" ? "calc(90vh - 200px)" : "fit-content" }}>
                    {renderImageHeader()}
                </T.RenderImageHeaderContainer>

                {!isQuiz &&
                    <>
                        <T.UniversalDimmerInfoContainer>
                            <T.DimmerInfoContainerTitle>
                                {renderTitleText()}
                            </T.DimmerInfoContainerTitle>
                            <T.DimmerInfoContainerButton>
                                {item.rating > 0 ?
                                    <T.DimmerInfoContainerRating>
                                        <RatingView
                                            rating={item.rating}
                                            maxStars={5}
                                            size={24}
                                            filledOut={true}
                                        />

                                    </T.DimmerInfoContainerRating>
                                    :
                                    <div />
                                }
                                {/* {itemData.link !== "" ? renderReadButton() : <div />} */}
                                {renderReadButton()}
                                {(itemData.type !== "document" && itemData.type !== "download") &&
                                    <MarkAsReadButton
                                        playlistId={playlistId}
                                        playlistItemState={state}
                                        item={item}
                                        lastAction={loadPlaylistStates}
                                    />
                                }
                                <Button floated='right' icon='times' onClick={closeDimmer} kind='secondary' tooltip={{ tooltipText: t("close"), position: "top", zIndex: 2147483639 }} />
                            </T.DimmerInfoContainerButton>

                            <T.DimmerInfoContainerDate>
                                {result.dueToDate &&
                                    <FloatedLeft>
                                        <DateTimeFns
                                            date={result.dueToDate}
                                            noTime
                                            hideIcons
                                            textColor="grey"
                                        />
                                    </FloatedLeft>
                                }
                                {isOptional ?
                                    <T.ColoredSpan color={state.state === "Finished" ? "@accentGreen" : "inherit"}>
                                        {state.state === "Finished" ? t("done") : t("optional")}
                                    </T.ColoredSpan>
                                    :
                                    (transition === "ReferenceDone" || transition === "ReferenceDoneWithValue") ?
                                        elementIsReference ?
                                            <T.ColoredSpan color={(result.text === "100%" || nodeIsDone) ? "@accentGreen" : warning ? "@warning" : result.color}>
                                                {nodeIsDone ? t("done") : warning ? t("warning") : result.text}
                                            </T.ColoredSpan>
                                            :
                                            <T.ColoredSpan color={result.text === "100%" ? "@accentGreen" : "@middleLightGrey"}>
                                                {result.text === "100%" ? t("done") : t("optional")}
                                            </T.ColoredSpan>
                                        :
                                        <T.ColoredSpan color={result.text === "100%" ? "@accentGreen" : warning ? "@warning" : result.color}>
                                            {result.text === "100%" ? t("done") : t(result.text)}
                                        </T.ColoredSpan>
                                }
                            </T.DimmerInfoContainerDate>
                        </T.UniversalDimmerInfoContainer>
                        {itemData.type === "url-file" &&
                            <T.DimmerInfoContainerLink>
                                <a href={link} target="_blank" rel="noreferrer">{link}</a>
                            </T.DimmerInfoContainerLink>
                        }
                        <T.RenderDescriptionContainer isPdf={itemData.type === "pdf"} isWiki={isWiki}>
                            {renderDescription()}
                        </T.RenderDescriptionContainer>
                    </>
                }
            </T.UniversalDimmerContainer>
        </Dimmer>
    );
}
export default PlaylistUniversalDimmer;
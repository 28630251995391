import { PlayListNodeDownloadDto, PlaylistStateDownloadDto } from 'collaboration-service';
import SafeHTML from 'components/SafeHTML/SafeHTML';
import { differenceInDays, differenceInHours } from 'date-fns';
import { belowBreakpointOrEqual, Button, Icon, Theme, Tooltip } from 'imaginarity-react-ui';
import { FloatedLeft, PaddedDiv } from 'layout/masterLayouts/MasterLayoutsSC';
import _ from 'lodash';
import * as React from 'react';
import { ApplicationState, useAppSelector } from 'services/ApplicationState/ApplicationState';
import { AccessHelpers, calcMinsInHoursAndMins, shallowCompare } from 'services/Helpers';
import { getTranslated, getTranslatedStandardDate } from 'services/Helpers/TranslationHelpers';
import { useImgI18N } from 'services/ImgI18N';
import { useAppTheme } from 'services/useAppTheme';
import { ThemeContext } from 'styled-components';
import { NodeState } from '../PlaylistDetail';
import { PlaylistTheme } from '../PlaylistMain';
import { PlaylistSC } from '../PlaylistSC';
import { TimeLineSC as T } from './TimeLineSC';

const mapper = (state: ApplicationState) => ({ contentLanguage: state.contentLanguage, user: state.user });

function getColoredLine(nodeIsInFuture: boolean, warning: boolean, dotColor: string, gid: number, todayPosition: number) {
    const idx = (100 * gid + 1) - 1;
    const top = 100;
    // const zIndex = 10;
    if (!nodeIsInFuture) {
        return (<T.ColoredLineHorizontal color={dotColor} idx={idx} width={todayPosition} top={top} style={{ left: "50%", right: "-60%" }} />);
    }
    if (warning) {
        return (
            <T.ColoredLineHorizontal color={dotColor} idx={idx} width={todayPosition} top={top} style={{ left: "-5%", right: "50%" }} />);
    }
    return (<T.ColoredLineHorizontal color="@neutralLow" idx={idx} width={todayPosition} top={top} style={{ left: "-5%", right: "-60%" }} />);
}

interface TimelineProps {
    data: PlaylistStateDownloadDto;
    onClose?: () => void;
}

const Timeline = (p: TimelineProps) => {
    const { data, onClose } = p;
    const { contentLanguage, user } = useAppSelector(mapper, shallowCompare);
    const { t } = useImgI18N("playlist");
    const now = React.useMemo(() => new Date(), []);
    const allNodes = React.useMemo(() => [...data.prevNodes, ...data.curNodes, ...data.nextNodes], [data]);
    const [today, setToday] = React.useState<Date>(now);
    const playlistTheme = useAppTheme<PlaylistTheme>("playlistTheme");
    const warningLess_X_Hours = playlistTheme.warningLess_X_Hours;
    const isGlobalAdmin = AccessHelpers.isGlobalAdmin()(user);
    const theme = React.useContext<Theme>(ThemeContext);
    const isMobile = belowBreakpointOrEqual({ theme }, "tablet");

    const handleDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const selectedDate = new Date(event.target.value);
        setToday(selectedDate);
    };
    const resetToToday = () => {
        setToday(now);
    };

    const sortedData = React.useMemo(() => _.sortBy(allNodes, (node) => node.calculatedDueDate), [allNodes]);

    const groupedData = React.useMemo(() => _.groupBy(sortedData, (node) => node.calculatedDueDate ? new Date(node.calculatedDueDate).toDateString() : ""), [sortedData]);

    const maxEntriesPerNode = React.useMemo(() => Math.max(...Object.values(groupedData).map(group => group.length)), [groupedData]);
    const groupedDataGroups = React.useMemo(() => Object.keys(groupedData).length, [groupedData]);

    const dates = React.useMemo(() => Object.keys(groupedData)
        .map(dateStr => new Date(dateStr))
        .sort((a, b) => a.getTime() - b.getTime()), [groupedData]);

    const { whereIsToday, position } = React.useMemo(() => {
        let whereIsToday: "before" | "between" | "after";
        let position = -1;

        if (today > dates[0] && today < dates[dates.length - 1]) {
            position = dates.findIndex((date) => today < date);
        }

        if (today < dates[0]) {
            whereIsToday = "before";
        } else if (today > dates[dates.length - 1]) {
            whereIsToday = "after";
        } else {
            whereIsToday = "between";
        }

        return { whereIsToday, position };
    }, [today, dates]);

    const columnWidth = React.useMemo(() => 100 / groupedDataGroups, [groupedDataGroups]);

    const calcTodayPosition = React.useMemo(
        () => (position + 1) * columnWidth - columnWidth,
        [position, columnWidth]
    );

    const todayPosition = React.useMemo(
        () => (whereIsToday === "before" ? 0 : whereIsToday === "after" ? 100 : calcTodayPosition),
        [whereIsToday, calcTodayPosition]
    );

    const showDisconnector = React.useMemo(() => calcTodayPosition < 0, [calcTodayPosition]);
    let phaseIdx = 0;

    const getNodeState = React.useMemo(() => (n: PlayListNodeDownloadDto, dif: number): NodeState => {
        let ret = {
            state: 'Overdue',
            finished: 0
        } as NodeState;

        if (data) {
            _.forEach(n.referenceIds, refId => {
                const state = _.find(data.itemStates, st => st.referenceId === refId) ?? _.find(data.prevItemStates, st => st.referenceId === refId);
                if (state?.state === 'Finished')
                    ret.finished++;
            });

            const first = _.first(n.children);
            const transFunction = first?.transitionFunction;
            const transValue = first?.referenceValue ?? 0;
            const refId = first?.referenceContentId ?? '';

            if (transFunction === "FallThru") {
                ret.state = "Finished";
            }
            if (transFunction === "AbsCountDone") {
                const f = _.filter(data.prevItemStates, st => st.state === "Finished");
                if (f.length >= transValue)
                    ret.state = "Finished";
            }
            if (transFunction === "PercentageDone") {
                const f = _.filter(data.itemStates, st => st.state === "Finished");
                const doneNow = f.length / data.itemStates.length;
                if (doneNow >= transValue)
                    ret.state = "Finished";
            }
            if (transFunction === "ReferenceDone") {
                const s = _.find(data.prevItemStates, st => st.referenceId === refId);
                if (s && s.state === 'Finished')
                    ret.state = "Finished";
            }
            if (transFunction === "ReferenceDoneWithValue") {
                const s = _.find(data.prevItemStates, st => st.referenceId === refId);
                if (s && s.state === 'Finished')
                    ret.state = "Finished";
            }
            if (n.referenceIds?.length === ret.finished)
                ret.state = 'Finished';
            else {
                if (dif >= 0 && ret.state !== 'Finished')
                    ret.state = 'On Track';
            }
        }
        return ret;
    }, [data]);

    const getIsToday = React.useMemo(() => (dueDate: Date | undefined): boolean | undefined => {
        const isToday = dueDate &&
            dueDate.getFullYear() === today.getFullYear() &&
            dueDate.getMonth() === today.getMonth() &&
            dueDate.getDate() === today.getDate();
        return isToday;
    }, [today]);

    const getNodeGroupInfo = React.useCallback((groupItems: PlayListNodeDownloadDto[]) => {
        const cd = groupItems[0].calculatedDueDate;
        const nodeDueDate = cd ? new Date(cd) : undefined;
        const getYear = nodeDueDate ? getTranslatedStandardDate(nodeDueDate, 'yy') : undefined;
        const getActYear = getTranslatedStandardDate(now, 'yy');
        const mobileView = isMobile;
        const formattedDate = (nodeDueDate ? (getTranslatedStandardDate(nodeDueDate, (mobileView ? 'dd. MM.' : (getYear === getActYear ? 'dd. MMM' : 'dd. MMM y')))) : t("no due date"));
        const isToday = getIsToday(nodeDueDate);

        return { nodeDueDate, formattedDate, isToday };
    }, [now, getIsToday, isMobile, t]);

    const getItemInfo = React.useCallback((g: PlayListNodeDownloadDto, nodeDueDate: Date | undefined) => {
        const dif = differenceInDays(new Date(nodeDueDate as Date), today);
        const nodeState = getNodeState(g, dif);
        const stateOfNode = nodeState.state;
        const nodeIsInFuture = nodeDueDate ? today < nodeDueDate : false;
        const diffInHours = differenceInHours(new Date(nodeDueDate as Date), today);
        const warning = diffInHours > 0 && diffInHours < warningLess_X_Hours;
        const isToday = getIsToday(nodeDueDate);

        const dotColor = (data.state === "Finished" ||
            (stateOfNode === "Finished" && _.find(data.prevNodes, pv => pv.id === g.id)) ||
            nodeState.finished === 1) ? "@accentGreen" :
            warning ? "@accentOrange" :
                isToday ? "@accentOrange" :
                    nodeIsInFuture ? "@neutralLow" :
                        stateOfNode === "On Track" ? "@accentGreen" :
                            stateOfNode === "Overdue" ? "@accentRed" : "@darkGrey";

        return { dotColor, nodeIsInFuture, warning };
    }, [data, today, warningLess_X_Hours, getNodeState, getIsToday]);


    // const nextNodeToDo = React.useMemo(() => {
    //     return sortedData.find(node => {
    //         const stateOfNode = getNodeState(node, differenceInDays(new Date(getPlaylistNodeDueDate(data, node) as Date), today)).state;
    //         return stateOfNode !== "Finished"; 
    //     });
    // }, [sortedData, data, today, getNodeState]);


    return (
        <>

            <T.Container top={isMobile ? 0 : (maxEntriesPerNode * 14)} style={{ height: "auto" }}>
                <T.TimeLineAll />
                <T.ContainerInner>
                    <T.ColoredLineHorizontal color='@middleLightGrey' idx={1} width={todayPosition} />
                    <T.TodayDot left={todayPosition} />
                    <T.TimeTodayLineVertical left={todayPosition} />
                    <T.DueDate
                        left={todayPosition}
                        idx={10}
                        color='@lightGrey'
                        style={{ top: "calc(50% + 52px)" }}
                    >
                        {t("today")}
                    </T.DueDate>
                    <T.PlaylistsContainer left={todayPosition} columns={groupedDataGroups}>
                        {_.map(Object.entries(groupedData), ([groupKey, groupItems], gid) => {
                            phaseIdx++;
                            const { nodeDueDate, formattedDate, isToday } = getNodeGroupInfo(groupItems);
                            const key = groupKey + gid;
                            const numberOfElementsInGroupedData = Object.keys(groupItems).length;

                            const countOptionalNodes = _.sumBy(groupItems, g => g.isOptional === true ? 1 : 0);
                            const isOptional = countOptionalNodes > 0;
                            const amountOfOptinalNodes = numberOfElementsInGroupedData > 1 && isOptional ? `${countOptionalNodes}x ` : undefined;


                            return (
                                <T.PlaylistsContainerRelative key={key} lang={contentLanguage} >
                                    <T.PlaylistsContainerAbsolute>
                                        {_.map(groupItems, (g, k) => {
                                            const { dotColor, nodeIsInFuture, warning } = getItemInfo(g, nodeDueDate);
                                            const isOptinalNode = g.children?.[0]?.transitionFunction === "FallThru";

                                            // const isNext = g.id === nextNodeToDo?.id;

                                            return (
                                                <React.Fragment key={k}>
                                                    {getColoredLine(nodeIsInFuture, warning, dotColor, gid, todayPosition)}
                                                    {!isMobile &&
                                                        <T.PlaylistNodeTitle key={g.id ?? k} color={dotColor === "@neutralLow" ? "@darkGrey" : dotColor}>
                                                            <Tooltip
                                                                tooltipText=''
                                                                position='bottom'
                                                                cursor='help'
                                                                tooltipContent={<div style={{ textAlign: "left" }}>
                                                                    <div style={{ fontWeight: "bolder" }}>
                                                                        <Icon name="info" style={{ float: "left", marginRight: 10, marginLeft: -2 }} marginTop={-2} color={g.color ?? "#000"} />
                                                                        <SafeHTML
                                                                            allowedTags={[]}
                                                                            allowedAttributes={{}}
                                                                            html={getTranslated(g.headlines, contentLanguage)?.text}
                                                                        />
                                                                    </div>
                                                                    <T.ToolTipDiv>
                                                                        {g.descriptions !== undefined &&
                                                                            <SafeHTML
                                                                                html={getTranslated(g.descriptions, contentLanguage)?.text ?? ""}
                                                                            />
                                                                        }
                                                                    </T.ToolTipDiv>
                                                                    {g.descriptions &&
                                                                        <p><SafeHTML
                                                                            allowedTags={[]}
                                                                            allowedAttributes={{}}
                                                                            html={getTranslated(g.descriptions, contentLanguage)?.text}
                                                                        /></p>
                                                                    }
                                                                    <T.ToolTipFooter>
                                                                        <div>
                                                                            {nodeDueDate ?
                                                                                <>
                                                                                    <Icon name="calendar" style={{ float: "left", marginRight: 5 }} marginTop={-2} size={16} />
                                                                                    <FloatedLeft>
                                                                                        {getTranslatedStandardDate(nodeDueDate!, "P")}
                                                                                        {isOptinalNode &&
                                                                                            <span style={{ marginLeft: 3, fontSize: "0.9em" }}>
                                                                                                {/* <Icon name="asterisk" style={{ float: "left" }} marginTop={1} size={16} /> */}
                                                                                                (optional)
                                                                                            </span>
                                                                                        }
                                                                                    </FloatedLeft>
                                                                                    {(g.dueDateRelativeTo !== undefined && g.dueDateRelativeTo !== 'Absolute' && g.dueDateRelativeInHours) &&
                                                                                        <>
                                                                                            <Icon name="clock" style={{ float: "left", marginLeft: 10, marginRight: 5 }} marginTop={-2} size={16} />
                                                                                            <div style={{ float: "left" }}>
                                                                                                {getTranslatedStandardDate(nodeDueDate, "p")}
                                                                                            </div>
                                                                                        </>
                                                                                    }
                                                                                </>
                                                                                :
                                                                                t("no due date set")
                                                                            }
                                                                        </div>
                                                                        {g.durationInMinutes ?
                                                                            <PaddedDiv unit="px" left={20}>
                                                                                <Icon name="clock" style={{ float: "left", marginRight: 5 }} marginTop={-1} size={16} />
                                                                                {calcMinsInHoursAndMins(g.durationInMinutes, t)}
                                                                            </PaddedDiv>
                                                                            :
                                                                            <div />
                                                                        }

                                                                    </T.ToolTipFooter>

                                                                </div>}
                                                                noMargin
                                                                notInline
                                                            >
                                                                <span>
                                                                    <SafeHTML
                                                                        allowedTags={[]}
                                                                        allowedAttributes={{}}
                                                                        html={(getTranslated(g.headlines, contentLanguage)?.text + ((isOptinalNode === true && groupItems.length > 1) ? " *" : ""))}
                                                                    />

                                                                </span>
                                                            </Tooltip>
                                                        </T.PlaylistNodeTitle>
                                                    }
                                                    <T.Dot
                                                        left={50}
                                                        color={dotColor}
                                                    />
                                                </React.Fragment>
                                            );
                                        }
                                        )}
                                    </T.PlaylistsContainerAbsolute>

                                    {isToday &&
                                        <>
                                            {/* <T.TodayMarker /> */}
                                            <Icon
                                                name="chevron right"
                                                style={{
                                                    position: "absolute",
                                                    right: 25,
                                                    bottom: -31,
                                                }}
                                                size="15px"
                                                color="@neutralLow"
                                            />
                                        </>
                                    }
                                    <T.DueDate idx={(phaseIdx + 1) + 100} left={50} color={nodeDueDate ? "@darKGrey" : "@middleLightGrey"} style={{ fontWeight: isToday ? "bolder" : "normal", marginTop: isOptional ? 8 : 0 }}>
                                        {formattedDate}
                                        {isOptional && <T.DueDateOptionalHint>
                                            {amountOfOptinalNodes} {t("optional")}
                                        </T.DueDateOptionalHint>}
                                    </T.DueDate>
                                </T.PlaylistsContainerRelative>
                            );
                        })}
                    </T.PlaylistsContainer>
                    {showDisconnector &&
                        <T.Disconnector left={todayPosition} />
                    }
                </T.ContainerInner>
                <T.TimeLineAllCover1 />
                <T.TimeLineAllCover2 />
                <T.CloseArea onClick={onClose}>
                    <Icon name='chevron up double' color='transparent' size="24px" />
                </T.CloseArea>
            </T.Container >
            <div style={{ height: 20 }} />
            {isGlobalAdmin &&
                <PlaylistSC.AdminPanel >
                    <div style={{ float: "left", lineHeight: "40px", paddingLeft: 20 }}>Temporary Admin Panel</div>
                    <div style={{ float: "right", lineHeight: "40px", marginRight: 0 }}>
                        {/* <Checkbox label='Sticky Header' onClick={() => setStickyHeader(!stickyHeader)} selected={stickyHeader} /> */}
                        {/* <div style={{ float: "right", cursor: "pointer" }} onClick={resetToToday}> */}
                        {/* <Icon name="times" color="@accentRed" style={{ float: "right", marginTop: 1, marginLeft: 5 }} /> */}
                        <Button kind="transparentButton" icon="times" onClick={resetToToday} floated='right' />
                        {/* </div> */}
                        <input
                            id="datePicker"
                            type="date"
                            value={today.toISOString().split('T')[0]}
                            onChange={handleDateChange}
                            style={{ outline: "none", padding: "0 10px", border: "none", background: "rgba(0,0,0,0.05)", color: "#313639", float: "right", height: 40, cursor: "pointer" }}
                        />
                    </div>
                    <div style={{ float: "right", height: "100%", marginRight: 10 }} >Manipulate Today Date</div>
                </PlaylistSC.AdminPanel>
            }
        </>
    );
};
export default Timeline;

import { FileEntryDownloadDto, PALMSUserTraining, PlaylistContentDownloadDto, PostDownloadDto } from 'collaboration-service';
import SafeHTML from 'components/SafeHTML/SafeHTML';
import { Button, Icon, ImgIcons } from 'imaginarity-react-ui';
import * as React from 'react';
import { useAppSelector } from 'services/ApplicationState/ApplicationState';
import { createNamedRoute, linkToRoute } from 'services/Helpers/RoutingHelper';
import { sanitizedNothingAllowed } from 'services/Helpers/SanitizedHelper';
import { getCMSContent, getTranslated } from 'services/Helpers/TranslationHelpers';
import { useImgI18N } from 'services/ImgI18N';
import { isInGroup } from 'services/StoreDependantHelpers';
import { FileEntryHint, IECButtons, IECIcon, IECTitle, IECType, ImplementedElementsContainer } from './PlaylistEditClasses';
import PlaylistEditPreviewElement from './PlaylistEditPreviewElement';
import { documentKindByType } from '../PlaylistItems/PlaylistNodeItem';

export function shortenUUID(uuid: string): string {
    const parts = uuid.split('-');
    return parts.slice(0, 5).join('-');
}

export interface PlaylistEditContentImplementedElementProps {
    content: PlaylistContentDownloadDto;
    post: PostDownloadDto;
    numberOfUsed: number;
    onRemove?: () => void;
    toggleHidden: (hidden: boolean) => void;
    sortByType: boolean;
    showAsList?: boolean;
    noLink?: boolean;
}

export interface PlaylistEditContentImplementedElementState {
    hidden: boolean;
}

export const playlistContentToIcon = (post: PostDownloadDto): ImgIcons => {
    switch (post.type) {
        case "WikiArticle":
            return "feed";
        case "TubePost":
            return "video player";
        case "PodcastPost":
            return "mic on";
        case "Quiz":
            return "game";
        case "ContentPost":
            return "content aggregation";
        case "PorscheMomentPost":
            return "excite";
        case "PALMS":
            return "link external";
        default:
            return "feed";
    }
}
type PlaylistContentData = { icon: ImgIcons, type: string | undefined, title: string };
export const getPlaylistContentData = (content: PlaylistContentDownloadDto, lng: string, post: PostDownloadDto): PlaylistContentData => {
    const title = content.referenceType === "PALMS" ? (JSON.parse(content.referenceData ?? '{"title": ""}') as PALMSUserTraining)?.title : (post.type === "FileEntry" ? (post as FileEntryDownloadDto).name : getTranslated(post?.headlines, lng)?.text);
    return {
        icon: playlistContentToIcon(post),
        type: content.referenceType,
        title: title,
    }
}

const PlaylistEditContentImplementedElement = (p: PlaylistEditContentImplementedElementProps) => {
    const contentLanguage = useAppSelector(s => s.contentLanguage)
    const { content, onRemove, sortByType, post, numberOfUsed, showAsList, noLink } = p;
    const { t, currentLanguage } = useImgI18N("playlist");

    const lng = contentLanguage ?? currentLanguage;

    const { icon, type, title } = React.useMemo(() => {
        return getPlaylistContentData(content, lng, post);
    }, [content, lng, post]);

    const removeDisabled = numberOfUsed !== 0;

    const sanitizedTitle = title.replace(/<\/?p>/g, '');
    const contentFE = post?.type === "FileEntry" ? getCMSContent({ fileEntry: post as FileEntryDownloadDto, filePosts: {}, language: lng }) : undefined;
    const eleType = post?.type === "FileEntry" ? documentKindByType(contentFE?.media?.mediaType) : undefined;
    const typeIsFileEntry = post.type === "FileEntry";

    const shortGroupId = shortenUUID(post.group_id);

    const postTypeConfig: Record<string, {
        route: string,
        params: (post: PostDownloadDto) => Record<string, any>,
        group: string,
        checkParentId?: boolean
    }> = {
        "FileEntry": { route: "cms_file", params: post => ({ projectId: shortGroupId, id: post.parentId }), group: "CMS", checkParentId: true },
        "WikiArticle": { route: "wiki_article", params: post => ({ id: post.id }), group: "WIKI" },
        "TubePost": { route: "tube_video_details", params: post => ({ id: post.id }), group: "TUBE" },
        "PodcastPost": { route: "podcast_audio_details", params: post => ({ id: post.id }), group: "PODCAST" },
        "Quiz": { route: "quiz_details", params: post => ({ quizId: post.id }), group: "QUIZ" },
        "ContentPost": { route: "content_art", params: post => ({ id: post.id }), group: "LANDING PAGE SMART MOBILITY" },
        "BestPracticePost": { route: "best_detail", params: post => ({ id: post.id }), group: "BEST PRACTICE" },
        "PorscheMomentPost": { route: "community_view_post", params: post => ({ id: post.id }), group: "COMMUNITY" }
    };

    const routeConfig = postTypeConfig[post.type as keyof typeof postTypeConfig];
    const linkedButtons = [];

    if (routeConfig && isInGroup(routeConfig.group) && (!routeConfig.checkParentId || post.parentId)) {
        linkedButtons.push(
            <Button
                key={post.type}
                icon="link external"
                floated="right"
                kind="transparentButton"
                asHtmlLinkToRouteTarget="_blank"
                asHtmlLinkToRoute={linkToRoute(createNamedRoute(routeConfig.route as any, routeConfig.params(post)) ?? "/")}
                tooltip={{ tooltipText: t("{{type}} opens in a new browser tab", { type: post.type }) }}
            />
        );
    }

    return (
        <ImplementedElementsContainer typeIsFileEntry={typeIsFileEntry} sortByType={sortByType} showAsList={showAsList ?? false}>
            <IECIcon>
                <Icon name={icon} style={{ float: "left", marginTop: showAsList ? 4 : 4, marginLeft: 10 }} />
                {typeIsFileEntry && sortByType &&
                    <Icon name="chevron right" size="14px" style={{ float: "left", marginTop: 2 }} />
                }
            </IECIcon>

            <IECType>
                {!sortByType && typeIsFileEntry &&
                    <>
                        <FileEntryHint>
                            {t(post.type ?? "unknown")}
                        </FileEntryHint>
                        <Icon name="chevron right" size="12px" style={{ float: "left", marginTop: 2, marginLeft: -5 }} />
                    </>
                }
                {(typeIsFileEntry || !sortByType) && t(eleType ?? type ?? "unknown")}
            </IECType>


            <IECTitle notRemoveable={showAsList ? true : removeDisabled} title={sanitizedTitle} showAsList={showAsList ?? false}>
                <SafeHTML
                    allowedTags={sanitizedNothingAllowed}
                    html={title}
                />
            </IECTitle>
            <IECButtons >
                {showAsList ?
                    <div style={{ marginTop: -7 }}>
                        <PlaylistEditPreviewElement post={post} />
                    </div>
                    :
                    <PlaylistEditPreviewElement post={post} />
                }

                {!noLink && linkedButtons}

                {onRemove &&
                    <Button
                        tooltip={{ tooltipText: removeDisabled ? t("remove element from list not possible as it is used in a phase") : t("remove item from list") }}
                        kind={removeDisabled ? "fullTransparentButton" : "transparentButton"}
                        iconColor={removeDisabled ? '@middleLightGrey' : '@accentRed'}
                        icon="times"
                        floated="right"
                        onClick={onRemove}
                        disabled={removeDisabled} />
                }
            </IECButtons>
        </ImplementedElementsContainer>
    );
}

export default PlaylistEditContentImplementedElement;
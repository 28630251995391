import { CategoryDownloadDto, ElementNode, FetchPostsFilter, PostController } from 'collaboration-service';
import AutocompleteSearch from 'components/AutocompleteSearch/AutocompleteSearch';
import { Icon, lightenColor } from 'imaginarity-react-ui';
import * as _ from "lodash";
import * as React from 'react';
import Autosuggest, { SuggestionsFetchRequestedParams } from 'react-autosuggest';
import { connect } from 'react-redux';
import { Actions } from 'services/ApplicationState/Actions';
import { ApplicationState } from 'services/ApplicationState/ApplicationState';
import { ComponentWithMappedApplicationState } from 'services/ApplicationState/HelperInterfaces';
import { ImgI18NTranslatedComponentProps, translate } from 'services/ImgI18N';
import { ThemeContext } from 'styled-components';
import { PodcastSC as T } from './PodcastSC';

const mapper = (state: ApplicationState) => ({
    //search: state.globalSearchState.search,
});

interface PodcastSearchProps extends ImgI18NTranslatedComponentProps, ComponentWithMappedApplicationState<typeof mapper> {
    onSearch?: (val: string) => void;
    accent?: string;
    categoryStack?: ElementNode<CategoryDownloadDto>[];
    categories?: CategoryDownloadDto[];
    filter: FetchPostsFilter;
    lightMode?: boolean;
}

const PodcastSearch = (p: PodcastSearchProps) => {
    const [search, setSearch] = React.useState<string>();
    const [suggestions, setSuggestions] = React.useState<string[]>([]);

    const theme = React.useContext(ThemeContext);

    const { t, onSearch, categoryStack, dispatch, filter } = p;
    //const globalsearch = p.search;
    const lm = p.lightMode;
    React.useEffect(() => {
        setSearch("");
        setSuggestions([]);
    }, [categoryStack])

    // React.useEffect(() => {
    //     setSearch(globalsearch);
    // }, [globalsearch]);

    const onEnterPressed = React.useMemo(() => () => {
        //console.log("onEnterPressed");

        if (onSearch)
            onSearch(search ?? "");
        //dispatch(Actions.setGlobalSearchSearch(search));
    }, [onSearch, search, /*dispatch*/]);

    const onSuggestionSelected = React.useMemo(() => (e: any, data: { suggestion: string }) => {
        //console.log("onSuggestionSelected");

        if (search) {
            const prefixEnd = search.lastIndexOf(" ");
            let prefix = "";
            if (prefixEnd >= 0)
                prefix = search.slice(0, prefixEnd) + " ";
            const s = `${prefix}${data.suggestion}`;
            //console.log('PodcastSearch.tsx:92:s => ', s);
            if (onSearch)
                onSearch(s);
            //dispatch(Actions.setGlobalSearchSearch(s));
        }
    }, [onSearch, search, /*dispatch*/]);

    const onSuggestionsFetchRequested = React.useMemo(() => _.debounce((request: SuggestionsFetchRequestedParams) => {
        //console.log("onSuggestionsFetchRequested", filter);

        if (request.reason === "input-changed" || request.reason === "input-focused") {
            let terms = request.value.split(" ");
            terms = _.filter(terms, t => t.length > 0);
            const lastTerm = _.last(terms);

            // if (lastTerm)
            //     PostController.SuggestPostTerm1({ term: lastTerm, filter }, setSuggestions);

            if (lastTerm) {
                PostController.SuggestPostTerm2({ term: lastTerm, filter }, suggestions => {
                    setSuggestions(suggestions);
                });
            }


        }
    }, 250), [setSuggestions, filter]);


    const searchChanged = React.useMemo(() => (event: React.FormEvent<any>, params: Autosuggest.ChangeEvent) => {
        setSearch(params.newValue);
        //console.log('PodcastSearch.tsx:108:params.newValue => ', params.newValue);
    }, [setSearch]);

    const resetSearchStr = React.useMemo(() => () => {
        setSearch("");
        //console.log('PodcastSearch.tsx:112:resetSearch => ');
        if (onSearch)
            onSearch("");
        dispatch(Actions.setGlobalSearchSearch(""));
    }, [onSearch, setSearch, dispatch]);

    const onSuggestionsClearRequested = React.useMemo(() => () => {
        //console.log('onSuggestionsClearRequested.tsx:162:onSuggestionsClearRequested');

        setSuggestions([]);
    }, [setSuggestions]);
    return (
        <T.TSAuto suggested={suggestions.length > 0 ? suggestions.length + 1 : 0} lightMode={lm} accent={p.accent}>
            <div className="searchIcon" >
                <Icon marginTop={9} name="search" size="20px" />
            </div>
            <AutocompleteSearch
                placeholder={t("search..")}
                onSuggestionSelected={onSuggestionSelected}
                onSuggestionsClearRequested={onSuggestionsClearRequested}
                onSuggestionsFetchRequested={onSuggestionsFetchRequested}
                search={search ?? ""}
                searchChanged={searchChanged}
                suggestions={suggestions}
                theme={{
                    container: { position: "relative" },
                    suggestionsContainer: {
                        display: "none",
                        width: "100%",
                        background: lm ? "@middleLightGrey" : "#0A0A0B",
                        backgroundColor: lm ? "@middleLightGrey" : "#0A0A0B",
                        marginLeft: 0,
                        boxShadow: "0 6px 8px -6px #000",
                    },
                    suggestionsContainerOpen: {
                        background: lm ? "#e9e9e9" : "#0A0A0B",
                        backgroundColor: lm ? "#e9e9e9" : "#0A0A0B",
                        color: lm ? "@darkerGrey" : theme.colors.mainBackground,
                        display: "block",
                        position: "absolute",
                        top: 40,
                        border: "1px solid " + lm ? "#e4e3e3" : lightenColor({ theme }, theme.colors.darkerGrey, 80),
                        fontSize: "1em",
                        borderBottomLeftRadius: 0,
                        borderBottomRightRadius: 0,
                        zIndex: 3000
                    },
                    suggestionsList: {
                        margin: 0,
                        padding: 0,
                        listStyleType: "none",
                        paddingTop: 0,
                        paddingBottom: 0
                    },
                    suggestion: {
                        paddingTop: 10,
                        paddingBottom: 10,
                        paddingLeft: 10,
                        cursor: "pointer"
                    },
                    suggestionHighlighted: {
                        background: p.accent ?? theme.colors.accent,
                        backgroundColor: p.accent ?? theme.colors.accent,
                        color: theme.colors.mainBackground,
                        borderLeft: ""
                    }
                }}
                onEnterPressed={onEnterPressed}

            />
            {
                search !== "" && search !== undefined &&
                <T.TSSearchDelete
                    onClick={search !== "" ? resetSearchStr : undefined}
                >
                    <Icon marginTop={9} name="times" size="20px" />
                </T.TSSearchDelete>
            }
        </T.TSAuto>
    );
}
export default translate("podcast")(connect(mapper)(PodcastSearch));
import { store } from "App";
import * as _ from "lodash";
import * as React from "react";
import { useSelector } from "react-redux";
import { AppRouteNames, ViewRouteNames } from "services/AppEntryDefinitions";
import { ApplicationState } from "services/ApplicationState/ApplicationState";
import { AppEntry, AppView } from "services/ApplicationState/MasterLayoutRouter";
import Config from "services/Config";
import History from "services/History";
import { getAppEntry } from "services/StoreDependantHelpers";
import { RouteParams } from "universal-router";

export const prefixRoute = (route?: string) => {
    return route ? ((route.startsWith("/") ? "" : "/") + route) : "/";
}

export const noPrefixRoute = (route?: string) => {
    return route ? ((route.startsWith("/") ? route.substring(1) : route)) : "";
}

export function linkToRoute(route: string) {
    //const loc = window.location;
    //const prefix = loc.protocol + "//" + loc.hostname + (loc.port ? ":" + loc.port : "") + "/index.html#/";
    const prefix = "/#/";
    return prefix + noPrefixRoute(route);
}

export function combineRoutes(routes: string[]) {
    return _.join(_.map(routes, r => r.slice(r.startsWith("/") ? 1 : 0, r.endsWith("/") ? -1 : undefined)), "/");
}

const fillRoute = (route: string, data?: { [key: string]: string }) => {
    const split = route.split("/");
    const reqKeys: string[] = [];
    const allKeys: string[] = [];
    const optKeys: string[] = [];
    const astKeys: string[] = [];
    _.forEach(split, s => {
        if (s.startsWith(":")) {
            const opt = s.endsWith("?")
            const ast = s.endsWith("*");
            const n = s.slice(1, opt || ast ? -1 : undefined);
            allKeys.push(n)
            if (opt || ast) {
                optKeys.push(n);
                if (ast)
                    astKeys.push(n);
            }
            else
                reqKeys.push(n);
        }
    });
    const dataKeys = data ? Object.keys(data) : [];

    //console.log('allKeys => ', allKeys);
    //console.log('reqKeys => ', reqKeys);
    //console.log('optKeys => ', optKeys);

    const toMuch = _.difference(dataKeys, allKeys);
    //console.log('toMuch => ', toMuch);
    if (toMuch.length > 0) {
        console.log(`could not fill route correctly! route = "${route}", data = `, data, ", unknown values = ", toMuch);
        return undefined;
    }
    const notProvided = _.difference(reqKeys, dataKeys);
    //console.log('notProvided => ', notProvided);
    if (notProvided.length > 0) {
        console.log(`could not fill route correctly! route = "${route}", data = `, data, ", missing values = ", notProvided);
        return undefined;
    }

    const notProvidedOpt = _.difference(optKeys, dataKeys);
    //console.log('notProvidedOpt => ', notProvidedOpt);
    const notProvidedOptIdx = _.map(notProvidedOpt, n => _.findIndex(optKeys, k => k === n))
    //console.log('notProvidedOptIdx => ', notProvidedOptIdx);
    let ok = notProvidedOptIdx.length > 0 && notProvidedOptIdx[notProvidedOptIdx.length - 1] !== optKeys.length - 1 ? false : true;
    if (ok) {
        for (let i = 1; i < notProvidedOptIdx.length; i++) {
            if (notProvidedOptIdx[i] - notProvidedOptIdx[i - 1] > 1) {
                ok = false;
                break;
            }
        }
    }
    if (!ok) {
        console.log(`could not fill route correctly! route = "${route}", data = `, data, ", optional parameters in between missing = ", notProvidedOpt);
        return undefined;
    }
    let r = route;
    const d = { ...data };
    //console.log('d => ', d);
    _.forEach(allKeys, k => {
        if (!d[k])
            d[k] = "";
    })
    //console.log('d => ', d);
    _.forEach(d, (v, k) => {
        // console.log('d => ', d);
        // console.log('v => ', v);
        // console.log('k => ', k);
        if (_.findIndex(astKeys, r => r === k) >= 0) {
            const vv = _.startsWith(v, "/") ? v.substring(1) : v;
            r = r.replace(`:${k}*`, vv);
        }
        else
            r = r.replace(`:${k}${_.findIndex(reqKeys, r => r === k) < 0 ? "?" : ""}`, v);
    });
    r = r.replace("//", "");
    while (r.endsWith("/"))
        r = r.slice(0, -1);
    //console.log('RoutingHelper.tsx:101:${r.startsWith("/") ? "" : "/"}${r} => ', `${r.startsWith("/") ? "" : "/"}${r}`);
    return `${r.startsWith("/") ? "" : "/"}${r}`;
}

const findNamedRoute = (name: ViewRouteNames | AppRouteNames) => {
    const state = store.getState();
    const apps = state.appEntries;

    const length = apps?.length ?? 0;

    // for (let i = 0; i < apps?.length ?? 0; i++) {
    for (let i = 0; i < length; i++) {
        const a = apps[i]!;
        //        console.log('a => ', a);
        if (a.routeName === name)
            return a.route;
        for (let j = 0; j < (a.views?.length ?? 0); j++) {
            const v = a.views![j];
            //            console.log('v => ', v);
            if (v.routeName === name)
                return combineRoutes([a.route, v.route]);
        }
    }
    return undefined;
}

export const getQrImageUriForNamedRoute = (name: ViewRouteNames | AppRouteNames, data?: { [key: string]: string }) => {
    const s = `${Config.getInstance().baseURL}/api/media/qrCode/${btoa(`${createFullNamedRoute(name, data)}`)}`;
    return s;
};
export const createFullNamedRoute = (name: ViewRouteNames | AppRouteNames, data?: { [key: string]: string }) => {
    const loc = document.location;
    return `${loc.protocol}//${loc.hostname}${loc.port ? ":" + loc.port : ""}/index.html#${createNamedRoute(name, data)}`;
}

export const createNamedRoute = (name: ViewRouteNames | AppRouteNames, data?: { [key: string]: string }, withPrefix?: boolean) => {
    const route = findNamedRoute(name);
    if (route)
        return withPrefix ? `#${fillRoute(route, data)}` : fillRoute(route, data);
    return undefined;
}

export const createRouteToModuleAndView = (to: { module: string, view?: string }, data?: { [key: string]: string }) => {
    const state = store.getState();
    const appEntry = to.module === "self" ? state.currentAppEntry : getAppEntry(to.module);
    if (appEntry) {
        let route: string | undefined = undefined;
        if (to.view) {
            const view = _.find(appEntry.views, v => v.name === to.view);
            route = combineRoutes([appEntry.route, view?.route ?? ""]);
        }
        else {
            route = appEntry.route;
        }
        if (route) {
            return fillRoute(route, data);
        }
    }
    return undefined;
}

export const gotoRouteOfModuleAndView = (to: { module: string, view?: string }, data?: { [key: string]: string }) => {
    const goto = createRouteToModuleAndView(to, data);
    if (goto)
        History.push(goto);
    else
        History.push("/");
}

export const gotoNamedRouteMethod = (name: ViewRouteNames | AppRouteNames, data?: { [key: string]: string }) => () => gotoNamedRoute(name, data);

export const gotoNamedRoute = (name: ViewRouteNames | AppRouteNames, data?: { [key: string]: string }, replace?: boolean) => {
    const goto = createNamedRoute(name, data);
    //console.log("RoutingHelper.tsx::169 => goto", goto);
    //console.log("RoutingHelper.tsx::182 => History", History);

    if (goto) {
        if (replace)
            History.replace(goto);
        else
            History.push(goto);
    }
    else {
        if (replace)
            History.replace("/");
        else
            History.push("/");
    }

}

export const useCheckWhereIAm = () => {
    const currentAppEntry = useSelector((s: ApplicationState) => s.currentAppEntry);
    const currentAppView = useSelector((s: ApplicationState) => s.currentAppView);
    const toRet = React.useMemo(() => (routeName: AppRouteNames | ViewRouteNames) => (currentAppEntry?.routeName === routeName && currentAppView === undefined) || currentAppView?.routeName === routeName, [currentAppEntry, currentAppView]);
    return toRet;
}

export const createCheckWhereIAm = (currentAppEntry: AppEntry | undefined, currentAppView: AppView | undefined) => (routeName: ViewRouteNames | AppRouteNames) => {
    return (currentAppEntry?.routeName === routeName && currentAppView === undefined) || currentAppView?.routeName === routeName;
}

export const tubeRoute = (id: string): { [key: string]: string; } => {
    let data: { [key: string]: string; } = { id };
    const state = store.getState();
    const currentCategoryStack = state.tubeState.currentCategoryStack;
    if (currentCategoryStack === undefined)
        return data;
    for (let i = 0; i < currentCategoryStack.length; ++i) {
        const node = currentCategoryStack[i].node;
        if (node === undefined)
            break;
        if (i === 0)
            data = { ...data, catid: node.id };
        if (i === 1)
            data = { ...data, catid1: node.id };
        if (i === 2)
            data = { ...data, catid2: node.id };
        if (i === 3)
            data = { ...data, catid3: node.id };
        if (i === 4)
            data = { ...data, catid4: node.id };
        if (i === 5)
            data = { ...data, catid5: node.id };
    }
    return data;
}
export const slideshowRoute = (id: string): { [key: string]: string; } => {
    let data: { [key: string]: string; } = { id };
    const state = store.getState();
    const currentCategoryStack = state.slideshowState.currentCategoryStack;
    if (currentCategoryStack === undefined)
        return data;
    for (let i = 0; i < currentCategoryStack.length; ++i) {
        const node = currentCategoryStack[i].node;
        if (node === undefined)
            break;
        if (i === 0)
            data = { ...data, catid: node.id };
        if (i === 1)
            data = { ...data, catid1: node.id };
        if (i === 2)
            data = { ...data, catid2: node.id };
        if (i === 3)
            data = { ...data, catid3: node.id };
        if (i === 4)
            data = { ...data, catid4: node.id };
        if (i === 5)
            data = { ...data, catid5: node.id };
    }
    return data;
}
export const contentRoute = (id: string): { [key: string]: string; } => {
    let data: { [key: string]: string; } = { id };
    const state = store.getState();
    //const currentCategoryStack = state.tubeState.currentCategoryStack;
    const currentCategoryStack = state.contentState.currentCategoryStack;
    if (currentCategoryStack === undefined)
        return data;
    for (let i = 0; i < currentCategoryStack.length; ++i) {
        const node = currentCategoryStack[i].node;
        if (node === undefined)
            break;
        if (i === 0)
            data = { ...data, catid: node.id };
        if (i === 1)
            data = { ...data, catid1: node.id };
        if (i === 2)
            data = { ...data, catid2: node.id };
        if (i === 3)
            data = { ...data, catid3: node.id };
        if (i === 4)
            data = { ...data, catid4: node.id };
        if (i === 5)
            data = { ...data, catid5: node.id };
    }
    return data;
}

export const getCurrentCat = (p?: RouteParams): string => {
    if (p) {
        if (p.catid5)
            return p.catid5 as string;
        if (p.catid4)
            return p.catid4 as string;
        if (p.catid3)
            return p.catid3 as string;
        if (p.catid2)
            return p.catid2 as string;
        if (p.catid1)
            return p.catid1 as string;
        if (p.catid)
            return p.catid as string;
    }
    return "";
}


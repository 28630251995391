import { ControllerHelper, GameController, GameDownloadDto, QuizController, QuizDownloadDto } from 'collaboration-service';
import { Loader } from 'imaginarity-react-ui';
import * as React from 'react';
import { ApplicationState, useAppSelector } from 'services/ApplicationState/ApplicationState';
import { shallowCompare } from 'services/Helpers';
import { useImgI18N } from 'services/ImgI18N';
import GameEngine from 'views/Quiz/GameEngine';

const mapper = (s: ApplicationState) => ({
    currentGroup: s.currentGroup,
});

interface PreviewQuizProps {
    item: QuizDownloadDto;
    playlistStateId?: string;
    id: string;
    closeDimmer: () => void;
}

const PreviewQuiz = (p: PreviewQuizProps) => {
    const { currentGroup } = useAppSelector(mapper, shallowCompare);
    const { item, playlistStateId, id, closeDimmer } = p;
    const [data, setData] = React.useState<{ game: GameDownloadDto, quiz: QuizDownloadDto }>();
    const { t } = useImgI18N('playlist');

    React.useEffect(() => {
        if (id) {
            GameController.SearchGames({ filter: { ids: [id] } },
                (games) => {
                    if (games.length > 0) {
                        const game = games[0];
                        if (currentGroup) {
                            QuizController.GetSingle({ id: game.id },
                                (quiz) => {
                                    setData({ game, quiz });
                                },
                                (error) => { console.log("Could not fetch game", error); });
                        }
                    }
                    else {
                        const getData = async () => {
                            if (id && currentGroup) {
                                const [game, quiz] = await ControllerHelper
                                    .addCall({ gameRequest: { quizId: id, gameMode: "Single", context: playlistStateId } }, GameController.CreateGame, true)
                                    .addCall({ id }, QuizController.GetSingle, true)
                                    .execute<GameDownloadDto, QuizDownloadDto>();
                                setData({ game, quiz });
                            }
                        };
                        getData();
                    }
                }
            );
        }
        else {
            setData(undefined);
        }
    }, [id, currentGroup, playlistStateId]);

    return (
        <>
            {(data && data.game !== undefined) ? <GameEngine
                game={data.game}
                quiz={item}
                closeModal={closeDimmer}
                isPpeQuiz
                isFromPlaylist={true}
            />
                : <Loader active size='small' onClick={closeDimmer} infoText={t("loading")} infoColor='#fff' />}
        </>
    );
}
export default PreviewQuiz;
import { fadeColor, getColor, styled } from "imaginarity-react-ui";

export class TimeLineSC {

    public static Container = styled.div<{ top: number }>`
    position: relative;
    color: ${p => getColor(p, "@darkerGrey")};
    padding: ${p => p.top}px 0px 20px 0px;
`;
    public static ContainerInner = styled.div`
    position: relative;
    min-height: 60px;
    left: 20px;
    right: 20px;
    width: calc(100% - 40px);
`;
    public static TimeLineAll = styled.div`
    position: absolute;
    left: 0;
    right: 0;
    //top: 50%;
    bottom: 48px;
    height: 2px;
    background-color: ${p => getColor(p, "@neutralLow")};
`;
    public static TimeLineAllCover1 = styled.div`
    position: absolute;
    right: -20px;
    width: 40px;
    bottom: 48px;
    height: 2px;
    background-color: ${p => getColor(p, "@neutralLow")};
`;
    public static TimeLineAllCover2 = styled.div`
    position: absolute;
    right: 0px;
    width: 500px;
    transform: translateX(100%);
    bottom: 48px;
    height: 2px;
    background-color: ${p => getColor(p, "@mainBackground")};
`;
    public static CloseArea = styled.div`
        position: absolute;
        width: 80px;
        height: 10px;
        background: ${p => getColor(p, "@veryLightGrey")};
        left: 50%;
        bottom: -20px;
        transform: translateX(-50%);
        transition: 0.1s all ease-out;
        cursor: pointer;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
        /* box-shadow: 0 0 0 0 transparent; */
        box-shadow: 0px -1px 4px -3px ${p => getColor(p, "@darkGrey")};
        text-align: center;
        &:hover{
            height: 30px;
            background: ${p => getColor(p, "@darkGrey")};
            box-shadow: 0px -6px 8px -6px ${p => getColor(p, "@darkGrey")};
            svg{
                    stroke: ${p => getColor(p, "@veryLightGrey")};
            }   
}       
`;
    public static TodayDot = styled.div<{ left: number }>`
    position: absolute;
    width: 6px;
    height: 6px;
    left: ${p => p.left}%;
    top: 50%;
    transform-origin: center center;
    transform: translate(-50%, -2px);
    border: 1px solid ${p => getColor(p, "@middleLightGrey")};
    background: ${p => getColor(p, "@middleLightGrey")};
    z-index: 10;
`;
    public static TimeTodayLineVertical = styled.div<{ left: number }>`
    position: absolute;
    left: calc(${p => p.left}% - 0.5px);
    top: 50%;
    width: 1px;
    height: 36px;
    background-color: ${p => getColor(p, "@neutralLow")};
`;
    public static ColoredLineHorizontal = styled.div<{ color: string, idx: number, width: number, top?: number }>`
    position: absolute;
    height: 2px;
    left: 0%;
    right: ${p => 100 - p.width}%;
    top: ${p => p.top ?? 50}%;
    background: ${p => getColor(p, p.color)};
`;
    public static Disconnector = styled.div<{ left: number }>`
    z-index: 500;
    background: ${p => getColor(p, "background")};
    height: 20px;
    width: 10px;
    border-left: 1px solid ${p => getColor(p, "@neutralLow")};
    border-right: 1px solid ${p => getColor(p, "@neutralLow")};
    position: absolute;
    top: 50%;
    left: ${p => p.left !== 100 ? `${p.left}%` : "unset"};
    right: ${p => p.left === 100 ? "0%" : "unset"};
    transform: translate(calc(-50% + ${p => p.left === 0 ? 30 : p.left === 100 ? -30 : 0}px), -50%);
`;

    public static DueDate = styled.div<{ idx: number, left: number, color: string }>`
    top: calc(50% + 20px);
    font-size: 0.86em;
    background: transparent;
    box-shadow: none;
    border: none;
    color: ${p => getColor(p, p.color)};
    transform: translate(-50%, -50%);
    position: absolute;
    left: ${p => p.left}%;
    line-height: 15px;
    z-index: ${p => p.idx};
    width: 150px;
    text-align: center;
    transition: 0.2s all ease-out;
    background: transparent;
    border: 1px solid transparent; 
`;
    public static DueDateOptionalHint = styled.div`    
    color: ${p => getColor(p, "@middleLightGrey")};
    text-transform: lowercase;
    transform: translateY(-4px);
`;
    public static PlaylistNodeTitle = styled.div<{ color: string }>`
    color: ${p => getColor(p, p.color)};
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    &:hover{
        color: ${p => getColor(p, "@accentBlue")};
    }
`;
    public static PlaylistsContainer = styled.div<{ left: number, columns: number }>`
    position: absolute;
    //background: rgba(0,0,0,0.05);
    top: 0;
    bottom: 0;
    left: ${p => p.left === 0 ? "59px" : "0px"};
    right: ${p => p.left === 100 ? "59px" : "0px"};
    display: grid;
    grid-template-columns: repeat(${p => p.columns}, 1fr);
`;
    public static PlaylistsContainerRelative = styled.div`
    height: 100%;
    text-align: center;
    position: relative;
`;
    public static PlaylistsContainerAbsolute = styled.div`
    padding: 5px;
    position: absolute;
    //border: 1px solid rgba(0,0,0,0.03);
    //background: rgba(0,0,0,0.01);
    border-bottom: none;
    width: 90%;
    text-align: center;
    z-index: 0;
    bottom: 30px;
    left: 5%;
    hyphens: auto;
    word-break: break-word;
    overflow-wrap: break-word;
    color: ${p => getColor(p, "@darkGrey")};
    transition: 0.2s all ease-out;
    &:hover{
        //background: ${p => fadeColor(p, "@accentBlue", 5)};
        //border: 1px solid rgba(0,0,0,0.05);
        //border-bottom: none;
}
`;
    public static Dot = styled.div<{ left: number, color: string }>`
    position: absolute;
    width: 6px;
    height: 6px;
    left: ${p => p.left}%;
    top: 100%;
    transform-origin: center center;
    transform: translate(-50%, -2px);
    //border: 1px solid ${p => getColor(p, p.color === "background" ? "@middleLightGrey" : getColor(p, p.color))};
    background: ${p => getColor(p, p.color)};
    z-index: 1000;
`;
    public static ToolTipDiv = styled.div`
    margin-top: 10px;
`;
    public static ToolTipFooter = styled.div`
    display: grid;
    grid-template-columns: 1fr max-content;
    //column-gap: 20px;
    width: calc(100% + 28px);
    padding: 10px 14px;
    margin-left: -14px;
    margin-bottom: -14px;
    font-size: 0.9em;
    border-top: 1px solid ${p => getColor(p, "@veryLightGrey")};
`;
    public static TodayMarker = styled.div`
    position: absolute;
    right: 30px;
    left: 50%;
    bottom: -24px;
    border-left: 1px solid ${p => getColor(p, "@neutralLow")};
    border-bottom: 1px solid ${p => getColor(p, "@neutralLow")};
    height: 20px;
`;

}
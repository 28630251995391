import { MediaDownloadDto, PlaylistDownloadDto, PlaylistItemStateDownloadDto, PlaylistItemStateEnum, PlaylistStateDownloadDto } from 'collaboration-service';
import SafeHTML from 'components/SafeHTML/SafeHTML';
import { Icon, ImgIcons, Tooltip } from 'imaginarity-react-ui';
import * as _ from "lodash";
import * as React from 'react';
import { ApplicationState, useAppSelector } from 'services/ApplicationState/ApplicationState';
import { convertMinutesToDaysHoursMinutes, countNodeItemsToBeDonePerNode, countPlaylistItemsToBeDone, getHigherDate, getHighestAbsDueDate, getHighestDueDateRelativeInHours, shallowCompare } from 'services/Helpers';
import { createNamedRoute, gotoNamedRouteMethod } from 'services/Helpers/RoutingHelper';
import { getTranslated, getTranslatedStandardDate } from 'services/Helpers/TranslationHelpers';
import { useImgI18N } from 'services/ImgI18N';
import { PlaylistOverviewCarouselItemSC as T } from './PlaylistOverviewCarouselItemSC';

const mapper = (state: ApplicationState) => ({ contentLanguage: state.contentLanguage });

export interface PlaylistOverviewCarouselItemProps {
    data: PlaylistDownloadDto | PlaylistStateDownloadDto;
    isAAO: boolean;
}

const PlaylistOverviewCarouselItem = (props: PlaylistOverviewCarouselItemProps) => {
    const { data, isAAO } = props;
    const { contentLanguage } = useAppSelector(mapper, shallowCompare);
    const { t } = useImgI18N("playlist");
    const [playlist, setPlaylist] = React.useState<PlaylistDownloadDto>();
    const [media, setMedia] = React.useState<MediaDownloadDto>();
    const dataType: "Playlist" | "PlaylistStates" = data.hasOwnProperty("playlist") ? "PlaylistStates" : "Playlist";
    const pState = data as PlaylistStateDownloadDto;

    React.useEffect(() => {
        if (dataType === "PlaylistStates") {
            const d = data as PlaylistStateDownloadDto;
            setPlaylist(d.playlist);
            setMedia(d.playlist.media);
        }
        else {
            const d = data as PlaylistDownloadDto;
            setPlaylist(d);
            setMedia(d.media);
        }
    },
        [data, dataType]);

    const getState = React.useCallback((states: PlaylistItemStateDownloadDto[]): PlaylistItemStateEnum => {
        if (states.some(st => st.state === "Available")) return "Available";
        if (states.some(st => st.state === "Started")) return "Started";
        if (states.every(st => st.state === "Finished")) return "Finished";
        return "NotAvailable";
    }, []);

    const thumbnail = _.find(media?.links, l => l.ref === "self")?.uri ?? "";
    const absNodeDueDate = getHighestAbsDueDate(pState);
    const absNodeDueDateRelativeInHours = getHighestDueDateRelativeInHours(pState);
    const dueToDate = getHigherDate(absNodeDueDate, absNodeDueDateRelativeInHours);

    const sum = React.useMemo(() => {
        return (pState.curNodes || []).reduce((acc, curNode) => acc + (curNode.durationInMinutes || 0), 0) +
            (pState.prevNodes || []).reduce((acc, prevNode) => acc + (prevNode.durationInMinutes || 0), 0) +
            (pState.nextNodes || []).reduce((acc, nextNode) => acc + (nextNode.durationInMinutes || 0), 0);
    }, [pState]);

    const sumDurationAll = React.useMemo(() => convertMinutesToDaysHoursMinutes(sum, t, true), [sum, t]);
    const detailRoute = React.useMemo(() => playlist ? createNamedRoute("playlist_detail", { id: playlist.id }) : undefined, [playlist]);
    const item = data as PlaylistStateDownloadDto;

    const prevNodes = React.useMemo(() => pState.prevNodes ?? [], [pState]);
    const curNodes = React.useMemo(() => pState.curNodes ?? [], [pState]);
    const nextNodes = React.useMemo(() => pState.nextNodes ?? [], [pState]);

    const allNodesReferenceIdsCount = React.useMemo(() => {
        return countPlaylistItemsToBeDone(curNodes) +
            countPlaylistItemsToBeDone(prevNodes) +
            countPlaylistItemsToBeDone(nextNodes);
    }, [curNodes, prevNodes, nextNodes]);

    const getCurrentLearningStatus = React.useCallback(() => {
        const status = getState(pState.itemStates ?? []);
        const dueTime = new Date(curNodes[0]?.absDueDate ?? new Date()).getTime() - new Date().getTime();
        if (status === "Finished" && pState.nextNodes.length === 0) {
            return { icon: "check circle", color: "@accentGreen" };
        }
        if ((status !== "Finished" && dueTime > 0) || isAAO) {
            return { icon: "clock", color: "background" };
        }
        return { icon: "exclamation triangle", color: "@accentRed" };
    }, [pState, getState, curNodes, isAAO]);

    const criticalCount = React.useMemo(() => {
        return countNodeItemsToBeDonePerNode(pState, curNodes) +
            countNodeItemsToBeDonePerNode(pState, prevNodes) +
            countNodeItemsToBeDonePerNode(pState, nextNodes);
    }, [pState, curNodes, prevNodes, nextNodes]);

    const percent = React.useMemo(() => {
        return Math.round((criticalCount / (isAAO ? pState.itemStates.length : allNodesReferenceIdsCount)) * 100);
    }, [criticalCount, isAAO, pState.itemStates.length, allNodesReferenceIdsCount]);

    return (
        <>
            <T.CarouselSlideContainerOuter img={thumbnail}>
                <T.CarouselSlideContainer onClick={detailRoute && playlist ? gotoNamedRouteMethod("playlist_detail", { id: playlist.id }) : undefined}>
                    <T.CarouselSlideContainerTitle>
                        <>{getTranslated(playlist?.headlines, contentLanguage)?.text}</>
                    </T.CarouselSlideContainerTitle>
                    <T.CarouselSlideContainerInfo>
                        <Tooltip
                            tooltipText=''
                            tooltipContent={<div style={{ textAlign: "justify" }}>
                                <div style={{ fontWeight: "bolder" }}>
                                    <SafeHTML
                                        allowedTags={["strong", "b"]}
                                        html={getTranslated(playlist?.headlines, contentLanguage)?.text}
                                    />
                                </div>
                                <T.ToolTipDiv>
                                    <SafeHTML
                                        html={getTranslated(playlist?.descriptions, contentLanguage)?.text}
                                    />
                                </T.ToolTipDiv>
                                {!isAAO &&
                                    <T.ToolTipFooter>
                                        <div>
                                            <Icon name="calendar" style={{ float: "left", marginRight: 5 }} marginTop={-2} size={16} />
                                            {dueToDate ? getTranslatedStandardDate(dueToDate, "PP") : ""}
                                        </div>
                                        {sum > 0 &&
                                            <div>
                                                <Icon name="clock" style={{ float: "left", marginRight: 5 }} marginTop={-1} size={16} />
                                                {sumDurationAll}
                                            </div>
                                        }
                                    </T.ToolTipFooter>
                                }

                            </div>}
                            noMargin position='bottom' notInline
                        >
                            <>
                                <Icon name={getCurrentLearningStatus().icon as ImgIcons} style={{ float: "right" }} color={getCurrentLearningStatus().color} />
                                {t("{{fin}} / {{of}} completed", { fin: criticalCount, of: isAAO ? item.itemStates.length : allNodesReferenceIdsCount })}
                            </>
                        </Tooltip>

                    </T.CarouselSlideContainerInfo>
                    <T.CarouselSlideContainerStatusBarContainer show={true}>
                        <T.CarouselSlideContainerStatusBar percentage={percent} color={getCurrentLearningStatus().color} />
                    </T.CarouselSlideContainerStatusBarContainer>
                </T.CarouselSlideContainer>
                {!isAAO &&
                    <T.Mandatory>{t("mandatory")}</T.Mandatory>
                }
            </T.CarouselSlideContainerOuter>
            <div />
        </>
    );
}
export default PlaylistOverviewCarouselItem;

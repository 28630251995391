import { ControllerHelper, MediaDownloadDto, PlaylistController, PlaylistDownloadDto, PlaylistStateDownloadDto, PlaylistUploadDto } from 'collaboration-service';
import CancelWithConfirmation from 'components/CancelWithConfirmation';
import SafeHTML from 'components/SafeHTML/SafeHTML';
import { Icon, Image, ImgIcons, RatingView, Tooltip, aboveBreakpoint, aboveBreakpointOrEqual, useThemePart } from 'imaginarity-react-ui';
import * as _ from "lodash";
import * as React from 'react';
import { ApplicationState, useAppSelector } from 'services/ApplicationState/ApplicationState';
import { AccessHelpers, convertMinutesToDaysHoursMinutes, getCriticalAndPendingCounts, getPlaylistStateDueDate, shallowCompare } from 'services/Helpers';
import { createNamedRoute, gotoNamedRouteMethod } from 'services/Helpers/RoutingHelper';
import { sanitizedNothingAllowed, sanitizedNothingAllowedAttributes } from 'services/Helpers/SanitizedHelper';
import { getTranslated, getTranslatedStandardDate } from 'services/Helpers/TranslationHelpers';
import { useImgI18N } from 'services/ImgI18N';
import { getGroupById } from 'services/StoreDependantHelpers';
import { ThemeContext } from 'styled-components';
import { PlaylistSC as T } from './PlaylistSC';

const mapper = (state: ApplicationState) => ({ contentLanguage: state.contentLanguage, user: state.user });

export interface PlaylistOverviewItemProps {
    data: PlaylistDownloadDto | PlaylistStateDownloadDto;
    showBtns: boolean;
    progress: "done" | "on Track" | "overdue" | "warning";
}

const PlaylistOverviewItem = (p: PlaylistOverviewItemProps) => {
    const { data, showBtns, progress } = p;
    const { contentLanguage, user } = useAppSelector(mapper, shallowCompare);
    const { t } = useImgI18N("playlist");
    const [playlist, setPlaylist] = React.useState<PlaylistDownloadDto>();
    const [media, setMedia] = React.useState<MediaDownloadDto>();
    const dataType: "Playlist" | "PlaylistStates" = data.hasOwnProperty("playlist") ? "PlaylistStates" : "Playlist";
    const pState = data as PlaylistStateDownloadDto;
    const contentWidth = useThemePart(x => x.content.width);

    React.useEffect(() => {
        if (dataType === "PlaylistStates") {
            const d = data as PlaylistStateDownloadDto;
            setPlaylist(d.playlist);
            setMedia(d.playlist.media);
        }
        else {
            const d = data as PlaylistDownloadDto;
            setPlaylist(d);
            setMedia(d.media);
        }
    }, [data, dataType])

    const thumbnail = _.find(media?.links, l => l.ref === "self")?.uri ?? "";
    const dueToDate = getPlaylistStateDueDate(pState);
    const curNodesDurationSum = (pState.curNodes || []).reduce((acc, curNode) => acc + (curNode.durationInMinutes || 0), 0);
    const prevNodesDurationSum = (pState.prevNodes || []).reduce((acc, prevNode) => acc + (prevNode.durationInMinutes || 0), 0);
    const nextNodesDurationSum = (pState.nextNodes || []).reduce((acc, nextNode) => acc + (nextNode.durationInMinutes || 0), 0);
    const sum = curNodesDurationSum + prevNodesDurationSum + nextNodesDurationSum;
    const sumDurationAll = convertMinutesToDaysHoursMinutes(sum, t, true, false);
    const detailRoute = playlist ? createNamedRoute("playlist_detail", { id: playlist.id }) : undefined;
    const theme = React.useContext(ThemeContext);
    const isTablet = aboveBreakpoint({ theme }, "largeMobile");
    const isLaptop = aboveBreakpointOrEqual({ theme }, "tablet");
    const isDesktop = aboveBreakpoint({ theme }, "desktop");
    const showDescs = contentWidth > 800;


    const getCurrentLearningStatus = React.useMemo(() => (): { icon: ImgIcons; color: string; text: string } => {
        if (progress === 'done' && pState.nextNodes.length === 0)
            return { icon: 'check circle', color: "@accentGreen", text: "done" };
        if (progress === 'on Track')
            return { icon: 'clock', color: "@darkerGrey", text: "on Track" };
        if (progress === 'warning')
            return { icon: 'bell', color: "@accentOrange", text: "warning" };
        return { icon: 'exclamation triangle', color: "@accentRed", text: "overdue" };
    }, [pState, progress]);

    const delIt = React.useMemo(() => (withEvents: boolean) => async () => {
        if (playlist) {
            await ControllerHelper.singleCall({ playlistId: playlist.id, withEvents }, PlaylistController.ResetPlaylistState);
        }
    }, [playlist]);

    const isAllAtOnce = pState && pState.playlist ? (pState.playlist as PlaylistUploadDto).sequencingMode === "AllAtOnce" : false;
    const isLinear = pState && pState.playlist ? (pState.playlist as PlaylistUploadDto).sequencingMode === "Linear" : false;
    const playlistIsFinished = pState.state === "Finished";
    const toBeDoneResult = getCriticalAndPendingCounts(pState);
    const criticalCount = toBeDoneResult.criticalCount;
    const itemsToBeDone = toBeDoneResult.itemsToBeDone;
    const toBeDone = <>{criticalCount} / {itemsToBeDone}</>;
    // const getDateFnsLng = ImgI18N.getInstance().languageLocal();
    const playlistStateIsOptional = pState.optional;
    const dueDate = new Date(dueToDate as Date);
    const isDueDateValid = !isNaN(dueDate.getTime());
    const isDueDateInFuture = isDueDateValid && dueDate >= new Date();
    const diplayTextColor = ((new Date(dueToDate as Date)) >= (new Date()) || playlistStateIsOptional || !isDueDateValid) ? "color" : "@accent";
    const displayText = playlistStateIsOptional
        ? t("optional")
        : (!isDueDateValid
            ? (isAllAtOnce ? <Icon name="times" color='@middleLightGrey' style={{ float: "left" }} size="16px" marginTop={3} /> : <Icon name="times" color='@middleLightGrey' style={{ float: "left" }} size="16px" marginTop={3} />
            )
            : (isDueDateInFuture ? getTranslatedStandardDate(dueDate, "PP") : getTranslatedStandardDate(dueDate, "PP"))
            // : (isDueDateInFuture ? getTranslatedStandardDate(dueDate, "PP") : formatDistance(dueDate, new Date(), { locale: getDateFnsLng, addSuffix: true }))
        )
        ;
    const plGroupName = getGroupById(playlist?.group_id)?.name ?? "";
    const isGlobalAdmin = AccessHelpers.isGlobalAdmin()(user);

    return (
        <>
            <div />
            <T.PoiLogoContainer onClick={detailRoute && playlist ? gotoNamedRouteMethod("playlist_detail", { id: playlist.id }) : undefined}>
                {thumbnail.indexOf("notFound") < 0 && <Image src={thumbnail} squared borderColor='#FFF' />}
            </T.PoiLogoContainer>
            <T.PoiDescriptionContainer onClick={detailRoute && playlist ? gotoNamedRouteMethod("playlist_detail", { id: playlist.id }) : undefined}>
                <div style={{ float: "left" }} className='nooverflow'>
                    {getTranslated(playlist?.headlines, contentLanguage)?.text}
                </div>
                {(isLaptop && playlist && playlist.ratingCount > 0) ?
                    <Tooltip tooltipText={t("{{count}} ratings", { count: playlist.ratingCount })} noMargin notInline position='top'>
                        <span style={{ float: "right" }}>
                            <div className='rating'>
                                <RatingView
                                    rating={playlist.rating}
                                    maxStars={5}
                                    filledOut={true}
                                    filledColor='@middleLightGrey'
                                />
                            </div>
                        </span>
                    </Tooltip>
                    :
                    <div />
                }
                {(isLaptop && playlist && playlist.commentCount > 0) ?
                    <div style={{ float: "right", position: "relative", marginLeft: 10 }}>
                        <Tooltip tooltipText={t("{{count}} comments", { count: playlist.commentCount })} noMargin notInline position='top'>
                            <span style={{ float: "right" }}>
                                <Icon name="comment" style={{ margin: 0, transform: "translateY(4px)" }} color="@middleLightGrey" />
                            </span>
                        </Tooltip>
                    </div>
                    :
                    <div />
                }
                {isGlobalAdmin ?
                    <div style={{ marginLeft: 10 }}>
                        <Tooltip tooltipText="" noMargin notInline position='top' tooltipContent={<div style={{ textAlign: "left", lineHeight: "20px" }}>This playlist is located in<br /> the <b>{plGroupName}</b> group<br /><small>Note only visible for global admins</small></div>}>
                            <span style={{ float: "right" }}>
                                <Icon name="info" style={{ margin: 0, transform: "translateY(4px)" }} color="@middleLightGrey" />
                            </span>
                        </Tooltip>
                    </div>
                    : <div />}
            </T.PoiDescriptionContainer>
            {showDescs ?
                <T.PoiDecriptionContainer onClick={detailRoute && playlist ? gotoNamedRouteMethod("playlist_detail", { id: playlist.id }) : undefined}>
                    <SafeHTML html={getTranslated(playlist?.descriptions, contentLanguage)?.text} allowedTags={sanitizedNothingAllowed} allowedAttributes={sanitizedNothingAllowedAttributes} />
                </T.PoiDecriptionContainer>
                : <div />
            }
            {isDesktop ?
                <T.PoiAssignContainer>
                    {getTranslatedStandardDate(pState.assignmentDate, "PP")}
                </T.PoiAssignContainer>
                : <div />
            }
            {isTablet ?
                <T.PoiDueToContainer color={diplayTextColor}>
                    {displayText}
                </T.PoiDueToContainer>
                : <div />
            }
            {isLaptop ?
                <T.PoiDurationContainer>
                    {sum > 0 ? sumDurationAll : (isAllAtOnce ? <Icon name="times" color='@middleLightGrey' style={{ float: "left" }} size="16px" marginTop={3} /> : "")}
                </T.PoiDurationContainer>
                : <div />
            }
            <T.PoiProgressContainer color={playlistStateIsOptional ? "color" : playlistIsFinished ? "@accentGreen" : getCurrentLearningStatus().color}>
                <Icon
                    name={playlistStateIsOptional ? "bulb" : (playlistIsFinished ? "check circle" : getCurrentLearningStatus().icon)}
                    color={playlistStateIsOptional ? "color" : (playlistIsFinished ? "@accentGreen" : getCurrentLearningStatus().color)}
                    size={18}
                    marginTop={4}
                    style={{ float: "left", marginRight: 5 }}
                />
                <span style={{ float: "left", marginRight: 5 }}>
                    {progress === "on Track" && isAllAtOnce ? t("open") : t(progress)}
                </span>
                <span style={{ float: "left", marginLeft: 5 }}>
                    {(isLinear && !isAllAtOnce) && <>{toBeDone}</>}
                </span>

            </T.PoiProgressContainer>
            {showBtns &&
                <T.PoiActionsContainer>
                    <CancelWithConfirmation
                        cancel={delIt(false)}
                        iconColor="@darkGrey"
                        kind="transparentButton"
                        icon="undo"
                        cancelText={t("cancel")}
                        confirmText={t("yes, reset state")}
                        tooltipInitialButton={t("reset state")}
                        iconOnly
                        floated='right'
                    />
                    <CancelWithConfirmation
                        cancel={delIt(true)}
                        iconColor="@darkGrey"
                        kind="transparentButton"
                        icon="resume"
                        cancelText={t("cancel")}
                        confirmText={t("yes, reset state with events")}
                        tooltipInitialButton={t("reset state with events")}
                        iconOnly
                        floated='right'
                    />
                </T.PoiActionsContainer>
            }
            <div />
        </>
    );
}
export default PlaylistOverviewItem;

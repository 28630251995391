import { ContentPostDownloadDto } from 'collaboration-service';
import * as React from 'react';
import PreviewTubePodcast from './PreviewTubePodcast';
import { getLink } from 'services/Helpers';
import { useThemePart } from 'imaginarity-react-ui';
import { getMediaLink } from 'services/Helpers/MediaHelpers';
import _ from 'lodash';
import { PlaylistDetailSC } from 'views/Playlist/PlaylistDetailSC';

interface PreviewContentPostProps {
    item: ContentPostDownloadDto;
    playlistStateId?: string;
}

const PreviewContentPost = (p: PreviewContentPostProps) => {
    const { item, playlistStateId } = p;
    const emptyImageUrl = useThemePart(t => t.emptyImageUrl);

    const image = getLink(item.media, "self")?.uri ?? emptyImageUrl;
    const i = item as ContentPostDownloadDto;
    const videoUrl = getMediaLink(_.first(i.content), c => c ? c.content : undefined);
    const hasContent = item && i?.content.length > 0;
    const isVideo = hasContent ? i.content.every(i => i.content.mediaType === "video/mp4") : false;
    return (
        <>
            {(videoUrl && isVideo) ? <div style={{ marginTop: -1 }}>
                <PreviewTubePodcast
                    playlistStateId={playlistStateId}
                    id={item.id}
                    isPodcast={false}
                />
            </div>
                :
                <PlaylistDetailSC.DimmerImageContainer imageUrl={image} />
            }
        </>
    );
}
export default PreviewContentPost;
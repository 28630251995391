import {
  appendPx,
  belowBreakpointOrEqual,
  fadeColor,
  fromThemeCreator,
  getButtonSizes,
  getColor,
  lightenColor,
  styled,
} from "imaginarity-react-ui";
import { MasterLayoutContentSC } from "layout/masterLayouts/MasterLayoutContentSC";
import {
  overflowHideAnimation,
  overflowShowAnimation,
} from "layout/parts/PartsSC";
import { CMSTheme } from "./CMS/CMSTheme";

const fromCmsTheme = fromThemeCreator<CMSTheme>("cmsTheme");


export const ICInputAlternative = styled.div`
  background: ${(props) => getColor(props, props.theme.colors.mainBackground)};
  padding: 10px;
  border-left: 3px solid ${(props) => getColor(props, props.theme.colors.accentRed)};
`;
export const ICInputAlternativeNonPadded = styled.div`
  background: ${(props) => getColor(props, props.theme.colors.mainBackground)};
  padding: 0 10px;
  border-left: 3px solid ${(props) => getColor(props, props.theme.colors.accentRed)};
  margin-top: 5px;
`;
export const MissingElementAlternative = styled.div`
  display: block;
  background: ${(p) => getColor(p, p.theme.colors.mainBackground)};
  line-height: ${(props) => belowBreakpointOrEqual(props, "tablet") ? "unset" : "22px"};
  font-size: 0.9rem;
  margin-right: 10px;
  margin-top: 2px;
  svg {
    stroke: ${(p) => getColor(p, p.theme.colors.accentRed)};
  }
`;
export const MissingElementAlternativeTitle = styled.div`
  text-align: left;
  font-weight: bolder;
  font-size: 1.05em;
  margin-bottom: 10px;
  width: calc(100% + 28px);
  margin-left: -14px;
  margin-top: -14px;
  background: ${(p) => getColor(p, p.theme.colors.veryLightGrey)};
  line-height: 40px;
  padding: 0 14px;
  color: ${(p) => getColor(p, p.theme.colors.accentRed)};
`;
export const SidebarContainerContent = styled.div`
  width: 100%;
  margin-top: 5px;
  //padding-left: 10px;
  transition: 0.2s all ease-out;
  button > div {
    margin: 0;
  }
`;
export const ButtonContainer = styled.div`
  width: ${(p) => p.theme.content.width}px;
  //height:40px;
  //touch-action: pan-right;
  button > div {
    margin: 0;
  }
`;
export const SidebarContainer = styled.div`
  width: ${(p) => p.theme.content.width}px;
  margin-top: 20px;
  cursor: default;
  overflow-x: hidden;
  height: ${(p) => appendPx(p.theme.content.height - 40 - 20 - 50)};
  //touch-action: pan-right;
`;
export const SidebarContainerTitle = styled.div<{ highlighted?: boolean, color?: string, noBold?: boolean }>`
  width: 100%;
  font-weight: ${p => p.noBold ? "normal" : "bolder"};
  line-height: 40px;
  height: 40px;
  margin-top: 5px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
   ${p => p.color && `color: ${getColor(p, p.color)};`};
  background: ${p => p.highlighted ? lightenColor(p, p.theme.backgroundColor, 80) : "transparent"};
  border-left: 1px solid ${p => p.highlighted ? getColor(p, "@accent") : "transparent"};
  padding-left: ${p => p.highlighted ? 12 : 0}px;
  transition: 0.2s all ease-out;
  button>div{margin: 0;}
`;
export const SidebarContainerTitleFilledIcon = styled.div<{ iconFillColor?: string }>`
  svg{
    fill: ${p => p.iconFillColor !== undefined ? getColor(p, p.iconFillColor) : undefined};
    stroke: ${p => p.iconFillColor !== undefined ? getColor(p, p.iconFillColor) : undefined};
  }
`;
export const MobileFooterBar = styled.div<{ accent?: string }>`
    z-index: 2147483637;

    white-space: nowrap;
    overflow-x: auto;
    overflow-y: hidden;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    box-sizing: border-box;
    display: inline-block;
    &::-webkit-scrollbar {
        display: none;
    }

    width: 100vw;
    //border-top: ${(p) => p.accent ? "1px solid " + lightenColor(p, p.accent, 70) : "none"};
    //height: ${(props) => MasterLayoutContentSC.buttonHeight(props.theme)};
    height: ${(p) =>
    appendPx(
      getButtonSizes(
        p.theme,
        "large"
      ).buttonHeight
    )};
    position: relative;
    background: ${(props) =>
    props.accent
      ? props.accent
      : props.theme.mobileButtonBar.backgroundColor
        ? getColor(props, props.theme.mobileButtonBar.backgroundColor)
        : getColor(props, props.theme.backgroundColor)};
    button{
        min-width: ${(p) =>
    appendPx(
      getButtonSizes(
        p.theme,
        "large"
      ).buttonHeight
    )};
        height: ${(p) =>
    appendPx(
      getButtonSizes(
        p.theme,
        "large"
      ).buttonHeight
    )};
    }
    svg{
        transform: scale(1.4);
    }
`;
export const MobileFooterBarTransparence = styled.div<{ accent?: string }>`
  position: absolute;
  top: -10px;
  left: 0px;
  right: 0px;
  background: linear-gradient(
    to top,
    ${(props) =>
    props.accent
      ? props.accent
      : props.theme.mobileButtonBar.backgroundColor
        ? getColor(props, props.theme.mobileButtonBar.backgroundColor)
        : getColor(props, props.theme.backgroundColor)},
    transparent
  );
  border-bottom: 1px solid ${(p) => getColor(p, p.theme.colors.middleLightGrey)};
  height: 10px;
`;
export const SidebarAnimationLeft = styled.div<{ open: boolean }>`
  padding: 0px;
  max-height: ${(props) => (props.open ? "100%" : "0")};
  transition: max-height 0.3s ease-in-out;
  overflow: auto;
  ${(props) => (props.open ? overflowShowAnimation : overflowHideAnimation)}
  //touch-action: pan-left;
`;
export const SidebarAnimationRight = styled.div<{ open: boolean }>`
  padding: 0px;
  max-height: ${(props) => (props.open ? "100%" : "0")};
  transition: max-height 0.3s ease-in-out;
  overflow: auto;
  ${(props) => (props.open ? overflowShowAnimation : overflowHideAnimation)}
  //touch-action: pan-right;
`;
export const HeaderTextList = styled.div`
  font-size: ${(props) => appendPx(props.theme.fontSize)};
  font-weight: ${(props) => props.theme.fontWeights.heavy};
  width: 100%;
  line-height: 30px;
  margin-bottom: 5px;
`;
export const SidebarContainerNoMargin = styled.div`
  width: 100%;
  margin-top: 0px;
  display: grid;
  grid-template-columns: max-content 1fr;
  cursor: default;
  align-items: top;
  margin-left: 0px;
`;
export const SidebarContainerTitleOneLine = styled.div`
  line-height: 30px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  padding-right: 15px;
  padding-left: 5px;
`;
export const SidebarContainerContentOneLine = styled.div`
  padding-left: 20px;
  line-height: 30px;
  width: calc(100% - 10px);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
export const RangeContainer = styled.span<{ notSetted?: boolean }>`
  padding-left: 10px;
  float: right;
  font-weight: normal;
  font-size: 0.8em;
  color: ${(p) => (p.notSetted ? getColor(p, "@lightGrey") : "inherit")};
`;
export const ToBeDone = styled.span`
  padding-right: 5px;
  padding-left: 5px;
  color: ${(p) => getColor(p, "@accent")};
  font-weight: 100;
  &:after {
    content: "2beDone";
  }
`;

export const LeftDatePicker = styled.div<{ selected?: boolean }>`
  .react-datepicker-popper[data-placement^="top"] {
    margin-bottom: 5px !important;
  }
  .react-datepicker__input-container{
    background-color: ${p => p.selected ? fromCmsTheme(p, t => fadeColor(p, t.select.filledColor, (t.select.filledFadings ? t.select.filledFadings[0] : 5)), t => "") : fromCmsTheme(p, t => fadeColor(p, t.select.emptyColor, (t.select.emptyFadings ? t.select.emptyFadings[0] : 5)), t => "")} !important;
  }
`;
export const RightDatePicker = styled.div<{ selected?: boolean }>`
  .react-datepicker-popper {
    left: -105px !important;
  }
  .react-datepicker__triangle {
    left: 172px !important;
  }
  .react-datepicker-popper[data-placement^="top"] {
    margin-bottom: 5px !important;
  }
  .react-datepicker__input-container{
    background-color: ${p => p.selected ? fromCmsTheme(p, t => fadeColor(p, t.select.filledColor, (t.select.filledFadings ? t.select.filledFadings[0] : 5)), t => "") : fromCmsTheme(p, t => fadeColor(p, t.select.emptyColor, (t.select.emptyFadings ? t.select.emptyFadings[0] : 5)), t => "")} !important;
  }
`;
export const SidebarContainerContentLinkOneLine = styled.div`
  padding-left: 20px;
  line-height: 30px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding-right: 0px;
  width: 100%;
  svg {
    height: 14px;
    width: 14px;
    float: left;
    margin-top: 8px;
  }
`;
export const ProjectOptionContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr max-content;
`;

export const PID = styled.div`
  text-align: right;
  color: ${(p) => getColor(p, "@lightGrey")};
`;

export const SBtitle = styled.div`
  width: 100%;
  font-weight: bolder;
  height: 40px;
  line-height: 40px;
  margin-top: 5px;
  button > div {
    margin: 0;
  }
`;

export const OptionsOuterContainer = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr max-content;
`;
export const OptionsOuterContainerLabel = styled.div`
  padding-right: 5px;
`;
export const OptionsOuterContainerInfo = styled.div`
  align-self: center;
`;

export class CustomSC { }
